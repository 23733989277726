import { DESIGN_INPUTS_SOURCE } from '../constants/index.ts'
import { useDesignInputsStore } from '../store/useDesignInputsStore.ts'

export const useQueue = () => {
  const { queue, setQueue } = useDesignInputsStore()

  const addToQueue = item => {
    const lastItem = queue[queue.length - 1]

    const filteredQueue = queue.filter(({ type }) => type !== item.type)

    // Case for Sketch
    if (lastItem?.type === DESIGN_INPUTS_SOURCE.SKETCH && lastItem?.image.length >= item.image.length) {
      // replace Sketch item
      const itemToReplace = { ...lastItem, ...item }
      // Sketch can be only on 0 index
      return setQueue([itemToReplace])
    }

    return setQueue([...filteredQueue, item])
  }

  const removeLastItem = () => {
    const updatedQueue = [...queue]
    updatedQueue.splice(queue.length - 1, 1)

    return setQueue(updatedQueue)
  }

  return {
    addToQueue,
    removeLastItem,
    queue,
  }
}
