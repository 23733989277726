import styled from 'styled-components'

export const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
`

export const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  margin: 3rem 3rem;
  gap: 1rem;
  width: 20rem;
`

export const SubmitButton = styled.button`
  background: rgba(59, 96, 226, 1);
  color: var(--cream);
  font-weight: 700;
  border: none;
  border-radius: 7px;
  padding: 1rem;
  cursor: pointer;
  text-align: center;
  font-size: 1.2rem;
`

export const FormTitle = styled.span`
  color: var(--cream);
  font-weight: 700;
  font-size: 1.6rem;
  text-align: center;
  margin-bottom: 1rem;
`

export const NumberInput = styled.input`
  color: var(--cream);
  height: 56px;
  width: 320px;
  background: transparent;
  border: 1px solid rgba(155, 155, 155, 0.5);
  border-radius: 4px;
  padding-left: 0.8rem;

  input[type='number'] {
    -moz-appearance: textfield; /* Firefox */
  }

  input[type='number']::-webkit-outer-spin-button,
  input[type='number']::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`
