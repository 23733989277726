

export const PolicyViolation = () => {
  const handleClick = e => {
    e.preventDefault() // Prevent the default behavior of the link

    window.open('mailto:sales@getleo.ai', '_blank')
  }

  return (
    <div style={{ fontSize: '1.7vh' }}>
      Our moderation system flagged the requested product as inappropriate. Please adjust your product description.{' '}
      <br />
      Enterprise customers can ask for a specific version adapted to their needs at{' '}
      <a href="mailto:sales@getleo.ai" onClick={handleClick} style={{ fontSize: '1.7vh' }}>
        sales@getleo.ai
      </a>
      .
    </div>
  )
}
