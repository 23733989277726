import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useFetchData } from '../api'
import { KYC_ENDPOINT } from '../lib/config'
import { ROUTE } from '@/router/Router'
import { useAppState } from '../store/useAppState'
import * as analytics from '../lib/analytics'

export const useCheckKYC = () => {
  const [loading, setLoading] = useState(false)
  const { fetchData } = useFetchData()
  const navigate = useNavigate()

  const { isOnboardingOpens, setIsOnboardingOpens } = useAppState(store => store)

  const checkAndRedirectKYC = () => {
    setLoading(true)
    fetchData({ method: 'GET', url: KYC_ENDPOINT })
      .then(data => {
        // lol
        analytics.registerKYCInfo(data.data.data)
      })
      .catch(error => {
        // if error 404 - move to Onboarding
        if (error.message === 'KYC not found') {
          setIsOnboardingOpens(true)
          navigate(ROUTE.MAIN)
        }
      })
      .finally(() => {
        setLoading(false)
      })
  }

  return {
    loading,
    checkAndRedirectKYC,
    isVisible: isOnboardingOpens,
    setIsVisible: setIsOnboardingOpens,
  }
}
