/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react'
import { VARIATIONS_MESSAGE } from '../../../constants/msgEnums'
import { useGalleryStore } from '../../../store/useGalleryStore'
import { ChatInput } from './ChatInput'
import { Message } from './Message'
import './Chat.scss'
import { useIdeationChat } from '@/src-ideation/store/useChat.js'

const ChatWindow = ({ inputHeight, chatSectionHeight, addMessages, addFullMessages, setImagePrompt }) => {
  const { messages } = useIdeationChat(store => store)
  const chatRef = useRef(null)
  const [_isScrolledToBottom, setIsScrolledToBottom] = useState(true)

  // const chatWindowRef = useRef(null)

  useEffect(() => {
    if (chatRef.current) {
      chatRef.current.style.height = `calc(${chatSectionHeight}px - ${inputHeight}px)`
    }
  }, [chatSectionHeight, inputHeight])

  useEffect(() => {
    if (chatRef.current) {
      chatRef.current.scrollTop = chatRef.current.scrollHeight
    }
  }, [messages])

  useEffect(() => {
    if (chatRef.current) {
      const secondToLastMessageElement = chatRef.current.children[chatRef.current.children.length - 2]
      if (secondToLastMessageElement) {
        secondToLastMessageElement.scrollIntoView({ behavior: 'smooth' })
      }
    }
  }, [messages])

  // const handleJumpToEnd = () => {
  //   if (chatRef.current) {
  //     chatRef.current.scrollTop = chatRef.current.scrollHeight
  //     setIsScrolledToBottom(true)
  //   }
  // }

  const handleScroll = () => {
    if (chatRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = chatRef.current
      setIsScrolledToBottom(scrollTop + clientHeight === scrollHeight)
    }
  }

  return (
    <div className="chat-container" onScroll={handleScroll} ref={chatRef}>
      {messages.map((message, index) => (
        <Message
          key={index}
          message={message}
          addMessages={addMessages}
          addFullMessages={addFullMessages}
          setImagePrompt={setImagePrompt}
        />
      ))}
    </div>
    // <div className="chat-window" ref={chatWindowRef}>
    //     <div className="chat-container" onScroll={handleScroll} ref={chatRef}>
    //         {messages.map((message, index) => (
    //             <Message key={index} message={message}/>
    //         ))}
    //     </div>
    //     {/*{!isScrolledToBottom && (*/}
    //     {/*    <Button*/}
    //     {/*        icon={{*/}
    //     {/*            element: <svg width="100" height="100" xmlns="http://www.w3.org/2000/svg">*/}
    //     {/*                <circle cx="50" cy="50" r="45" stroke="black" stroke-width="3" fill="none"/>*/}
    //     {/*                <path d="M25,35 L50,65 L75,35" stroke="black" stroke-width="3" fill="none"/>*/}
    //     {/*            </svg>*/}
    //     {/*            ,*/}
    //     {/*            position: 'left'*/}
    //     {/*        }}*/}
    //     {/*        type="primary"*/}
    //     {/*        onClick={handleJumpToEnd}*/}
    //     {/*        className={'floating-button'}*/}
    //     {/*    />*/}
    //     {/*)}*/}
    // </div>
  )
}

export const Chat = ({ generateImages, setSpec }) => {
  const variantImage = useGalleryStore(store => store.variantImage)
  const { fullMessagesLog, setFullMessagesLog, promptVersions, setPromptVersions, messages, setMessages } =
    useIdeationChat(store => store)

  const [inputHeight, setInputHeight] = useState(0)
  const [chatSectionHeight, setChatSectionHeight] = useState(0)
  const chatSectionRef = useRef(null)

  const setImagePrompt = text => setPromptVersions([...promptVersions, text])

  useEffect(() => {
    if (!!variantImage) {
      const lastMessage = messages[messages.length - 1]
      if (lastMessage.text === VARIATIONS_MESSAGE) return
    }
  }, [variantImage])

  useEffect(() => {
    if (chatSectionRef.current) {
      setChatSectionHeight(chatSectionRef.current.style.height)
    }
  }, [chatSectionRef])

  const addMessages = newMessages => setMessages([...messages, ...newMessages])

  const addFullMessages = newMessages => setFullMessagesLog([...fullMessagesLog, ...newMessages])

  return (
    <div className="chat-section" ref={chatSectionRef}>
      <ChatWindow
        inputHeight={inputHeight}
        chatSectionHeight={chatSectionHeight}
        addMessages={addMessages}
        addFullMessages={addFullMessages}
        setImagePrompt={setImagePrompt}
      />
      <ChatInput
        setInputHeight={setInputHeight}
        generateImages={generateImages}
        setSpec={setSpec}
        setImagePrompt={setImagePrompt}
      />
    </div>
  )
}
