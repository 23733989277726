const replaceTags = (tempDiv, tagName, prefix, suffix) => {
  const elements = tempDiv.getElementsByTagName(tagName)
  for (let i = 0; i < elements.length; i++) {
    elements[i].textContent = `${prefix} ${elements[i].textContent} ${suffix}`
  }
}

export const extractTextFromHTML = htmlCode => {
  const tempDiv = document.createElement('div')
  tempDiv.innerHTML = htmlCode

  // Replace <h1> tags with **
  replaceTags(tempDiv, 'h1', '**', '**')
  // Replace <h2> tags with *
  replaceTags(tempDiv, 'h2', '\n*', '*')

  // Replace <li> tags with numbers inside <ol>
  const orderedLists = tempDiv.getElementsByTagName('ol')
  for (let j = 0; j < orderedLists.length; j++) {
    const listItems = orderedLists[j].getElementsByTagName('li')
    for (let k = 0; k < listItems.length; k++) {
      listItems[k].textContent = k + 1 + '. ' + listItems[k].textContent?.trim()
    }
  }

  const extractedText = tempDiv.textContent
  return extractedText || ''
}
