import React, { useState } from 'react'
import clsx from 'clsx'
import Shapes from '../../../../../../assets/icons/shapes.svg?react'
import Pencil from '../../../../../../assets/icons/edit.svg?react'
import Undo from '../../../../../../assets/icons/undo.svg?react'
import Line from '../../../../../../assets/icons/minus.svg?react'
import Circle from '../../../../../../assets/icons/circle.svg?react'
import Rectangle from '../../../../../../assets/icons/rounded-rectangle.svg?react'
import Eraser  from '../../../../../../assets/icons/eraser.svg?react'
import styles from './CanvasSideBar.module.scss'

const CanvasButton = ({ children, onClick, title }) => {
  return (
    <button className={clsx(styles.button, styles.dark)} {...{ onClick, title }} title="undo">
      {children}
    </button>
  )
}
const CanvasSubButton = ({ children, onClick, title }) => {
  return (
    <button className={clsx(styles.drawer, styles.dark)} {...{ onClick, title }}>
      {children}
    </button>
  )
}

export const CanvasSideBar = ({ undoLastDrawing, handleShapeSelect }) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false)

  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen)
  }

  return (
    <div className={clsx(styles.CanvasSideBar, styles.dark)}>
      <CanvasButton
        onClick={e => {
          e.stopPropagation() // Prevent event propagation
          undoLastDrawing()
          setIsDrawerOpen(false)
        }}
        title="undo"
      >
        <Undo className={styles.icon} width="3.6vh" height="3.6vh" />
        Undo
      </CanvasButton>
      <CanvasButton
        onClick={e => {
          e.stopPropagation() // Prevent event propagation
          handleShapeSelect('eraser')
          setIsDrawerOpen(false)
        }}
        title="eraser"
      >
        <Eraser className={styles.icon} width="3.6vh" height="3.6vh" />
        Eraser
      </CanvasButton>
      <CanvasButton
        onClick={e => {
          e.stopPropagation() // Prevent event propagation
          handleShapeSelect('pencil')
          setIsDrawerOpen(false)
        }}
        title="Pencil"
      >
        <Pencil className={styles.icon} width="3.6vh" height="3.6vh" />
        Pencil
      </CanvasButton>
      <CanvasButton onClick={toggleDrawer} title="Shapes">
        <Shapes className={styles.icon} width="3.6vh" height="3.6vh" />
        Shapes
      </CanvasButton>
      {isDrawerOpen && (
        <div className={styles.drawerSubmenu}>
          <CanvasSubButton onClick={() => handleShapeSelect('line')} title="Line">
            <Line width="1.7vh" height="1.7vh" />
          </CanvasSubButton>
          <CanvasSubButton onClick={() => handleShapeSelect('circle')} title="Circle">
            <Circle width="1.7vh" height="1.7vh" />
          </CanvasSubButton>
          <CanvasSubButton onClick={() => handleShapeSelect('rectangle')} title="Rectangle">
            <Rectangle width="1.7vh" height="1.7vh" />
          </CanvasSubButton>
        </div>
      )}
      {/* <CanvasButton title="upload">
        <Upload className={styles.icon} width="3.6vh" height="3.6vh" />
        Upload
      </CanvasButton> */}
    </div>
  )
}
