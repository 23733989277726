/**
 * This function iterates the layers, and for each layer creates an image.
 * The return value is an object contains the layers' images by 'element.attrs.id'
 * @param {*} canvasCurrent
 * @param {*} desiredWidth
 * @param {*} desiredHeight
 * @returns
 */
export const convertCanvasLayersToBase64Images = async (layerNames, canvasCurrent, desiredWidth, desiredHeight) =>
  new Promise((resolve, _reject) => {
    const stage = canvasCurrent
    if (!stage) return

    const res = {}
    const promises = []

    stage.children
      .filter(element => layerNames.includes(element.attrs.id))
      .forEach((element, _index) => {
        const originalDataUrl = element.toDataURL()

        let img = new Image()
        img.src = originalDataUrl
        img.crossOrigin = 'anonymous'

        const promise = new Promise(resolve => {
          img.onload = async () => {
            const canvas = document.createElement('canvas')
            canvas.width = desiredWidth
            canvas.height = desiredHeight
            const ctx = canvas.getContext('2d')

            // Set the fill style to black
            ctx.fillStyle = 'black'
            // Draw a black rectangle covering the entire canvas area
            ctx.fillRect(0, 0, canvas.width, canvas.height)

            ctx.drawImage(img, 0, 0, canvas.width, canvas.height)

            const resizedDataUrl = canvas.toDataURL()
            res[`${element.attrs.id}`] = resizedDataUrl
            resolve(true)
          }
        })

        promises.push(promise)
      })

    Promise.all(promises).then(() => resolve(res))
  })
