import styled from 'styled-components'

//todo connect the theme via ThemeProvider
export const Wrapper = styled.div`
  display: flex;
  gap: 2vh;
  width: 42%;
  height: 17vh;
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: 10px;
  background: rgba(45, 39, 53, 1);
  padding: 2vh;
  cursor: pointer;
`
export const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 1vh;
`

export const Title = styled.div`
  color: ${props => props.theme.palette.secondary.main};
  font-weight: 700;
`
export const SessionDate = styled.div`
  color: ${props => props.theme.components.MuiSlider.styleOverrides.mark.background};
  font-weight: 700;
  font-size: 1.8vh;
`
export const ImageOfSession = styled.img`
  width: 13vh;
  height: 100%;
  border-radius: 7.89px;
  border: 2px solid rgba(255, 255, 255, 0.8);
`
