
import  Star  from '@/src-ideation/assets/icons/star_2.svg?react'
import styles from './Stars.module.scss'

export const Stars = ({ rating, setRating }) => {
  const handleStarClick = starIndex => {
    setRating(starIndex + 1)
  }

  return (
    <div className={styles.wrap}>
      {[...Array(5)].map((_, index) => (
        <span className={styles.star} key={index} onClick={() => handleStarClick(index)}>
          <Star fill={index < rating ? '#7493FF' : 'none'} height={'100%'} width={'100%'} />
        </span>
      ))}
    </div>
  )
}
