import React, { useEffect, useState } from "react";
import * as analytics from "@/lib/analytics.ts";
import Close from "@/assets/icons/close.svg?react";
import Vector from "@/assets/icons/Vector 80.svg?react";
import {
  Container,
  OptionsWrapper,
  Title,
  Option,
  CloseButton,
  OtherContentInput,
  SubmitText,
} from "@/components/chatSection/sentimentsFeedback/SentimentsFeedbackStyle.ts";

interface Props {
  onClose: () => void;
  sentimentsFeedbackType: string | boolean;
  options: string[];
  sentimentTitle: string;
}

const SentimentsFeedback: React.FC<Props> = ({
  onClose,
  sentimentsFeedbackType,
  options,
  sentimentTitle,
}) => {
  const [selected, setSelected] = useState<string>("");
  const [otherContent, setOtherContent] = useState<string>("");
  const [displaySubmitText, setDisplaySubmitText] = useState<boolean>(false);

  useEffect(() => {
    if (selected && selected !== "Other") {
      setTimeout(() => {
        setSelected("");
        onClose();
      }, 2000);
    }
  }, [selected]);

  const applyFeedback = (content: string) => {
    if (selected !== "Other") {
      analytics.track("Message Sentiment Feedback", {
        type: sentimentsFeedbackType,
        content,
      });
    }

    setSelected(content);
  };

  const otherSubmit = (content: string, otherContent = "") => {
    analytics.track("Message Sentiment Feedback", {
      type: sentimentsFeedbackType,
      content,
      otherContent,
    });

    setDisplaySubmitText(true);

    setTimeout(() => {
      setDisplaySubmitText(false);
      onClose();
    }, 2000);
  };

  return (
    <Container>
      <CloseButton onClick={onClose}>
        <Close />
      </CloseButton>
      <Title>{sentimentTitle}</Title>
      <OptionsWrapper>
        {options.map((feedback: string) => (
          <Option
            selected={selected === feedback}
            onClick={() => applyFeedback(feedback)}
          >
            {selected === feedback && <Vector />}
            {feedback}
          </Option>
        ))}
        {selected === "Other" && (
          <OtherContentInput disable={otherContent === ""}>
            {displaySubmitText ? (
              <SubmitText>Thanks!</SubmitText>
            ) : (
              <input
                onChange={(e) => setOtherContent(e.target.value)}
                placeholder={"What went wrong?"}
              />
            )}
            <button
              disabled={otherContent === ""}
              onClick={() => otherSubmit(selected, otherContent)}
            >
              Submit
            </button>
          </OtherContentInput>
        )}
      </OptionsWrapper>
    </Container>
  );
};

export default SentimentsFeedback;
