/**
 * This is a bad file - it was copy pasted from the ideation.
 * 
 * It lacks context, all such constant needs to be defined where they are used
 * to follow locality of behaviour.
 * 
 * Most of these are used specifically, and only, for the KycForm. Why aren't they there?
 * 
 * Also note that there are no consistent conventions between the constants in src/constants directory.
 */
export const OTHER_OPTION = "Other (please specify)";

export const ROLES = [
  "Mechanical Engineer",
  "System Engineer",
  "Product/Industrial designer",
  "Executive/Manager",
  "Biomedical Engineer",
  "Educator",
  "Manufacturer",
  "Technician/practical engineer",
  "Art Designer",
  "Physician",
  "Inventor",
  "Marketing",
  "Sales",
  "Student",
  "Construction Architect",
  OTHER_OPTION,
];

export const INDUSTRY = [
  "Aerospace",
  "Architecture",
  "Arts and Entertainment",
  "Automotive",
  "Commercial Products",
  "Customer Electronics",
  "Customer Goods",
  "Dental",
  "Education",
  "Engineering Consulting Service",
  "Government/Military",
  "Industrial Equipment",
  "Jewelry",
  "Medical Device or Equipment",
  "Toys",
  "Other",
];

export const SOURCES = [
  "Friends and colleagues",
  "Social media content",
  "Blog post",
  "Podcast",
  "TV advertisement",
  "Conference or event",
  "Search engine",
  OTHER_OPTION,
];
