// Return one of upscale images
import * as analytics from '@/lib/analytics'
export const findVariation = (imgUrl, responses) => {
  const response = responses.find(({ upscale }) => upscale.find(({ uri }) => uri === imgUrl))
  return response && response.upscale.find(({ uri }) => uri === imgUrl)
}

// Used for UploadImage icon and UploadedImage

export const isValidImageMimeType = selectedImage => {
  // png, jpg, jpeg
  return selectedImage?.type?.match(/^image\/(png|jpe?g).*/) != null
}

/**
 * Searches for an object in an array based on the specified value within a structure with nested data.
 * @param {Array} dataArray - Array of objects to search through.
 * @param {string} targetValue - Target value to search for.
 * @param {string} childKey - Key containing the value in the nested data structure.
 * @param {string} [parentKey] - Key to access the nested data structure. If not provided, the search will be conducted at the root level.
 * @returns {Object|null} - The found object or null if nothing is found.
 */
export const findByKeys = (dataArray, targetValue, childKey, parentKey) => {
  const foundItem = dataArray.find(item => {
    const currentItem = parentKey ? item[parentKey]?.map(up => up[childKey]) : item[childKey]
    return currentItem && currentItem.includes(targetValue)
  })

  return foundItem || null
}

export const mockDataObject = {
  'upscale': [
    {
      'id': {
        'sessionNum': 40,
        'revisionNum': 1,
        'imageNum': 1,
      },
      'uri':
        'https://cdn.discordapp.com/attachments/1196441241060135072/1197095553905987685/midget1000_3D_CAD_model_of_A_big_blue_cup_with_a_smooth_and_glo_a82c407e-59fe-46b2-a83a-7e2fb0e76d35.png?ex=65ba051d&is=65a7901d&hm=d3bb98df34837234e1ed517a26f45cfdfb2ef3e2782621c05499a48262aef970&',
    },
    {
      'id': {
        'sessionNum': 40,
        'revisionNum': 1,
        'imageNum': 2,
      },
      'uri':
        'https://cdn.discordapp.com/attachments/1196441241060135072/1197095562147795005/midget1000_3D_CAD_model_of_A_big_blue_cup_with_a_smooth_and_glo_86fc69a3-3486-4aab-b7e6-f4d94d625ab2.png?ex=65ba051e&is=65a7901e&hm=db35e293997ee4e403d303d955445af0e32bc351fb36ce30a0b0471f06b92f2f&',
    },
    {
      'id': {
        'sessionNum': 40,
        'revisionNum': 1,
        'imageNum': 3,
      },
      'uri':
        'https://cdn.discordapp.com/attachments/1196441241060135072/1197095569533968514/midget1000_3D_CAD_model_of_A_big_blue_cup_with_a_smooth_and_glo_69b9461d-e1be-4414-8ae5-d27e6bafc260.png?ex=65ba0520&is=65a79020&hm=f956ddccf771d0cd1386b69800c869e834ff577c9fc597809feff8b23a096635&',
    },
  ],
}

export function isMobileCheck() {
  if (/Android|webOS|iPhone|iPad|iPod|Electron|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
    analytics.track('Non Browser Access', { userAgent: navigator.userAgent })
    return true
  }
  return false
}
