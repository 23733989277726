import React, { useState } from 'react'
import { useForm, Controller } from 'react-hook-form'
import { FormControl, Grid, InputLabel, MenuItem, Select, TextField } from '@mui/material'
import { useFetchData } from '../../api'
import { Button } from '../common/Button'
import * as analytics from '../../lib/analytics'
import { KYC_ENDPOINT } from '../../lib/config'
import { INDUSTRY, OTHER_OPTION, ROLES, SOURCES } from './constants'

import * as observability from '../../lib/observability'

import styles from './styles.module.scss'

async function submitFormData(fetchData, values) {
  values.name = `${values.firstName} ${values.lastName}`

  try {
    await fetchData({ method: 'POST', url: KYC_ENDPOINT, data: { data: values } })
  } catch (error) {
    observability.captureException('Failed to POST KYC', {
      error,
      tags: { url: KYC_ENDPOINT, method: 'POST' },
      extra: { values },
    })
  }

  analytics.registerKYCInfo(values)
  analytics.track('KYC Form Submitted', values)
}

export const OnboardingForm = ({ submitCallback }) => {
  const { fetchData } = useFetchData()

  const [role, setRole] = useState('')
  const [source, setSource] = useState('')

  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      firstName: '',
      lastName: '',
      location: '',
      role: '',
      otherRole: '',
      companyName: '',
      industry: '',
      source: '',
      otherSource: '',
    },
  })

  const onSubmit = async values => {
    await submitFormData(fetchData, values)
    submitCallback()
  }

  return (
    <>
      <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Controller
              name="firstName"
              {...{ control }}
              render={({ field }) => {
                return (
                  <FormControl style={{ width: '100%' }}>
                    <TextField
                      label={'First Name'}
                      id="first-name"
                      variant="outlined"
                      {...field}
                      {...register('firstName', { required: 'This field is required' })}
                      error={Boolean(errors.firstName)}
                      helperText={errors.firstName?.message}
                    />
                  </FormControl>
                )
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <Controller
              name="lastName"
              {...{ control }}
              render={({ field }) => (
                <FormControl required style={{ width: '100%' }}>
                  <TextField
                    label="Last name"
                    variant="outlined"
                    {...field}
                    {...register('lastName', { required: 'This field is required' })}
                    error={Boolean(errors.lastName)}
                    helperText={errors.lastName?.message}
                  />
                </FormControl>
              )}
            />
          </Grid>

          <Grid item xs={6}>
            <Controller
              name="role"
              control={control}
              render={({ field }) => (
                <FormControl
                  style={{ width: '100%' }}
                  {...register('role', { required: 'This field is required' })}
                  error={Boolean(errors.role)}
                  helperText={errors.role?.message}
                >
                  <InputLabel id="role-label">Role</InputLabel>
                  <Select
                    labelId="role-label"
                    id="role-select"
                    label="Role"
                    {...field}
                    onChange={e => {
                      setRole(e.target.value)
                      field.onChange(e.target.value)
                    }}
                  >
                    {ROLES.map(role => (
                      <MenuItem key={role} value={role}>
                        {role}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
            />

            {role === OTHER_OPTION && (
              <Controller
                name="otherRole"
                control={control}
                render={({ field }) => (
                  <TextField
                    label="Provide your role"
                    fullWidth
                    {...field}
                    {...register('otherRole', { required: 'This field is required' })}
                    error={Boolean(errors.otherRole)}
                    helperText={errors.otherRole?.message}
                  />
                )}
              />
            )}
          </Grid>
          <Grid item xs={6}>
            <Controller
              name="companyName"
              {...{ control }}
              render={({ field }) => (
                <TextField
                  fullWidth
                  label="Company name"
                  variant="outlined"
                  {...field}
                  {...register('companyName', { required: 'This field is required' })}
                  error={Boolean(errors.companyName)}
                  helperText={errors.companyName?.message}
                />
              )}
            />
          </Grid>

          <Grid item xs={12}>
            <Controller
              name="industry"
              control={control}
              render={({ field }) => (
                <FormControl
                  {...register('industry', { required: 'This field is required' })}
                  error={Boolean(errors.industry)}
                  helperText={errors.industry?.message}
                  fullWidth
                >
                  <InputLabel id="industry">Industry</InputLabel>
                  <Select labelId="industry" label="Industry" {...field}>
                    {INDUSTRY.map(industry => (
                      <MenuItem key={industry} value={industry}>
                        {industry}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              name="source"
              control={control}
              style={{ width: '100%' }}
              render={({ field }) => (
                <FormControl style={{ width: '100%' }}>
                  <InputLabel id="source-label">How did you hear about Leo?</InputLabel>
                  <Select
                    labelId="source-label"
                    id="source"
                    label="How did you hear about Leo?"
                    {...field}
                    onChange={e => {
                      setSource(e.target.value)
                      field.onChange(e.target.value)
                    }}
                  >
                    {SOURCES.map(source => (
                      <MenuItem key={source} value={source}>
                        {source}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
            />

            {source === OTHER_OPTION && (
              <Controller
                name="otherSource"
                control={control}
                style={{ width: '100%' }}
                render={({ field }) => (
                  <FormControl style={{ width: '100%' }}>
                    <TextField label="How did you hear about Leo?" {...field} />
                  </FormControl>
                )}
              />
            )}
          </Grid>
          <Grid item xs={12}>
            <FormControl>
              <Button type="submit" variant="contained" className={styles.submitButton}>
                Done
              </Button>
            </FormControl>
          </Grid>
        </Grid>

        {/* <Controller
            name="location"
            control={control}
            render={({ field }) => (
              <FormControl required>
                <Autocomplete
                  options={COUNTRIES}
                  autoHighlight
                  getOptionLabel={option => option.label}
                  renderOption={(props, option) => (
                    <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                      <img
                        loading="lazy"
                        width="20"
                        src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                        srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                        alt=""
                      />
                      {option.label} ({option.code}) +{option.phone}
                    </Box>
                  )}
                  renderInput={params => (
                    <TextField
                      {...params}
                      required
                      label="Location"
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: 'new-password', // disable autocomplete and autofill
                      }}
                      {...field}
                    />
                  )}
                />
              </FormControl>
            )}
          /> */}
      </form>
    </>
  )
}
