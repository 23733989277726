import { useEffect } from 'react'

export const useUndoRedoShortcut = (undoAction, redoAction) => {
  useEffect(() => {
    const handleKeyDown = event => {
      // Check for both Ctrl (Windows/Linux) and Command (Mac) keys
      const isCtrlKey = event.ctrlKey || event.metaKey

      if (isCtrlKey && event.key === 'z') {
        if (event.shiftKey) {
          redoAction()
        } else {
          undoAction()
        }
      }
    }

    window.addEventListener('keydown', handleKeyDown)

    return () => {
      window.removeEventListener('keydown', handleKeyDown)
    }
  }, [undoAction, redoAction])
}
