import EditIcon from "../../assets/icons/edit.svg?react";
import { formatDate } from 'date-fns';

import { SessionsTime } from "./SessionsHistoryListStyle";
import * as sessionsApi from '@/api/sessions';

import styles from './SessionHistoryListItem.module.css';
import { ChangeEvent, KeyboardEvent, useEffect, useState } from "react";
import {Button} from "@/src-ideation/components/common/Button";

export interface Props {
    // It was any in the SessionHistoryList,
    // I won't type it now because i'm just extracting the logic not modifying it.
    // TODO: type the session! If we don't use types as needed, typescript is only a liability.
    session: any;
    onSessionClick: () => void;
}

const parseTimestamp = (dateString: string) => formatDate(dateString, "dd/MM/yyyy");

export async function commitTitleChange(sessionNum: number, newTitle: string) {
    await sessionsApi.updateSessionData(sessionNum, { headline: newTitle });
}

function Headline(props: {
    headline: string,
    onTitleClick: () => void,
    onUpdateTitle: (title: string) => Promise<void>,
}) {
    const [activeHeadline, setActiveHeadline] = useState<string>("");
    const [editHeadlineMode, setEditHeadlineMode] = useState<boolean>(false);

    useEffect(() => {
        setActiveHeadline(props.headline);
    }, [props.headline])

    function handleHeadlineInputChange(e: ChangeEvent<HTMLInputElement>) {
        setActiveHeadline(e.target.value);
    }

    function updateTitle() {
        props
            .onUpdateTitle(activeHeadline)
            .catch(() => {
                setActiveHeadline(props.headline);
            })
            .finally(() => {
                setEditHeadlineMode(false);
            });
    }

    function handleHeadlineInputKeyup(e: KeyboardEvent<HTMLInputElement>) {
        if (e.key == "Enter") {
            updateTitle();
        }
    }

    function handleEditClick() {
        setEditHeadlineMode(true);
    }

    function handleSaveClick() {
        updateTitle();
    }

    return (
        <div className={styles.headlineContainer}>
            {editHeadlineMode ?
                <div>
                    <input type="text" value={activeHeadline}
                           onChange={handleHeadlineInputChange}
                           onKeyUp={handleHeadlineInputKeyup}/>
                </div> :
                <span className={styles.title} onClick={props.onTitleClick}>
                    {activeHeadline}
                </span>
            }

            <div className={styles.editButton} onClick={handleEditClick}>
                {editHeadlineMode ?
                    <Button variant="contained" onClick={handleSaveClick} className="save-button">Save</Button> :
                    <EditIcon/>
                }
            </div>
        </div>
    )
}

export default function SessionHistoryListItem(props: Props) {
    const { session, onSessionClick } = props;

    return (
        <div className={styles.container}>
            <Headline
                headline={session.headline}
                onTitleClick={onSessionClick}
                onUpdateTitle={title => commitTitleChange(session.sessionNum, title)}
                />
            <SessionsTime>{parseTimestamp(session.createdAt)}</SessionsTime>
        </div>
    )
}
