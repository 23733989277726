export function dateFormatConversion(date: string): string {
  //convert from 2024-01-14T15:20:19.668Z to dd/mm/yy

  const inputDate = new Date(date)

  const day = inputDate.getUTCDate().toString().padStart(2, '0')
  const month = (inputDate.getUTCMonth() + 1).toString().padStart(2, '0')
  const year = inputDate.getUTCFullYear().toString().slice(2)

  return `${day}/${month}/${year}`
}
