import React, { useEffect, useState } from 'react'
import clsx from 'clsx'
import { useTechSpecStore } from '../../../store/useTechSpecStore'
import { CopyAndExportControls } from '../../CopyAndExportControls'
import { LikeButton } from '../../LikeAndDislikeControls/LikeButton'
import { DislikeButton } from '../../LikeAndDislikeControls/DislikeButton'
import { useLikeDislike } from '../../../hooks/useLikeDislike'
import * as analytics from '../../../lib/analytics'
import { Button } from '../../common/Button'
import { LeoProModal } from '../../LeoProModal'
import { ProjectDocsContainer } from '../ProjectDocsContainer'
import '../EditableTextWindow/EditableTextWindow.scss'
import Document from '../Document'
import useDocsStore from '../../../store/useDocsStore'
import { FEATURE_VIEW_PROJECT_DOCS } from '../../../lib/config'

export const ProductSummary = () => {
  const { liked, disliked, clickLikeDislikeHandler } = useLikeDislike()
  const { text, currentVersionIndex } = useTechSpecStore(store => store)
  const state = useDocsStore()

  const dataArrived =
    state.getDoc('product-summary', currentVersionIndex) &&
    !state.getDoc('product-summary', currentVersionIndex)?.isLoading
  const [displayProjectDocsModal, setDisplayProjectDocsModal] = useState(false)
  const [content, setContent] = React.useState<string | undefined>()

  // Track the analytic event that a sentiment has been changed!!!
  useEffect(() => {
    if (liked || disliked) {
      const props = { text, liked, disliked, doc: 'product-summary' }
      analytics.track('Project Docs Sentiment Changed', props)
    }
  }, [liked, disliked, text])

  const handleContentChange = (content: string) => {
    setContent(content)
  }

  const handleDislikeClick = (e: Event) => {
    clickLikeDislikeHandler(e, 'dislike')
  }

  const handleLikeClick = (e: Event) => {
    clickLikeDislikeHandler(e, 'like')
  }

  const handleOpenProjectDocsModal = () => {
    setDisplayProjectDocsModal(true)
    analytics.track('Open Project Docs Modal', {})
  }
  return (
    <>
      <div className={clsx('editable-text-window', 'dark', FEATURE_VIEW_PROJECT_DOCS && 'display-project-docs-button')}>
        <div className="view-container">
          <div className="textwindow-buttons-bar">
            <div className="controls">
              {dataArrived && (
                <>
                  <LikeButton liked={liked} clickHandler={handleLikeClick} />
                  <DislikeButton disliked={disliked} clickHandler={handleDislikeClick} />
                  <CopyAndExportControls text={content} />
                </>
              )}
            </div>
          </div>

          <Document name="product-summary" onContentChange={handleContentChange} />
        </div>
      </div>

      {dataArrived && FEATURE_VIEW_PROJECT_DOCS && (
        <Button onClick={handleOpenProjectDocsModal} className={'project-docs-button'}>
          View Project Docs
        </Button>
      )}

      <LeoProModal
        type={'project-docs-modal'}
        modalIsOpen={displayProjectDocsModal}
        setModalIsOpen={setDisplayProjectDocsModal}
        hideCloseButton
      >
        <ProjectDocsContainer onClose={() => setDisplayProjectDocsModal(false)} />
      </LeoProModal>
    </>
  )
}
