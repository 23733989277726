export const chooseParts = [
  'how does a mobius kaleidocycle work',
  'what is special about the sr-71',
  'why does a jet engine need a stator',
  'solve the navier-stokes equation',
  'Do antimicrobial coatings comply with food safety regulations?',
  "Does the UV-protective coating affect the touchscreen's sensitivity or clarity?",
  'Explain what is Quantum Entanglement',
]

export const bestCoating = [
  'I want to build an ion thruster, what are the first steps',
  'give me 5 alternatives for aluminum to make my design lighter',
  'what do i need to anodize aluminum at home',
  'compare and contrast different options for actuating a robot arm',
  'Suggest the coating best suited for the shaft of a race car',
  'How to calculate magnet pull force?',
  'list advantages and disadvantages of servo motors',
  'clarify the meaning of true position 0.03',
]

export const bestManufacturing = [
  'Explain how Lift Force works',
  'Explain what E=mc^2 means',
  'Explain how Superconductors work',
  'Explain what a PMM2 is in the field of thermodynamics',
  'Explain Quantum mechanics',
  'explain the difference between UNF & UNC',
  'what is the difference between AISI 1117 and 1040 steel?',
  'discuss the importance of draft angle for steel in sand castings',
  'list benefits and drawbacks of phosphate manganese as a finish on steel',
]

export const howToCalculate = [
  'which is best suited for machine drilling, 304 stainless steel or 1020 steel?',
  'suggest a coating for aluminum that provides corrosion resistance and good grip',
  'compare delarin to policarbonate in terms of friction & wear of o ring',
  'what is the difference between bending beam load cell and shear beam load cell',
  'give examples of ways to damp a pneumatic cylinder',
  'suggest some methods to join aluminum to steel',
  'suggest methods to increase friction coefficient between two clamped parts',
  'propose a development plan for silent fireworks',
]

export const explainIdeas = [
  'Explain how Lift Force works',
  'Explain what is Quantum Entanglement',
  'Explain what E=mc^2 means',
  'Explain PMM2',
  'Explain Superconductors',
  'Explain Quantum mechanics',
]

export const ideateSuggestions = [
  'I need ideas for a new type of ergonomic office chair',
  'design a train as if it was run by jet engines',
  'I have an idea I want to visualize',
  'help me make a concept of my idea',
]

export const findSuggestions = [
  'find a ball bearing with inner diameter of 30 mm and',
  'I need a M8 Stainless Steel Helical Inserts for Particle-Free Environments',
  'get me a heavy duty check valve for 3/4’’ tube',
  'Find a lightweight aluminum bracket for my CAD model',
  'I need a motor with specific torque and speed characteristics for my design',
  'Locate a sensor that fits within these dimensions and has these features',
  'what are standard sizes for stainless steel tube',
  'I need to find a flexible coupling for 2 shafts with specific dimensions and tolerances',
]

type Category = {
  title: string
  suggestions: string[]
}

//todo enable 'Find' once the parts retrieval feature is on
const categories = [
  {
    title: 'Learn',
    suggestions: chooseParts,
  },
  {
    title: 'Develop',
    suggestions: bestCoating,
  },
  {
    title: 'Ideate',
    suggestions: ideateSuggestions,
  },
  // {
  //   title: 'Find',
  //   suggestions: findSuggestions,
  // },
  {
    title: 'Explain ideas',
    suggestions: explainIdeas,
  },
]

export function useRandomSuggestions() {
  const generateRandomSuggestions = (numberOfCategories: number, suggestionsPerCategory: number): Category[] => {
    return getRandomSuggestions(numberOfCategories, suggestionsPerCategory)
  }

  function getRandomSuggestions(numberOfCategories: number, suggestionsPerCategory: number) {
    return pickRandomIndices(numberOfCategories, categories.length)
      .map(i => categories[i])
      .map(category => ({
        title: category.title,
        suggestions: pickRandomIndices(suggestionsPerCategory, category.suggestions.length).map(
          suggestionIndex => category.suggestions[suggestionIndex]
        ),
      }))
  }

  // pickRandomIndices(
  function pickRandomIndices(pickCount: number, maxIndex: number) {
    const indices = Array(maxIndex)
      .fill(0)
      .map((_, i) => i)
    const chosen = []
    for (let i = 0; i < pickCount; ++i) {
      const randomPosition = Math.floor(Math.random() * indices.length)
      chosen.push(indices.splice(randomPosition, 1)[0])
    }

    return chosen
  }

  return { generateRandomSuggestions }
}
