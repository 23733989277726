import { LeoProModal } from '../../../LeoProModal'
import React, { useEffect, useRef } from 'react'
import { useGalleryModal } from '../../../../store/useGalleryModal'
import { LikeButton } from '../../../LikeAndDislikeControls/LikeButton'
import clsx from 'clsx'
import styles from './GalleryModal.module.scss'
import { DislikeButton } from '../../../LikeAndDislikeControls/DislikeButton'
import { VariationButton } from '../Image/VariationButton'
import * as analytics from '../../../../lib/analytics'
import { downloadImageWithWatermark } from '../Image'
import DownloadIcon from '../../../../assets/icons/download.svg?react'
import { ShareButton } from '../Image/ShareButton'
import { Button } from '@mui/material'
import { useLeoProModal } from '../../../../store/useLeoProModal'
import { MagicEraserButton } from '../Image/MagicEraserButton'
import CloseButton from '../../../../assets/icons/x-button.svg?react'
import { useGalleryStore } from '../../../../store/useGalleryStore'
import { findByKeys } from '../../../../utils'
import { FEATURE_MAGIC_ERASER } from '../../../../lib/config'
import { useTechSpecStore } from '../../../../store/useTechSpecStore'
import { useIdeationChat } from '@/src-ideation/store/useChat.js'

export const GalleryModal = () => {
  const { imageUrl, openModal, setOpenModal, imagesData, currentImageUrl } = useGalleryModal()
  const { removeImage, likeDislikeImages, setDislikeImage, setLikeImage } = useGalleryStore(store => store)
  const promptVersions = useIdeationChat(store => store.promptVersions)
  const { imagesContent } = useTechSpecStore(store => store)
  const imageRef = useRef(null)
  const canvasRef = useRef(null)
  const currantImage = likeDislikeImages.find(image => image.uri === imageUrl)
  const { liked, disliked } = currantImage || {}
  const { setShowLeoProModal } = useLeoProModal()

  const currentImageInData = imagesData.find(obj => obj.uri === currentImageUrl)
  const revNum = currentImageInData?.id?.revisionNum
  const currentImageTitle = imagesContent[revNum]?.title
  const currentImageText = imagesContent[revNum]?.description

  const filteredArray = findByKeys(imagesData, imageUrl, 'uri', 'upscale')
  const title = filteredArray?.requestMessage || currentImageTitle || ''
  const text = filteredArray?.promptVersion || currentImageText || ''

  useEffect(() => {
    if (openModal) {
      analytics.track('Img Modal - Opened', { imageUrl, prompt: text })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (liked || disliked) {
      analytics.track('Image Sentiment Changed', {
        imageUrl,
        liked,
        disliked,
        prompt: promptVersions[promptVersions.length - 1],
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [imageUrl, liked, disliked])

  const handleDislikeClick = () => {
    analytics.track('Img Modal - Dislike Image Button Clicked', {
      imageUrl,
      prompt: promptVersions[promptVersions.length - 1],
    })
    setDislikeImage(imageUrl)
  }

  const handleLikeClick = () => {
    analytics.track('Img Modal - Like Image Button Clicked', {
      imageUrl,
      prompt: promptVersions[promptVersions.length - 1],
    })
    setLikeImage(imageUrl)
  }

  const downloadVariant = async e => {
    e.stopPropagation()
    analytics.track('Img Modal - Download Image Variant Clicked', {
      imageUrl,
      prompt: promptVersions[promptVersions.length - 1],
    })
    downloadImageWithWatermark(canvasRef, imageUrl)
  }

  const exportClickHandler = () => {
    analytics.track('Img Modal - Export CAD Clicked')
    setShowLeoProModal(true)
  }

  const deleteClickHandler = () => {
    analytics.track('Img Modal - Delete Image Clicked', { imageUrl, prompt: promptVersions[promptVersions.length - 1] })
    removeImage(imageUrl)
    setOpenModal(!openModal)
  }

  const handleClosedModal = () => {
    analytics.track('Img Modal - Closed', { imageUrl, prompt: promptVersions[promptVersions.length - 1] })
    setOpenModal(!openModal)
  }

  return (
    <LeoProModal type={'imgModal'} hideCloseButton modalIsOpen={openModal} setModalIsOpen={() => handleClosedModal()}>
      <div className={styles.closeBtn} onClick={() => handleClosedModal()}>
        <div className={styles.closeBtnIcon}>
          <CloseButton width={'100%'} height={'100%'} />
        </div>
        <div className={styles.closeBtnText}>Close</div>
      </div>

      <div className={styles.componentWrap}>
        <div className={styles.leftWrap}>
          <div className={styles.buttonImgWrap}>
            <LikeButton
              liked={liked}
              clickHandler={handleLikeClick}
              onImageStyle={clsx(styles.button, styles.ImageLikeButton)}
            />
            <DislikeButton
              disliked={disliked}
              clickHandler={handleDislikeClick}
              onImageStyle={clsx(styles.button, styles.ImageDislikeButton)}
            />

            <ShareButton type={'modalImg'} onImageStyle={styles.button} {...{ url: imageUrl }} />
          </div>
          <div className={styles.imageWrap}>
            <canvas ref={canvasRef} style={{ display: 'none' }} />
            <img
              ref={imageRef}
              style={{
                width: '100%',
                height: 'auto',
              }}
              src={imageUrl}
              alt=""
            />

          </div>
          <div className={styles.lowerButtonsWrap}>
            {FEATURE_MAGIC_ERASER && (
                <MagicEraserButton
                    type={'edit'}
                    onImageStyle={styles.edit}
                    {...{ imageRef, imageUrl }}
                />
            )}
            <Button onClick={downloadVariant} className={styles.download}>
              Download
              <DownloadIcon fill="var(--leo-white)" />
            </Button>
            <VariationButton
              onClick={() => setOpenModal(false)}
              classProp={styles.variants}
              type={'modalImg'}
              {...{ imageRef }}
            />
            <Button onClick={deleteClickHandler} className={styles.delete}>
              Delete
            </Button>
          </div>
        </div>
        <div className={styles.rightWrap}>
          <h2>{title}</h2>
          <p style={{ opacity: 0.7 }}>{text}</p>
        </div>
      </div>
    </LeoProModal>
  )
}
