
/**
 * This is a bad file - it was copy pasted from the ideation.
 * 
 * It lacks context, all such constant needs to be defined where they are used
 * to follow locality of behaviour.
 * 
 * Also, don't we want to get these from the backend? How else will we maintain this?
 */
export const fileNames: { [key: string]: string } = {
  simulationsguidelinesincfd:
    "CFD Simulation & Guidelines, Ideen Sadrehaghighi, 2024.",
  mil_std_414: "Mil std 414",
  idema_mechanics_and_relativity_sept2019:
    "Mechanics and Relativity, Idema, Timon, 2018.",
  mil_std_105e: "Mil std 105e",
  total_quality_management_by_dale_h_besterfield_carol_besterfield_michna_glen_h_besterfield_mary_besterfield_sacre_hemant_urdhwareshe_rashmi_urdhwarshe_z_lib_org_:
    "Total Quality Management Revised Edition: For Anna University, 3/e, Besterfield, D.H. and Dale H. Besterfield, C.B.M.G.H.B.M.B.S.H.U.R.U., .",
  shigley_s_mechanical_engineering_design_10th_edition_:
    "Shigley's mechanical engineering design, Budynas, Richard Gordon and Nisbett, J Keith and others, 2011.",
  bsc_optics_2nd_edition_color:
    "BSc Optics, Konijnenberg, AP and Adam, AJL and Urbach, Paul, 2024.",
  mil_std_810h: "Mil std 810h",
  mil_hdbk_217f: "Mil hdbk 217f",
  duane_knudson_fundamentals_of_biomechanics_2ed:
    "Fundamentals of Biomechanics, Knudson, D., 2013.",
  fundamentals_of_thermodynamics_10e:
    "Fundamentals of thermodynamics, Borgnakke, Claus and Sonntag, Richard E, 2020.",
  handbook_of_industrial_engineering_equations_formulas_and_calculations:
    "Handbook of industrial engineering equations, formulas, and calculations, Badiru, Adedeji B and Omitaomu, Olufemi A, 2010.",
  "13_02_ali":
    "Statistical process control for total quality, Ali, Syed W, 1992.",
  epdf_pub_vehicle_dynamics_and_control_2nd_edition:
    "Vehicle dynamics and control, Rajamani, Rajesh, 2011.",
  control_systems: "Control systems",
  fundamentals_of_compressible_fluid_mechanics:
    "Fundamentals of Compressible Fluid Mechanics, Genick Bar--Meir, Ph D, 2006.",
  hydraulic_design_ad0884519:
    "Engineering Design Handbook: Hydraulic Fluids, United States. Army Materiel Command, 1971.",
  surface_roughness_indications:
    "Surface roughness indications, JIS B 0601, 2024.",
  industrial_power_engineering_and_applications_handbook_pdfdrive_:
    "Industrial power engineering and applications handbook, KC, Agrawal, 2001.",
  handbook_of_optical_design:
    "Handbook of optical design, Malacara, Zacarias and Malacara-Hern{\\'a}ndez, Daniel and Malacara-Hern{\\'a}ndez, Zacar{\\'\\i}as, 2003.",
  handbook_battery_energy_storage_system:
    "Handbook battery energy storage system, ASIAN DEVELOPMENT BANK, 2018.",
  smart_materials_technologies_ada323851:
    "Smart Structures and Materials 1997: 3-4 March 1997, San Diego, California. Smart materials technologies, Simmons, W.C. and Aksay, I.A. and Huston, D.R. and Society of Photo-optical Instrumentation Engineers and Society for Experimental Mechanics (U.S.) and American Society of Mechanical Engineers, 1997.",
  material_overview_ansi: "material overview ansi, kennametal, 2024.",
  "1449_the_engineers_cost_handbook":
    "The Engineer's Cost Handbook: Tools for Managing Project Costs, Westney, R.E., 1997.",
  the_infrared_electro_optical_systems_handbook_electro_optical_components_volume_3_:
    "The Infrared \\& Electro-Optical Systems Handbook. Electro-Optical Components, Volume 3, Rogatto, William D and Accetta, Joseph S and Shumaker, David L, 1993.",
  text_bookof_finite_element_analysis_by_p_seshu_1_:
    "TEXTBOOK OF FINITE ELEMENT ANALYSIS, SESHU, P., 2003.",
  fundamentals_of_physics_textbook:
    "Fundamentals of physics, Halliday, David and Resnick, Robert and Walker, Jearl, 2013.",
  tension_control_complete_guide:
    "Tension control complete guide, Mitsubishi, 2024.",
  pram_guide_ma:
    "Project Risk Analysis and Management Guide, Bartlett, J., 2004.",
  handbookofmechanicaldesign00nord:
    "Handbook of Mechanical Design (Classic Reprint), Nordenholt, G.F., 2017.",
  structured_electronics_design:
    "Structured Electronics Design, Montagne, Anton JM, 2023.",
  thermal_engineering_i_ii_by_s_ramachandr:
    "thermal engineering, S.Ramachandran, 2024.",
  mechvib_theory_and_applications:
    "Mechanical Vibrations: Theory and Applications, Kelly, S.G., 2022.",
  handbook_renewable_energy_engineering_an:
    "Renewable Energy Handbook Engineering & Property, RISK CONTROL PRACTICE: OCCUPANCY, Didier L. SCH\u00dcTZ, 2024.",
  handbook_of_optical_and_laser_scanning:
    "Handbook of optical and laser scanning, Marshall, Gerald F and Stutz, Glenn E, 2012.",
  vehicle_dynamics_theory_and_applications:
    "Vehicle dynamics, Jazar, Reza N, 2008.",
  mil_std_464c: "Mil std 464c",
  handbook_of_optics_volume_ii_devices_measurements_and_properties_:
    "Handbook of optics volume ii devices, measurements, Bass, Michael and Stryland, Eric W Van and Williams, David R and Wolfe, Williams L, 1995.",
  structural_engineers_pocket_book:
    "Structural Engineer's Pocket Book, Cobb, F., 2004.",
  classical_numerical_methods_in_scientific_computing:
    "Classical Numerical Methods in Scientific Computing, van Kan, JJIM and Segal, A and Vermolen, Fred, 2023.",
  stainless_steel_grade_datasheets:
    "Stainless steel grade datasheets, Steels, Atlas, 2013.",
  introduction_to_machine_learning_smola:
    "Introduction to machine learning, Smola, Alex and Vishwanathan, SVN, 2008.",
  piping_handbook: "Piping Handbook, Nayyar, M.L., 1999.",
  stas_bekman_machine_learning_engineering:
    "Machine Learning Engineering Open Book, stas Beckman, 2024.",
  computational_materials_science:
    "Computational Materials Science: The Simulation of Materials, Microstructures and Properties, Raabe, D., 1998.",
  carbon_steel_handbook_: "Carbon steel handbook, Gandy, D, 2007.",
  misumi_technical_data:
    "DESAIN CETAKAN INJEKSI PLASTIK PRODUK GANTUNGAN DINDING, ARDI HAS GIANT, ANTARIKSA and ERDIAN, SUNTOSA, 2022.",
  fundamentals_aerospace_engineering_soler_2014:
    "Fundamentals of Aerospace Engineering: An Introductory Course to Aeronautical Engineering, Soler, M. and Soler, M., 2014.",
  power_engineering_guide_siemense:
    "Power engineering guide, Siemens, AG, 2017.",
  bme_fundamental_bronzino:
    "Biomedical Engineering Fundamentals, Bronzino, J.D. and Peterson, D.R., 2014.",
  a_designers_handbook_series_n_o_9014_des:
    "DESIGN GUIDELINES FOR THE SELECTION AND USE OF STAINLESS STEELS, SERIES, A DESIGNERS\u2019HANDBOOK, .",
  "25362631":
    "Cryogenics for particle accelerators and detectors, Lebrun, Ph and Tavian, L and Wagner, U and Vandoni, Giovanna, 2002.",
  the_measurement_instrumentation_and_sensors_handbook:
    "Measurement, Instrumentation, and Sensors Handbook: Two-Volume Set, Webster, John G and Eren, Halit, 2018.",
  applied_industrial_electricity_1591728448:
    "Applied Industrial Electricity: Theory and Application, John, Haughery and Tony R, Kuphaldt and others, 2021.",
  henrikbruus_microfluidics_lectures:
    "Theoretical Microfluidics, Bruus, H., 2008.",
  modern_communications_systems:
    "AModern communications systems: A first course, Todor Cooklev, Andrew E. Yagle, 2024.",
  handbook_of_electric_power_calculations_beaty:
    "Handbook of electric power calculations, Beaty, H Wayne, 2001.",
  handbook_of_optics_volume_i_fundamentals_techniques_and_design:
    "Handbook of optics volume i fundamentals, techniques, Bass, Michael and Stryland, Eric W Van and Williams, David R and Wolfe, William L, 1995.",
  outokumpu_stainless_steel_handbook:
    "Outokumpu stainless steel handbook, Outokumpu, 2024.",
  manufacturing_processes_4_5:
    "Manufacturing Processes 4-5, Virasak, LamNgeun, 2019.",
  alternative_fuels_from_biomass_sources_:
    "Alternative fuels from biomass sources, Toraman, H, 2020.",
  mechatronics_sathiyagnanam: "mechatronics, sathiyagnanam, 2024.",
  mil_std_461g: "Mil std 461g",
  gd_t_indications_and_surface_roughness:
    "Gd&t indications and surface roughness, Gd&t, 2021.",
  power_plant_engineering:
    "Power plant engineering, Raja, AK and Srivastava, Amit Prakash, 2006.",
  control_systems_an_introduction:
    "Control Systems: An Introduction, Khalil, Hassan K, 2023.",
  fundamentals_of_nuclear_science_engineering:
    "Fundamentals of Nuclear Science and Engineering, Shultis, J.K. and Faw, R.E., 2016.",
  handbook_of_energy_engineering_calculations_:
    "Handbook of energy engineering calculations, Hicks, Tyler Gregory, 2012.",
  aerodynamics_and_aircraft_performance_3e:
    "Aerodynamics and aircraft performance, Marchman, James F, 2004.",
  the_structural_engineers_professional_tr:
    "The Structural Engineer\u2019s Professional Training Manual, Adams, D.K., 2007.",
  cold_rolled_steel_sheet_: "Cold rolled steel sheet , JFE, 2024.",
  elementsofacousticalengineering_11325713:
    "Elements of Acoustical Engineering, Olson, H.F., 1947.",
  introduction_to_power_system_enegineering:
    "Introduction to power system engineering, Goli, Preetham, 2019.",
  environmental_engineering_weiner:
    "Environmental Engineering, Weiner, R. and Matthews, R., 2003.",
  food_contact_materials_metals_and_alloys:
    "Food contact materials-metals and alloys: Nordic guidance for authorities, industry and trade, Cederberg, Dorthe Licht and Christiansen, Mette and Ekroth, Susanne and Engman, Joakim and Fabech, Bente and Gu{\\dh}j{\\'o}nsd{\\'o}ttir, Katr{\\'\\i}n and H{\\aa}land, Julie Tesdal and J{\\'o}nsd{\\'o}ttir, Ingibj{\\\"o}rg and Kostaomo, Pirkko and Legind, Charlotte and others, 2015.",
  renewable_energy_sources: "Renewable Energy Sources, katravath bocha, 2024.",
  space_systems_engineering_fundamentals_t:
    "Space Systems Engineering, Wikibooks, 2024.",
  introduction_to_industrial_engineering_1612822866:
    "Introduction to Industrial Engineering, Boardman, Bonnie, 2020.",
  mechanical_engineers_handbook_volume_4_energy_and_power_4e:
    "Mechanical Engineers' Handbook, Volume 4: Energy and Power, Kutz, Myer, 2015.",
  asm_metals_handbook_vol_04:
    "ASM Handbook, ASM International and ASM International. Alloy Phase Diagram Committee and ASM International. Handbook Committee, 1991.",
  interpretation_of_metal_fab_drawings_1593030189:
    "Interpretation of Metal Fab Drawings, Moran, Cameren, 2021.",
  theelementsofrailroadengineering_10072906:
    "The Elements of Railroad Engineering, Raymond, W.G., 1917.",
  numerical_methods_in_scientific_computing:
    "Numerical methods in scientific computing, van Kan, JJIM and Segal, August and Vermolen, Fredericus Johannes, 2023.",
  mechanical_engineers_handbook_volume_1_materials_and_engineering_mechanics_4e:
    "Mechanical engineers' handbook, volume 1: Materials and engineering mechanics, Kutz, Myer, 2015.",
  additive_menufacturing: "Additive menufacturing, Sid collins, 2024.",
  "4445_acoustical_engineering_3rd_edn":
    "Acoustical Engineering, Olson, H.F., 1991.",
  handbook_of_electrical_engineering:
    "Handbook of electrical engineering: for practitioners in the oil, gas and petrochemical industry, Sheldrake, Alan L, 2016.",
  mechanical_engineers_handbook_volume_2_design_instrumentation_and_controls_4e:
    "Mechanical Engineers' Handbook, Volume 2: Design, Instrumentation, and Controls, Kutz, Myer, 2015.",
  numerical_methods_for_ordinary_differential_equations:
    "Numerical methods for ordinary differential equations, Vuik, Cornelis and Vermolen, Fredericus Johannes and van Gijzen, Martin and Vuik, Mathea J, 2023.",
  mil_std_1235c: "Mil std 1235c",
  fundamentals_of_biomechanics_3nbsped_9783030518370_303051837x_compress:
    "Fundamentals of Biomechanics, Knudson, D., 2013.",
  advanced_web_process_control_handbook:
    "Advanced web process control handbook, CMC, 2005.",
  introductory_dynamics: "Introductory Dynamics, Steeneken, Peter G, 2024.",
  finite_element_analysis_fea_roylance:
    "Finite element analysis, Roylance, David, 2001.",
  aisi_chemical_composition_limits:
    "aisi chemical composition limits, ansi, 2024.",
  "21_total_quality_management_concepts":
    "Total Quality Management: Concepts, Evolution and Acceptability inDeveloping Economies, Lakhe, RR and Mohanty, RP, 1994.",
  additive_manufacturing_technologies_and_applications:
    "Additive manufacturing technologies and applications, Brischetto, Salvatore and Maggiore, Paolo and Ferro, Carlo Giovanni, 2018.",
  at_2007_001: "An introduction to cryogenics, Lebrun, Philippe, 2007.",
  dimensional_tolerances_metric_: "Dimensional tolerances metric , JIS, 2024.",
  a_pocket_book_of_marine_engineering_rules_and_tables_1922:
    "A Pocket Book of Marine Engineering Rules and Tables: For the Use of Marine Engineers, Naval Architects ... and All Engaged in the Design and Construction of Marine Machinery, Naval \\& Mercantile, Seaton, A.E. and Rounthwaite, H.M., 2022.",
  vehicle_dynamics_rill: "Vehicle dynamics, Rill, Gtorg, 2009.",
  fracture_mechanics:
    "Fracture Mechanics: An Engineering Primer, Janssen, Michael and Zuidema, Jan and Wanhill, RJH, 2024.",
  book_fundamentalsofbiomechanics:
    'Fundamentals of Biomechanics: Equilibrium, Motion, and Deformation, \\"O}zkaya, N. and Leger, D. and Goldsheyder, D. and Nordin, M., 2016.',
  mil_std_1916: "Mil std 1916",
  marks_standard_handbook_for_mechanical_engineers_11e:
    "Marks' Standard Handbook for Mechanical Engineers. 10, Avallone, Eugene and Baumeister, Theodore and Sadegh, Ali, 2006.",
  material_cross_reference: "material cross reference, material, 2005.",
  metals_handbook_vol_2_properties_and_selection_nonferrous_alloys_and_special_purpose_materials_:
    "ASM Handbook Volume 2: Properties and Selection: Nonferrous Alloys and Special-Purpose Materials, Nunes, Rafel and Adams, JH and Ammons, M and Avery, HS and Barnhurst, RJ and Bean, JC, 1990.",
  transportation_engg_tom_v_mathew:
    "Introduction to Transportation engineering, Mathew, Tom V and Rao, KV Krishna, 2006.",
  small_electric_vehicles:
    "Small electric vehicles: an international view on light three-and four-wheelers, Ewert, Amelie and Schmid, Stephan and Brost, Mascha and Davies, Huw and Vinckx, Luc, 2021.",
  "3d_printing_for_repair_guide":
    "3D Printing for Repair Guide, Bolanos Arriola, J and van Oudheusden, AA and Flipsen, Bas and Faludi, Jeremy, 2022.",
  opto_mechatronic_systems_handbook:
    "Opto-mechatronic systems handbook: techniques and applications, Cho, Hyungsuck, 2002.",
  mil_std_882e: "Mil std 882e",
  elements_of_robotics:
    "Elements of robotics, Ben-Ari, Mordechai and Mondada, Francesco, 2017.",
  modeling_web_handling_systems_and_a_method_for_determining_feedback_controller_gains_:
    "Modeling Web Handling Systems and a Method for Determining Feedback Controller Gains, Reish, Benjamin David, 2020.",
  diesle_and_gasoline_engines:
    "Diesel and gasoline engines, Viskup, Richard, 2020.",
  designguidelinesfortheselectionanduseofstainlesssteels_9014_:
    "Design Guidelines for the Selection and Use of Stainless Steel, Specialty Steel Industry of the United States and Specialty Steel Institute of North America and Nickel Development Institute (Canada) and American Iron, 1993.",
  tribology_handbook_2nd_edition_: "The Tribology Handbook, Neale, M.J., 1995.",
  fundamentals_of_modern_manufacturing_materials_processes_and_systems_4th_edition:
    "Fundamentals of Modern Manufacturing: Materials, Processes, and Systems, Groover, M.P., 2010.",
  aashto_materials_standards_41st_edition_2021_table_of_contents_revision_list_:
    "Standard specifications for transportation materials and methods of sampling and testing, American Association of State Highway and Transportation Officials, 2014.",
  direct_energy_conversion:
    "Direct Energy Conversion, Mitofsky, Andrea M, 2006.",
  lessons_in_industrial_instrumentation:
    "Lessons in industrial instrumentation, Kuphaldt, Tony R, 2008.",
  material_grades: "material grades, meusburger, 2024.",
  crc_hybrid_vehicles_freebook: "Hybrid & Electric Vehicles, crc press, 2024.",
  nasa_systems_engineering_handbook_0:
    "NASA Systems Engineering Handbook: NASA/SP-2016-6105 Rev2 - Black and White Version, , 2018.",
  project_management_for_engineering_busin:
    "Project Management for Business, Engineering, and Technology: Principles and Practice, Nicholas, J.M. and Steyn, H., 2008.",
  marks_calculations_for_machine_design:
    "Mark's Calculations For Machine Design, Brown, T.H., 2005.",
  machinery_s_handbook:
    "Machinery's Handbook, Oberg, Erik and Jones, Franklin D and Horton, Holbrook L and RYFFEL, HENRY H and MCCAULEY, CHRISTOPHER J and HEALD, RICCARDO and HUSSAIN, MUHAMMED IQBAL, 1914.",
  introduction_to_fluid_mechanics_fox:
    "Fox and McDonald's Introduction to Fluid Mechanics, Fox, R.W. and McDonald, A.T. and Mitchell, J.W., 2020.",
  introduction_to_nuclear_engineering_lamarsh_3rd_edition:
    "Introduction to Nuclear Engineering, Lamarsh, J.R. and Baratta, A.J., 2011.",
  reverse_engineering_j_jcde_2017_11_009:
    "Reverse engineering of mechanical parts: A template-based approach, Buonamici, Francesco and Carfagni, Monica and Furferi, Rocco and Governi, Lapo and Lapini, Alessandro and Volpe, Yary, 2018.",
  the_mechatronics_handbook_bishop2002:
    "The Mechatronics Handbook - 2 Volume Set, Bishop, R.H., 2018.",
  krejcilozano_propsamllsat_procieee2018presub:
    "Space propulsion technology for small spacecraft, Krejci, David and Lozano, Paulo, 2018.",
  coastal_dynamics_open_textbook_webversion1_2_svn1489_pp:
    "Coastal dynamics, Bosboom, Judith and Stive, Marcel JF, 2021.",
  standard_specification_for_zinc:
    "Standard specification for zinc, astm, 2024.",
  computational_materials_science_and_chemistry_rpt:
    "Computational materials science and chemistry: accelerating discovery and innovation through simulation-based engineering and science, Crabtree, George and Glotzer, Sharon and McCurdy, Bill and Roberto, Jim, 2010.",
  asm_metals_handbook_vol_01:
    "ASM Handbook, ASM International. Handbook Committee, 1990.",
  bim_project_execution_planning_guide_version_3_0_1619002597:
    "BIM Project Execution Planning Guide, Version 3.0, Messner, John, 2023.",
  opto_mechanical_systems_design_volume_1_design_and_analysis_of_opto_mechanical_assemblies_4e:
    "Opto-Mechanical Systems Design, Volume 2: Design and Analysis of Large Mirrors and Structures, Yoder, Paul and Vukobratovich, Daniel, 2017.",
  sheet_metal_design_guide: "Sheet metal design guide, GoProto, 2024.",
  qa8000_fabrication_standards_instruction_manual:
    "FABRICATION STANDARDS PROCEDURE 8000, Vacuum Technologies, 2005.",
  hvac4th:
    "HVAC Systems Design Handbook, Fifth Edition, Haines, R.W. and Myers, M.E., 2009.",
  introduction_to_autonomous_robots:
    "Introduction to autonomous robots: mechanisms, sensors, actuators, and algorithms, Correll, Nikolaus and Hayes, Bradley and Heckman, Christoffer and Roncone, Alessandro, 2022.",
  signals_and_systems: "Signals and Systems, Wikibooks, 2013.",
  geotechnical_engineering:
    "Geotechnical Engineering, Venkatramaiah, C., 2006.",
  mechanisms_and_mechanical_devices_sourcebook_5e:
    "Mechanisms and mechanical devices sourcebook, Sclater, Neil and Chironis, Nicholas P, 2001.",
  handbook_of_comparative_world_steel_standards_:
    "Hand Book of Comparative World Steel Standard s, Bringas, John E, 2004.",
  future_energy_opportunities_challenges:
    "Future Energy: Opportunities \\& Challenges, Kerlin, Thomas W, 2021.",
  double_r_controls_concept_of_continuous_process_web_handling_principles:
    "Double r controls concept of continuous process web handling principles, Rothwell, N, 2000.",
  civil_engineers_reference_book:
    "Civil Engineer's Reference Book, Blake, L.S., 1975.",
  winding_and_unwinding_webs_a_review_of_the_state_of_the_science_in_2005:
    "Winding and Unwinding Webs: A Review of the State of Science in 2005, Good, JK, 2005.",
  introduction_to_reverse_engineering:
    "Introduction to reverse engineering, Raja, Vinesh, 2008.",
  mems_materials_and_processes_handbook_ghodssi2011:
    "MEMS Materials and Processes Handbook, Ghodssi, R. and Lin, P., 2011.",
  mil_hdbk_1916: "Mil hdbk 1916",
  aectp_400_3: "aectp_400_3, J. MAJ, 2006.",
  steel_grades_properties_and_global_standards:
    "Steel grades properties and global standards, outokumpu, 2005.",
  mechanical_engineers_handbook_volume_3_manufacturing_and_measurement_4e:
    "Mechanical Engineers' Handbook, Volume 3: Manufacturing and Management, Kutz, Myer, 2015.",
  springer_handbook_of_lasers_and_optics:
    'Springer handbook of lasers and optics, Tr{\\"a}ger, Frank, 2012.',
  a_heat_transfer_textbook: "A heat transfer textbook, Lienhard, John H, 2005.",
  the_hydraulic_handbook_pdfdrive_:
    "The Hydraulic Handbook, Hunt, T.M. and Hunt, T. and Vaughan, N.D. and Vaughan, N., 1996.",
  smart_materials_new_technologies_for_the_architecture_design_professions:
    "Smart Materials and Technologies in Architecture, Addington, M. and Schodek, D., 2012.",
  alderliesten_introduction_to_aerospace_structures_and_materials:
    "Introduction to aerospace structures and materials, Alderliesten, Ren{\\'e, 2018.",
  electrical_engineering_handbook:
    "The Electrical Engineering Handbook,Second Edition, Dorf, R.C., 1997.",
  lecture_notes_on_quantum_electrical_circuits:
    "Lecture Notes on Quantum Electrical Circuits, Ciani, Alessandro and DiVincenzo, David P and Terhal, Barbara M, 2023.",
  electro_optics_handbook: "Electro-optics handbook, Waynant, Ronald W, 2000.",
  handbook_of_optics_vol_iv:
    "Handbook of Optics: Volume IV-Optical properties of materials, nonlinear optics, quantum optics, Bass, Michael, 2010.",
  the_infrared_electro_optical_systems_handbook_ir_systems_engineering_vol_2:
    "The Infrared \\& Electro-Optical Systems Handbook, Volume 2: Atmospheric Propagation of Radiation, Smith, Frederick G, 1993.",
  mil_a_8625f: "Mil a 8625f",
  advanced_space_propulsion_for_the_21st_century_frisbee:
    "Advanced space propulsion for the 21st century, Frisbee, Robert H, 2003.",
  mil_std_1472h: "Mil std 1472h",
};
