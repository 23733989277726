
import Box from '@mui/material/Box'
import Modal from '@mui/material/Modal'

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  minWidth: '270px',
  bgcolor: 'var(--leo-black)',
  borderRadius: 'var(--leo-radius)',
}

export const ModalWindow = ({ isOpen, handleClose, children }) => {
  return (
    <>
      <Modal
        open={isOpen}
        onClose={() => handleClose()}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>{children}</Box>
      </Modal>
    </>
  )
}
