import React, { useState } from 'react'
import {
  DataSheetButton,
  DetailsStyle,
  DownloadPartButton,
  TitleStyle,
  ButtonsWrapper,
  PartImage,
  Wrapper,
  ReadMore,
  SpecWrapper,
  LargePartImage,
} from '@/components/chatSection/partRetrieval/partRetrievalStyle.ts'
import SessionsPagination from '@/src-ideation/components/Sessions/SessionsPagination/sessionsPagination.tsx'

interface PartRetrievalProps {
  message: string
}
interface ComponentObject {
  _index: string
  _id: string
  _score: number
  _source: {
    imagesObjectUrl: any[]
    cadObjectUrl: string
    originUrl: string
    attributes: {
      'scraped-by': string
      alt: string
      header: string
      'general-description': string
      spec: { [key: string]: any }
    }
  }
}

interface ComponentsDisplay {
  [key: number]: any
}

const PartRetrieval: React.FC<PartRetrievalProps> = ({ message }) => {
  const [displaySpec, setDisplaySpec] = useState<ComponentsDisplay>({})
  const [displayLargerImage, setDisplayLargerImage] = useState<ComponentsDisplay>({})
  const [displayInnerLargerImage, setDisplayInnerLargerImage] = useState<ComponentsDisplay>({})

  const parsedData = JSON.parse(message)
  const showComponentSpec = (index: number) => {
    setDisplaySpec((currentComponentSpecs: ComponentsDisplay) => {
      const specDisplayStatus: boolean = !currentComponentSpecs[index]
      return { ...currentComponentSpecs, [index]: specDisplayStatus }
    })
  }

  const showLargerImage = (index: number, value: boolean) => {
    setDisplayLargerImage((currentComponentSpecs: ComponentsDisplay) => {
      return { ...currentComponentSpecs, [index]: value }
    })
  }

  const showInnerLargerImage = (index: number, value: boolean) => {
    setDisplayInnerLargerImage((currentComponentSpecs: ComponentsDisplay) => {
      return { ...currentComponentSpecs, [index]: value }
    })
  }

  const [page, setPage] = useState<number>(1)

  const historicalSessions = parsedData || []

  const sessionsPerPage = 2
  const pagesNumbersAmount = 4
  const pad = 1

  const numOfPages = Math.ceil(historicalSessions.length / sessionsPerPage)

  const indexOfLast = page * sessionsPerPage
  const indexOfFirst = indexOfLast - sessionsPerPage
  const currentSessionsList = historicalSessions.slice(indexOfFirst, indexOfLast)

  const changePage = (num: number, arrowNavigation?: boolean) => {
    setDisplaySpec({})
    if (arrowNavigation) {
      setPage(currentPage => currentPage + num)
    } else {
      setPage(num)
    }
  }

  return (
    <div>
      {(currentSessionsList || []).map((component: ComponentObject, index: number) => {
        const text = component._source.attributes['general-description'].split(' ')
        const first10WordsArray = text.slice(0, 10)
        const previewText = first10WordsArray.join(' ')
        const expandedText = text.slice(10).join(' ')

        return (
          <Wrapper key={component._source.originUrl + index}>
            <TitleStyle>{component._source.attributes.header}</TitleStyle>
            <DetailsStyle>
              {previewText}
              {!displaySpec[index] && (
                <ReadMore style={{ marginLeft: 5 }} onClick={() => showComponentSpec(index)}>
                  Read More
                </ReadMore>
              )}
              {displaySpec[index] && <span>{expandedText}</span>}
            </DetailsStyle>

            <SpecWrapper specHeight={displaySpec[index]}>
              {Object.keys(component._source.attributes.spec || {}).map((item: string) => {
                return (
                  <div key={item + index}>
                    <span>{item}</span>
                    {`: ${component._source.attributes.spec[item]}`}
                  </div>
                )
              })}
              {displaySpec[index] && (
                <>
                  <div
                    onMouseEnter={() => showInnerLargerImage(index, true)}
                    style={{ margin: '1rem 0', position: 'relative' }}
                    onMouseLeave={() => showInnerLargerImage(index, false)}
                  >
                    {displayInnerLargerImage[index] && (
                      <LargePartImage src={component._source.imagesObjectUrl[1]} alt="" />
                    )}

                    <PartImage src={component._source.imagesObjectUrl[1] || ''} alt="" />
                  </div>
                  <ReadMore onClick={() => showComponentSpec(index)}>Read Less</ReadMore>
                </>
              )}
            </SpecWrapper>

            <ButtonsWrapper>
              <DataSheetButton
                availableLink={component._source.originUrl}
                href={component._source.originUrl}
                target="_blank"
              >
                Data Sheet
              </DataSheetButton>
              <DownloadPartButton
                availableLink={component._source.cadObjectUrl}
                href={component._source.cadObjectUrl}
                target="_blank"
              >
                Download part
              </DownloadPartButton>

              <div
                style={{ position: 'relative' }}
                onMouseEnter={() => showLargerImage(index, true)}
                onMouseLeave={() => showLargerImage(index, false)}
              >
                {displayLargerImage[index] && <LargePartImage src={component._source.imagesObjectUrl[0]} alt="" />}
                <PartImage src={component._source.imagesObjectUrl[0] || ''} alt="" />
              </div>
            </ButtonsWrapper>
          </Wrapper>
        )
      })}

      <SessionsPagination
        numOfPages={numOfPages}
        setPage={changePage}
        currentPage={page}
        pagesNumbersAmount={pagesNumbersAmount}
        pad={pad}
        positionStatic={true}
      />
    </div>
  )
}
export default PartRetrieval
