import { toast } from 'react-toastify'
import { DESIGN_INPUTS_SOURCE } from '../constants/index.ts'
import { UPLOAD_ERROR_MESSAGE, UPLOAD_ERROR_FILE_TOO_BIG } from '../constants/msgEnums'
import { useDesignInputsStore } from '../store/useDesignInputsStore.ts'

export const ClearReason = {
  // UPLOAD_ERRO_MESSAGE is specific for invalid mime type.
  // Generally, the constants/msgEnums is too stretched.
  InvalidFormat: UPLOAD_ERROR_MESSAGE,

  FileTooBig: UPLOAD_ERROR_FILE_TOO_BIG,
}

export const useDesignInputs = () => {
  const {
    lines,
    uploadedImage,
    base64String,
    sketchBase64,
    setSketchBase64,
    setBase64String,
    magicEraserPayload,
    queue,
  } = useDesignInputsStore(store => store)

  const queueLastItem = queue[queue.length - 1]

  const source = queueLastItem?.type || (lines.length > 0 ? DESIGN_INPUTS_SOURCE.SKETCH : DESIGN_INPUTS_SOURCE.NONE)

  const getImageSrc = item => {
    switch (item?.type) {
      case DESIGN_INPUTS_SOURCE.UPLOADED:
        return URL.createObjectURL(item.image)
      case DESIGN_INPUTS_SOURCE.MAGIC_ERASER:
        return item.image.src
      case DESIGN_INPUTS_SOURCE.VARIANT:
        return item.image.uri
      case DESIGN_INPUTS_SOURCE.SKETCH:
      default:
        return null
    }
  }

  const imageSrc = getImageSrc(queueLastItem)

  const clearImage = reason => {
    // TODD: the reason arg came after the implementation of the 'clearImage' function.
    // Then, it only toasted with the UPLOAD_ERROR_MESSAGE.
    // We should make this a non-optional argument accross the project and force the consumer
    // to provide the message using the `ClearReason` enum.
    // Even better, convert this to TS and export an enum.
    reason = reason || ClearReason.InvalidFormat
    // Show error message
    toast.error(reason)
    // Clear store
    setBase64String(null)
  }

  const isUploadedImage = source === DESIGN_INPUTS_SOURCE.UPLOADED
  const isMagicEraserImage = source === DESIGN_INPUTS_SOURCE.MAGIC_ERASER

  return {
    base64String,
    clearImage,
    imageSrc,
    isUploadedImage,
    source,
    uploadedImage,
    setBase64String,
    sketchBase64,
    setSketchBase64,
    magicEraserPayload,
    isMagicEraserImage,
  }
}
