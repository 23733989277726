import create from "zustand";
import { initialMessage } from "../constants/msgEnums";
import { Message } from "@/components/chatSection/message/Message.tsx";
import { Method } from "@/api/error-handlers.ts";

export type Sender = "assistant" | "user" | "description" | "component";

export interface WebCitation {
  type: "web";
  site: string;
  url: string;
}

interface DocumentCitation {
  type: "document";
  documentName: string;
  pageNumber: number;
}

type Citation = WebCitation | DocumentCitation;

interface CitationsType {
  [key: string]: Citation;
}

export interface Message {
  text: string;
  sender: Sender;
  references?:
    | {
        citations: CitationsType;
        index: string;
        intent: string[];
      }
    | undefined;
}

export interface ResponseDataType {
  items: Message[];
}

export interface UrlDocumentType {
  index: string;
  name: string;
  page?: number;
  url?: string;
}

export type ChatPayloadType =
  | {
      messages: Message[];
      withSearch: true;
    }
  | string;

export type SessionInfoType = { sessionNum: number; revisionNum: number };

export type ArgsType = {
  headers: any;
  url: string;
  method: Method;
  params: SessionInfoType;
  data: ChatPayloadType;
};

interface ChatState {
  creativenessControllerValue: number;
  setCreativenessControllerValue: (value: number) => void;

  promptVersions: any[]; // You might want to replace `any` with the correct type
  setPromptVersions: (promptVersions: any[]) => void;

  fullMessagesLog: Message[];
  setFullMessagesLog: (fullMessagesLog: Message[]) => void;

  lastGenFullMassageLog: Message[];
  setLastGenFullMassageLog: (lastGenFullMassageLog: Message[]) => void;

  isGenerated: boolean;
  setIsGenerated: (isGenerated: boolean) => void;

  messages: Message[];
  setMessages: (messages: Message[]) => void;

  inputText: string;
  setInputText: (inputText: string) => void;

  productDescription: string | undefined;
  setProductDescription: (productDescription: string | undefined) => void;

  isLoading: boolean;
  setIsLoading: (isLoading: boolean) => void;

  isStreaming: boolean;
  setIsStreaming: (isStreaming: boolean) => void;

  isKycFetching: boolean;
  setIsKycFetching: (isKycFetching: boolean) => void;

  isSuggestionsChosen: boolean;
  setIsSuggestionsChosen: (isSuggestionsChosen: boolean) => void;

  isRouterLoading: boolean;
  setIsRouterLoading: (isRouterLoading: boolean) => void;
}

export const useChat = create<ChatState>((set) => ({
  creativenessControllerValue: 0.5,
  setCreativenessControllerValue: (value) =>
    set({ creativenessControllerValue: value }),

  promptVersions: [],
  setPromptVersions: (promptVersions) => set({ promptVersions }),

  fullMessagesLog: [],
  setFullMessagesLog: (fullMessagesLog) => set({ fullMessagesLog }),

  lastGenFullMassageLog: [],
  setLastGenFullMassageLog: (lastGenFullMassageLog) =>
    set({ lastGenFullMassageLog }),

  isGenerated: false,
  setIsGenerated: (isGenerated) => set({ isGenerated }),

  messages: [{ text: initialMessage, sender: "assistant" }],
  setMessages: (messages) => set({ messages }),

  inputText: "",
  setInputText: (inputText) => set({ inputText }),

  productDescription: "",
  setProductDescription: (productDescription) => set({ productDescription }),

  isLoading: false,
  setIsLoading: (isLoading) => set({ isLoading }),

  isStreaming: false,
  setIsStreaming: (isStreaming) => set({ isStreaming }),

  isKycFetching: false,
  setIsKycFetching: (isKycFetching) => set({ isKycFetching }),

  isSuggestionsChosen: false,
  setIsSuggestionsChosen: (isSuggestionsChosen) => set({ isSuggestionsChosen }),

  isRouterLoading: false,
  setIsRouterLoading: (isRouterLoading) => set({ isRouterLoading }),
}));
