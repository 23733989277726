
import { TargetFormat } from './useSpecExporter'

import { DownloadIcon } from '../../icons'
import   MoreIcon  from '../../../assets/icons/more_horiz.svg?react'
import styles from './ExportButton.module.css'
import { useExportButton } from './useExportButton'

export const Formats = {
  // [TargetFormat.Markdown]: {
  //   display: 'Markdown',
  //   extension: 'md',
  //   mime: 'text/plain',
  // },
  [TargetFormat.PDF]: {
    display: 'PDF',
    extension: 'pdf',
    mime: 'application/pdf',
  },
  [TargetFormat.Word]: {
    display: 'Word',
    extension: 'docx',
    mime: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  },
  [TargetFormat.Text]: {
    display: 'Text',
    extension: 'txt',
    mime: 'text/plain',
  },
}

export const ExportButton = ({ text, ThreeDotsIcon, isInSpecComp }) => {
  const { handleMouseLeave, wrapperRef, dropdownClassname, handleClose, handleClickOpen } = useExportButton(text)

  return (
    <div
      ref={wrapperRef}
      onMouseLeave={handleMouseLeave}
      onMouseUp={e => handleClickOpen(e)}
      onMouseDown={e => e.preventDefault()}
      className={isInSpecComp ? `${styles.container} ${styles.containerSpec}` : styles.container}
    >
      {ThreeDotsIcon ? <MoreIcon /> : <DownloadIcon fill="var(--leo-white)" />}

      <div className={dropdownClassname}>
        {Object.entries(Formats).map(([format, { display }]) => (
          <div key={format} className={styles.dropdownItem} onClick={() => handleClose(format)}>
            {/* This is a hack! There are bad stuff going on with the CSS, we can't style buttons here! TODO */}
            <DownloadIcon fill="var(--leo-white)" /> {display}
          </div>
        ))}
      </div>
    </div>
  )
}
