
import { Container, Divider } from '@mui/material'
import { AccountDetails } from './AccountDetails'
import { BillingAndPayments } from './BillingAndPayments'
import { HoursBalance } from './HoursBalance'
import { Settings } from './Settings'
import { Plan } from './Plan'
import styles from './Profile.module.scss'

export const Profile = () => (
  <Container className={styles.Profile}>
    <h1>Account</h1>
    <Container className={styles.container}>
      <AccountDetails />
      <Divider />
      <Plan />
      <Divider />
      <HoursBalance />
      <Divider />
      <BillingAndPayments />
      <Divider />
      <Settings />
    </Container>
  </Container>
)
