import { useEffect, useState } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { Tooltip } from "@mui/material";
import Copy from "@/assets/icons/copy.svg?react";
import BoldCopy from "@/assets/icons/bold-copy.svg?react";
import * as analytics from "@/lib/analytics.ts";
import { Item } from "@/components/simpleToolbar/SimpleToolbarStyle.ts";
import { TooltipContent } from "@/components/chatSection/message/MessageStyle.ts";

type Props = {
  plainText: string;
};
export const CopyToClipboardButton = (props: Props) => {
  const [copied, setCopied] = useState(false);

  const { plainText } = props;

  useEffect(() => {
    if (copied) {
      setTimeout(() => setCopied(false), 2000);
    }
  }, [copied]);

  const handleCopy = () => {
    setCopied(true);

    analytics.track("Copy Text Message", {
      plainText,
    });
  };

  if (!plainText) {
    return null;
  }

  return (
    <CopyToClipboard
      text={plainText}
      options={{ format: "text/plain" }}
      onCopy={handleCopy}
    >
      <Item>
        <Tooltip
          title={<TooltipContent>Copy</TooltipContent>}
          placement="top"
          disableInteractive
        >
          <div>{copied ? <BoldCopy /> : <Copy />}</div>
        </Tooltip>
      </Item>
    </CopyToClipboard>
  );
};
