import React, { useEffect, useState } from 'react'
import clsx from 'clsx'
import { Slider, Tooltip } from '@mui/material'
import { CONTROLLER_SETTINGS, useController } from '../../../../hooks/useController'
import { useDesignInputs } from '../../../../hooks/useDesignInputs'
import { FIRST_SEEN_ELEMENT, useGlowing } from '../../../../hooks/useGlowing'
import styles from './Controller.module.scss'

const CustomSlider = () => {
  const [showValueLabel, setShowValueLabel] = useState(false)
  const { setValue, settings, value } = useController()
  const { onElementClick, isControllerGlow } = useGlowing()

  useEffect(() => {
    window.addEventListener('mouseup', handleMouseUp)
    return () => {
      window.removeEventListener('mouseup', handleMouseUp)
    }
  }, [])

  const handleMouseDown = () => setShowValueLabel(true)
  const handleMouseUp = () => setShowValueLabel(false)

  function ValueLabelComponent(props) {
    const { children, value } = props

    return (
      <Tooltip
        id="valueLabeComponent"
        enterTouchDelay={0}
        placement="top"
        title={value}
        disableHoverListener={true}
        {...{
          open: showValueLabel,
          slotProps: {
            transition: {
              timeout: 0,
            },
          },
        }}
      >
        {children}
      </Tooltip>
    )
  }

  const changeHandler = e => {
    onElementClick(FIRST_SEEN_ELEMENT.CONTROLLER)

    setValue(e.target.value)
  }

  return (
    <Slider
      classes={{ rail: isControllerGlow && 'glowing' }}
      value={value}
      aria-label="creativeness controller"
      min={settings.min}
      max={settings.max}
      marks={settings?.marks || []}
      step={settings.step}
      onChange={changeHandler}
      slots={{ valueLabel: ValueLabelComponent }}
      valueLabelDisplay="auto"
      scale={value => value * 100}
      valueLabelFormat={value => `${value.toFixed()}%`}
      sx={{
        borderWidth: 3,
        color: 'var(--purple-25)',
        '& .MuiSlider-thumb': {
          color: '#fff',
          height: 13,
          width: 13,
          boxShadow: '0px 0px 0px 7px rgba(116,147,255,0.4)',
          // border: 'solid 7px rgba(116,147,255, 0.38)'
        },
      }}
      onMouseDown={handleMouseDown}
    />
  )
}

export const Controller = () => {
  const { setValue, settings } = useController()
  const { isUploadedImage, source } = useDesignInputs()

  useEffect(() => {
    setValue(CONTROLLER_SETTINGS[source].defaultValue)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className={styles.Controller}>
      <div className={clsx(styles.textContainer, isUploadedImage && 'reverse')}>
        <Tooltip title={settings.tooltips.left} placement="top">
          <div className={clsx(styles.text, styles.dark)}>{settings.labels.left}</div>
        </Tooltip>
        <Tooltip title={settings.tooltips.right} placement="top">
          <div className={clsx(styles.text, styles.dark)}>{settings.labels.right}</div>
        </Tooltip>
      </div>
      <CustomSlider />
    </div>
  )
}
