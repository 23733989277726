import React, { useState } from 'react'
import loadingGif from '../../assets/icons/Logo_animation_Bright.gif'
import loadingGifStart from '../../assets/icons/logo-animation-bright-start.png'
import { FIRST_SEEN_ELEMENT, useGlowing } from '../../hooks/useGlowing'
import { useThemeContext } from '../../hooks/useThemeContext.jsx'
import * as analytics from '../../lib/analytics'
import { useGalleryStore } from '../../store/useGalleryStore'
import { useTechSpecStore } from '../../store/useTechSpecStore'
import { LeoProModal } from '../LeoProModal'
import { Button } from '../common/Button'
import { Feedback } from '../common/Feedback'
import styles from './Footer.module.scss'
import clsx from 'clsx'
import { useLeoProModal } from '../../store/useLeoProModal'
import { useSessionStore } from '../../store/useSessionStore'
import { FeedbackModal } from './components/FeedbackModal'
import { useAccountInfoStore } from '../../store/useAccountInfoStore'
// import { getDistinctId } from '../../lib/analytics'
import { ModalWithTextBox } from '../common/ModalWithTextBox'
import { useUserTalkToUs } from '../../hooks/useUserTalkToUs'
import { toast } from 'react-toastify'
import { useIdeationChat } from '@/src-ideation/store/useChat.js'
import {SlackChat} from "@/src-ideation/components/Footer/components/SlackChat";

const EMPTY_PROMPTS_MESSAGE = `Tell Leo what you'd like to design in the chat window first.`

export const Footer = ({ onGenerate }) => {
  const { setIsGeneratedAlready } = useSessionStore(store => store)
  const { isDark } = useThemeContext()
  const { loadingImages, variantImage } = useGalleryStore(state => state)
  const loading = useTechSpecStore(state => state.loading)
  const { promptVersions, productDescription } = useIdeationChat(store => store)
  const { credits } = useAccountInfoStore(store => store)
  const { isGenerateButtonGlow, onElementClick } = useGlowing()
  const { showLeoProModal, setShowLeoProModal } = useLeoProModal()
  const [showGenerateModal, setShowGenerateModal] = useState(false)
  const [showFeedbackModal, setShowFeedbackModal] = useState(false)
  const [showCreditsModal, setShowCreditsModal] = useState(false)
  const [showInterestedModal, setShowInterestedModal] = useState(false)
  const isEmptyPrompts = promptVersions.length === 0
  const isGenerateButtonDisabled = loading || loadingImages || isEmptyPrompts || (!productDescription && !variantImage)
  const isEmptyPromptsModalCanBeOpen = isEmptyPrompts
  // const distinctID = getDistinctId()
  const CREDITS_TO_DISPLAY_FEEDBACK = 3
  const CREDITS_TO_DISPLAY_OUT_OF_CREDITS = 0
  const CREDITS_TO_DISPLAY_TALK_TO_US = 8
  const { postUserTalkToUs } = useUserTalkToUs()

  const onClick = () => setShowGenerateModal(true)

  const joinClickHandler = () => {
    analytics.track('Join The Waitlist Button Clicked')
  }

  const talkToUsHandler = message => {
    // window.open(`https://calendly.com/leoai/meeting-with-the-ceo?utm_source=ideation&utm_term=${distinctID}`, '_blank')
    analytics.track('Talk To Us Submitted', { message })
    postUserTalkToUs(message)
      .catch(e => {
        toast.error(e.message)
      })
      .then(() => {
        setShowCreditsModal(false)
        setShowInterestedModal(false)
      })
  }

  const onGenerateClick = () => {
    setIsGeneratedAlready(true)
    if (credits === CREDITS_TO_DISPLAY_OUT_OF_CREDITS) {
      setShowCreditsModal(true)
      analytics.track('Out Of Credits Displayed')
    } else {
      if (credits === CREDITS_TO_DISPLAY_FEEDBACK) {
        setShowFeedbackModal(!showFeedbackModal)
        analytics.track('Feedback Form urged')
      }

      if (credits === CREDITS_TO_DISPLAY_TALK_TO_US) {
        setShowInterestedModal(!showInterestedModal)
        analytics.track('Talk To Us Urged')
      }
      onElementClick(FIRST_SEEN_ELEMENT.GENERATE)
      onGenerate()
    }
  }

  return (
    <>
      <div className={styles.Footer}>
        <div className={styles.buttonsContainer}>
          <div
            className={clsx(styles.buttonWrapper, isGenerateButtonGlow && 'glowing')}
            style={{ cursor: 'pointer' }}
            {...(isEmptyPromptsModalCanBeOpen && { onClick })}
          >
            <Button
              onClick={onGenerateClick}
              variant="contained"
              style={{
                background: isGenerateButtonDisabled && 'var(--leo-white-50)',
                cursor: isGenerateButtonDisabled && 'default',
              }}
              disabled={isGenerateButtonDisabled}
            >
              <span className={styles.buttonContent}>
                <span className={styles.iconContainer}>
                  {loading || loadingImages ? (
                    <img
                      src={loadingGif}
                      alt="Loading..."
                      className={styles.loadingGif}
                      style={{ cursor: 'default' }}
                    />
                  ) : (
                    <img src={loadingGifStart} alt="ready" className={styles.loadingGif} />
                  )}
                </span>
                <span className={styles.text}>Generate</span>
              </span>
            </Button>
          </div>
        </div>

        <div className={styles.footerLeft}>
          <Feedback
            {...{ isDark }}
            setShowFeedbackModal={() => {
              analytics.track('Feedback Form Opened')
              setShowFeedbackModal(!showFeedbackModal)
            }}
          />
            <SlackChat/>
        </div>
      </div>

      <LeoProModal
        hideCloseButton
        type={'feedback'}
        setModalIsOpen={setShowFeedbackModal}
        modalIsOpen={showFeedbackModal}
      >
        <FeedbackModal setModalIsOpen={setShowFeedbackModal} />
      </LeoProModal>

      <LeoProModal
        type={'empty-text'}
        modalIsOpen={showGenerateModal}
        setModalIsOpen={setShowGenerateModal}
        title={EMPTY_PROMPTS_MESSAGE}
      />

      <LeoProModal
        type={'join-waitlist'}
        modalIsOpen={showLeoProModal}
        setModalIsOpen={setShowLeoProModal}
        title="Join the waitlist to get early access to Leo CAD module"
      >
        <a
          href="https://www.getleo.ai/cad-waitlist"
          target="_blank"
          rel="noreferrer"
          style={{
            textAlign: 'center',
            marginTop: '6vh',
            borderRadius: '8px',
            overflow: 'hidden',
            width: '26vh',
          }}
        >
          <Button variant="contained" color="primary" size="large" onClick={joinClickHandler}>
            <span
              style={{
                padding: '1vh 0.4vh',
                fontSize: '2.2vh',
                fontWeight: '700',
                display: 'block',
              }}
            >
              Join Waitlist
            </span>
          </Button>
        </a>
      </LeoProModal>

      <ModalWithTextBox
        title={'You are out of credits! Tell us about your use case and we will be in touch.'}
        showModal={showCreditsModal}
        setShowModal={setShowCreditsModal}
        buttonEvent={talkToUsHandler}
        analyticsMessage={'Modal Closed - Out of Credits'}
      />
      <ModalWithTextBox
        title={'Interested in Leo for your business? Tell us about your use case and we will be in touch.'}
        showModal={showInterestedModal}
        setShowModal={setShowInterestedModal}
        buttonEvent={talkToUsHandler}
        analyticsMessage={'Modal Closed - Interested in Leo for your business'}
      />
    </>
  )
}
