import CloseIcon from '../../../../assets/icons/close.svg?react'
import { useDesignInputs } from '../../../../hooks/useDesignInputs'
import { useQueue } from '../../../../hooks/useQueue'
import { Button } from '../../../common/Button'
import { UploadedImage } from './UploadedImage'
import styles from './VariantImage.module.scss'
import { useGalleryStore } from '../../../../store/useGalleryStore'

export const VariantImage = () => {
  const { imageSrc, isUploadedImage } = useDesignInputs()
  const { setVariantImage } = useGalleryStore(state => state)
  const { removeLastItem } = useQueue()

  // const creativenessControllerValue = useChat(store => store.creativenessControllerValue)
  // const blur = creativenessControllerValue + 0.5

  const removeVariantImageUrl = () => {
    removeLastItem()
    setVariantImage(null)
  }

  return (
    <div className={styles.VariantImage}>
      <div className={styles.closeButtonBlock}>
        <Button onClick={removeVariantImageUrl}>
          <CloseIcon />
        </Button>
      </div>
      <div className="canvas-preview">
        {isUploadedImage ? (
          <UploadedImage />
        ) : (
          <img
            // style={{ filter: `blur(${blur}px)` }}
            style={{ cursor: 'default' }}
            src={imageSrc}
            alt=""
          />
        )}
      </div>
    </div>
  )
}
