import React, { useEffect, useState } from 'react'
import clsx from 'clsx'
// import {  EditIcon } from '../../../assets/icons/edit.svg?react'
import { useThemeContext } from '../../../hooks/useThemeContext.jsx'
import { useTechSpecStore } from '../../../store/useTechSpecStore'
import TextareaAutosize from '../../TextareaAutosize'
import { SPINNER_COLOR, Spinner } from '../../Spinner'
import { CopyAndExportControls } from '../../CopyAndExportControls'
import { Article } from './Article'
// import { Pagination } from './Pagination'
import { LikeButton } from '../../LikeAndDislikeControls/LikeButton'
import styles from '../RightSection.module.scss'
import { DislikeButton } from '../../LikeAndDislikeControls/DislikeButton'
import { useLikeDislike } from '../../../hooks/useLikeDislike'
import * as analytics from '../../../lib/analytics'
import { Button } from '../../common/Button'
import { LeoProModal } from '../../LeoProModal'
import { ProjectDocsContainer } from '../ProjectDocsContainer'
import './EditableTextWindow.scss'
import { FEATURE_VIEW_PROJECT_DOCS } from '../../../lib/config'

export const SpecText = ({ _editDisable, title, isSpecWindowExpanded }) => {
  //TODO SpecText is deprecated and should be deleted at some point
  const { isDark } = useThemeContext()
  const { liked, disliked, clickLikeDislikeHandler } = useLikeDislike()

  const { loading, specVersions, setSpecVersions, text, setText, currentVersionIndex, setCurrentVersionIndex } =
    useTechSpecStore(store => store)

  const [isEditing, setIsEditing] = useState(false)
  const [displaySpecModal, setDisplaySpecModal] = useState(false)

  // The loading animation should appear only in the last version of the spec window
  const isSpinnerVisible =
    (loading && specVersions.length === 1) || (loading && currentVersionIndex === specVersions.length - 1)

  const onUpdate = (index, newText) => {
    const updatedVersions = [...specVersions]
    updatedVersions[index] = newText
    setSpecVersions(updatedVersions)
  }

  useEffect(() => {
    if (specVersions?.length > 0) {
      setCurrentVersionIndex(specVersions.length - 1) // Set the current version to the last index
    }
  }, [specVersions, loading, setCurrentVersionIndex])

  useEffect(() => {
    if (specVersions?.length > 0) {
      setText(specVersions[currentVersionIndex])
    }
  }, [specVersions, currentVersionIndex, setText])

  // const handleEdit = () => setIsEditing(true)

  const handleSave = () => {
    setIsEditing(false)
    onUpdate(currentVersionIndex, text)
  }

  useEffect(() => {
    if (liked || disliked) {
      analytics.track('Spec Sentiment Changed', { text, liked, disliked })
    }
  }, [text, liked, disliked])

  const handleDislikeClick = e => {
    clickLikeDislikeHandler(e, 'dislike')
  }

  const handleLikeClick = e => {
    clickLikeDislikeHandler(e, 'like')
  }

  const handleOpenSpecModal = () => {
    setDisplaySpecModal(true)
  }

  const isButtonsVisible = !isSpinnerVisible && !!text

  return (
    <>
      <div className={clsx('editable-text-window', 'dark')}>
        {isEditing ? (
          <div className="edit-container">
            <div className="textwindow-buttons-bar">
              <h1 className="text-window-headline">{title}</h1>
              <button onClick={handleSave} className="button-with-icon">
                Save
              </button>
            </div>
            <div className="scrollable-text-window">
              <TextareaAutosize className="edit-text-input" value={text} onChange={e => setText(e.target.value)} />
            </div>
          </div>
        ) : (
          <div className="view-container">
            <div className="textwindow-buttons-bar">
              {title ? <h1 className="text-window-headline">{title}</h1> : ''}
              {/* {editDisable ? (
                ''
              ) : (
                <button onClick={handleEdit} className={'svg-only-button'}>
                  <EditIcon />
                </button>
              )} */}
              <div className="controls">
                {isButtonsVisible && (
                  <>
                    <LikeButton liked={liked} clickHandler={handleLikeClick} />
                    <DislikeButton disliked={disliked} clickHandler={handleDislikeClick} />
                    <CopyAndExportControls isInSpecComp={true} {...{ text }} />
                  </>
                )}
                {/*{specVersions.length > 1 && <Pagination />}*/}
              </div>
            </div>

            {isSpinnerVisible ? (
              <div className={clsx(styles.specLoadingContainer, isSpecWindowExpanded && styles.expanded)}>
                <Spinner {...{ size: '20vh', color: isDark ? SPINNER_COLOR.BRIGHT : SPINNER_COLOR.DARK }} />
              </div>
            ) : (
              <Article text={text} isSpecWindowExpanded={isSpecWindowExpanded} extraPadding={false} />
            )}
          </div>
        )}
      </div>
      {isButtonsVisible && FEATURE_VIEW_PROJECT_DOCS && (
        <Button onClick={handleOpenSpecModal} className={'project-docs-button'}>
          View Project Docs
        </Button>
      )}

      <LeoProModal
        type={'project-docs-modal'}
        modalIsOpen={displaySpecModal}
        setModalIsOpen={setDisplaySpecModal}
        hideCloseButton
        style={{ textAlign: 'center', marginTop: '60vh', borderRadius: '8px', overflow: 'hidden' }}
      >
        <ProjectDocsContainer />
      </LeoProModal>
    </>
  )
}
