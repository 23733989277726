import React, { useState } from 'react'
import ReactMarkdown from 'react-markdown'
import clsx from 'clsx'
import   EditIcon  from '../../../../assets/icons/edit.svg?react'
import { editedMessage, userEditPrefix } from '../../../../constants/msgEnums'
import { Button } from '../../../common/Button'
import TextareaAutosize from '../../../TextareaAutosize'
import { useThemeContext } from '../../../../hooks/useThemeContext.jsx'
import './Message.scss'
import * as analytics from '../../../../lib/analytics'

export const Message = ({ message, addMessages, addFullMessages, setImagePrompt }) => {
  const { isDark } = useThemeContext()
  const [isEditing, setIsEditing] = useState(false)
  const [messageText, setMessageText] = useState(message.text)
  const [isEdited, setIsEdited] = useState(false)

  const handleEdit = () => {
    setIsEditing(true)
    analytics.track('Product Description Edit Clicked')
  }

  const handleSave = () => {
    analytics.track('Product Description Edit Saved')
    setIsEditing(false)
    if (isEdited) {
      setImagePrompt(messageText)
      addMessages([{ text: editedMessage, sender: 'assistant' }])
      addFullMessages([
        { text: `${userEditPrefix}: ${messageText}`, sender: 'user' },
        { text: editedMessage, sender: 'assistant' },
      ])
      setIsEdited(false)
    }
  }

  const handleChange = event => {
    setMessageText(event.target.value)
    setIsEdited(true)
  }

  /** This function handle saving the edited message by pressing enter */
  const handleKeyDown = event => {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault() // Prevent the default Enter key behavior
      handleSave()
    }
  }

  const classes = {
    user: 'user-message',
    assistant: 'assistant-message',
    description: 'image-description-message',
    // Add as many as you need
  }

  return (
    <div className={clsx('Message', classes[message.sender])}>
      {isEditing ? (
        <div className="edit-container">
          {message.sender === 'description' ? (
            <div className="image-description-banner">
              <h2 style={{ marginBlock: '0' }}>Product Description</h2>
              <Button variant="contained" onClick={handleSave} className="save-button">
                Save
              </Button>
            </div>
          ) : (
            ''
          )}
          <TextareaAutosize
            value={messageText}
            onChange={handleChange}
            onKeyDown={handleKeyDown}
            className={clsx('message-edit-input', isDark && 'dark')}
          />
        </div>
      ) : (
        <div className="message-container">
          {message.sender === 'description' ? (
            <div className="image-description-banner">
              <h2 style={{ marginBlock: '0' }}>Product Description</h2>
              <button onClick={handleEdit} className={clsx('edit-button', isDark && 'dark')}>
                <EditIcon />
              </button>
            </div>
          ) : (
            ''
          )}
          <ReactMarkdown style={{ marginBlock: '0' }}>{messageText}</ReactMarkdown>
        </div>
      )}
    </div>
  )
}
