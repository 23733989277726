import { useQuery } from '@tanstack/react-query'
import createInstance from '../../api/createInstance'
import { IMAGE_LIST_ENDPOINT } from '../../lib/config'
import * as observability from '../../lib/observability'
import * as analytics from '../../lib/analytics'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import leoWatermark from '../../assets/icons/logo-animation-bright-start.png'
export default function useSessionThumbnail(sessionNum: number) {
  return useQuery({
    queryKey: ['session', 'thumbnail', sessionNum],
    queryFn: async () => {
      const api = createInstance()

      try {
        const response = await api({
          url: IMAGE_LIST_ENDPOINT,
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
          params: {
            sessionNum,
            revisionNum: 1,
          },
        })

        const firstImage = response.data.images[response.data.images.length - 1]
        return firstImage && firstImage.upscale[0].uri
      } catch (error: any) {
        observability.captureException(`Session Thumbnail error GET ${IMAGE_LIST_ENDPOINT} sessionNum ${sessionNum}`, {
          error,
          tags: { url: IMAGE_LIST_ENDPOINT, method: 'GET' },
        })

        analytics.track('Unable to get Session Thumbnail', { error })

        return leoWatermark
      }
    },
  })
}
