import Close from '@/assets/icons/close.svg?react'
import {StyledPopUp, StyledPopUpClose, StyledPopUpWrapper} from "@/components/infoPoPUp/InfoPoPUpStyle.ts";


interface InfoPoPUpProps {
    onHandleClose: () => void;
}
export const InfoPoPUp = ({onHandleClose}:InfoPoPUpProps) => {
    return <StyledPopUpWrapper>
        <StyledPopUpClose onClick={onHandleClose}><Close/></StyledPopUpClose>
        <StyledPopUp>
            Check out Leo's new capabilities! Now you can ask leo questions in the chat window, and keep thinking on new ideas in the Ideation window.
        </StyledPopUp>
    </StyledPopUpWrapper>
}
