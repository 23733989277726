
import clsx from 'clsx'
import { useTechSpecStore } from '../../store/useTechSpecStore'
import { WindowHeader } from '../common/WindowHeader'
import { SpecText } from './EditableTextWindow'
import { Gallery } from './Gallery'
import styles from './RightSection.module.scss'
import { Pagination } from './EditableTextWindow/Pagination'
import { ProductSummary } from './ProductSummary'
import { FEATURE_PRODUCT_SUMMARY } from '../../lib/config'

const DesignSection = {
  Title: 'Design Concepts',
}

const SummarySection = {
  Title: 'Product Summary',
}

export const RightSection = ({ onGenerate }) => {
  const {
    isSpecWindowExpanded,
    setIsSpecWindowExpanded,
    isDesignWindowExpanded,
    setIsDesignWindowExpanded,
    specVersions,
    revisionsAmount,
  } = useTechSpecStore(store => store)

  // handling the minimize and maximize of the buttons based on logics
  const handleMaximizeAndMinimize = name => {
    switch (name) {
      case DesignSection.Title:
        setIsDesignWindowExpanded(!isDesignWindowExpanded)
        setIsSpecWindowExpanded(false)
        break
      case SummarySection.Title:
        setIsDesignWindowExpanded(false)
        setIsSpecWindowExpanded(!isSpecWindowExpanded)
        break
      default:
        break
    }
  }

  const displayPagination = FEATURE_PRODUCT_SUMMARY ? revisionsAmount > 1 : specVersions.length > 1

  return (
    <div className={styles.RightSection}>
      <WindowHeader title={DesignSection.Title} onMinMaxClick={handleMaximizeAndMinimize} withControls />

      <div className={clsx(styles.outputsSection, styles.dark)}>
        <div className={clsx(styles.galleryComponent, isDesignWindowExpanded && styles.designExpanded, styles.dark)}>
          <Gallery {...{ onGenerate }} />
        </div>
        <div className={clsx(styles.techSpecContainer, isSpecWindowExpanded && styles.expanded)}>
          <WindowHeader
            title={SummarySection.Title}
            onMinMaxClick={handleMaximizeAndMinimize}
            style={{
              borderBottomLeftRadius: isDesignWindowExpanded ? '0.6vh' : '',
              borderBottomRightRadius: isDesignWindowExpanded ? '0.6vh' : '',
            }}
            withControls
            displayChildrenWithTitle
          >
            {displayPagination && <Pagination />}
          </WindowHeader>
          <div
            className={clsx(
              styles.captionSpecComponent,
              isSpecWindowExpanded && styles.expanded,
              isDesignWindowExpanded && styles.designExpanded,
              styles.dark
            )}
          >
            <div className={styles.specContainer} data-testid="spec-container">
              {FEATURE_PRODUCT_SUMMARY ? (
                <ProductSummary />
              ) : (
                <SpecText isSpecWindowExpanded={isSpecWindowExpanded} editDisable={true} />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
