
import Maximize from '../../../assets/icons/maximize.svg?react'
import Minimize from '../../../assets/icons/minimize.svg?react'
import styles from './WindowHeader.module.scss'

export const WindowHeader = ({
  onMinMaxClick,
  style = {},
  title = '',
  withControls = false,
  children,
  displayChildrenWithTitle,
}) => {
  return (
    <div className={styles.WindowHeader} {...{ style }}>
      <h2>
        {title}
        {displayChildrenWithTitle && <div className={styles.buttonsBlock}>{children}</div>}
      </h2>

      {!displayChildrenWithTitle && <div className={styles.buttonsBlock}>{children}</div>}

      {withControls && (
        <div className={styles.buttonsBlock}>
          <button className={styles.minMaxButton} onClick={() => onMinMaxClick(title)}>
            <Minimize className={styles.svgBtn} style={{ marginRight: '8px' }} width="2.2vh" height="0.6vh" />
            <Maximize className={styles.svgBtn} width="2.2vh" height="2.2vh" />
          </button>
        </div>
      )}
    </div>
  )
}
