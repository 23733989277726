import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { ROUTE } from '@/router/Router.tsx'
import * as analytics from '@/lib/analytics.ts'
import { useUser } from '@descope/react-sdk'
import { FormControl, InputLabel, TextField, Select, MenuItem } from '@mui/material'
import { INDUSTRY, OTHER_OPTION, ROLES, SOURCES } from '@/constants/menuLists.ts'
import { FormTitle, FormWrapper, NumberInput, StyledForm, SubmitButton } from '@/components/kycForm/KycStyle.ts'
import { useKyc } from '@/api/kyc.ts'
export interface PersonalDetails {
  firstName: string
  lastName: string
  companyName: string
  companySize?: number | undefined
  role: string
  otherRole?: string
  industry: string
  source: string
  otherSource?: string
  name?: string
  email?: string
}
const KycForm = () => {
  const navigate = useNavigate()
  const [selectedRole, setSelectedRole] = useState('')
  const [selectedSource, setSelectedSource] = useState('')
  const { postKycFormValues } = useKyc()
  const { user } = useUser()

  const [formData, setFormData] = useState<PersonalDetails>({
    firstName: '',
    lastName: '',
    companyName: '',
    companySize: undefined,
    role: '',
    industry: '',
    source: '',
  })

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const { name, value } = e.target
    setFormData(prevData => ({
      ...prevData,
      [name]: value,
    }))
  }

  const handleChangeList = (name: string, value: string | any): void => {
    if (name === 'role') {
      setSelectedRole(value)
    }
    if (name === 'source') {
      setSelectedSource(value)
    }

    setFormData(prevData => ({
      ...prevData,
      [name]: value,
    }))
  }

  const handleSubmit = (e: React.FormEvent): void => {
    formData.name = `${formData.firstName} ${formData.lastName}`
    formData.email = user.email

    e.preventDefault()
    postKycFormValues(formData)
    navigate(ROUTE.MAIN)

    analytics.registerKYCInfo(formData)
    analytics.track('KYC Form Submitted', { formData })
  }

  return (
    <FormWrapper>
      <StyledForm onSubmit={handleSubmit}>
        <FormTitle>Tell us more about yourself</FormTitle>
        <TextField
          type="text"
          id="firstName"
          name="firstName"
          placeholder={'First name'}
          value={formData.firstName}
          onChange={handleChange}
          required
        />

        <TextField
          type="text"
          id="lastName"
          name="lastName"
          placeholder={'Last name'}
          value={formData.lastName}
          onChange={handleChange}
          required
        />

        <TextField
          type="size"
          id="companyName"
          name="companyName"
          placeholder={'Company name'}
          value={formData.companyName}
          onChange={handleChange}
          required
        />

        <NumberInput
          type={'number'}
          onChange={handleChange}
          name={'companySize'}
          placeholder={'No. of engineers in your company'}
          required
        />
        <FormControl>
          <InputLabel>Role</InputLabel>
          <Select
            labelId="role-label"
            id="role"
            label="Role"
            onChange={e => handleChangeList('role', e.target.value)}
            required
          >
            {ROLES.map(role => (
              <MenuItem key={role} value={role}>
                {role}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        {selectedRole === OTHER_OPTION && (
          <TextField
            type="text"
            id="otherRole"
            name="otherRole"
            placeholder={'Provide your role'}
            value={formData.otherRole}
            onChange={handleChange}
            required
          />
        )}
        <FormControl>
          <InputLabel>Industry</InputLabel>
          <Select
            labelId="industry-label"
            id="industry"
            label="Industry"
            onChange={e => handleChangeList('industry', e.target.value)}
          >
            {INDUSTRY.map(industry => (
              <MenuItem key={industry} value={industry}>
                {industry}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl>
          <InputLabel>How did you hear about Leo?</InputLabel>
          <Select
            labelId="source-label"
            id="source"
            label="Source"
            onChange={e => handleChangeList('source', e.target.value)}
          >
            {SOURCES.map(source => (
              <MenuItem key={source} value={source}>
                {source}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        {selectedSource === OTHER_OPTION && (
          <TextField
            type="text"
            id="otherSource"
            name="otherSource"
            placeholder={'How did you hear about Leo?'}
            value={formData.otherSource}
            onChange={handleChange}
          />
        )}

        <SubmitButton type={'submit'}>Ready to chat with Leo</SubmitButton>
      </StyledForm>
    </FormWrapper>
  )
}

export default KycForm
