import { AxiosError, AxiosResponse } from 'axios'
import { FetchDataArgs } from './useFetchData'

import * as errors from './errors'
import * as analytics from '../lib/analytics'
import * as observability from '../lib/observability'

export function handleError<TData, TParams>(error: AxiosError, args: FetchDataArgs<TData, TParams>) {
  if (isRequestTooLargeError(error.response)) {
    handleRequestToLargeError(error, args)
  }

  if (isSchemaValidationError(error?.response)) {
    handleSchemaValidationError(error, args)
  }

  if (isPolicyViolationError(error?.response)) {
    handlePolicyViolationError(error, args)
  }

  if (isPaymentRequiredError(error?.response)) {
    handlePaymentRequiredError(error, args)
  }
  if (isNotFoundError(error?.response)) {
    handleNotFoundError(error, args)
  }

  handleUnknownError(error, args)
}

function isNotFoundError(response: AxiosResponse | undefined) {
  return response?.status === 404 && response?.data?.reason === 'Not found'
}

function handleNotFoundError<TData, TParams>(error: AxiosError, args: FetchDataArgs<TData, TParams>) {
  const { headers, method = 'get', params, url } = args
  observability.captureException(`Not found error ${method} ${url}`, {
    error,
    tags: { url, method },
    extra: { params, headers },
  })

  if (!url?.includes('project-document')) {
    analytics.track('Not found Error', { error })
  }

  throw new errors.NotFoundError(error.message)
}
function isRequestTooLargeError(response: AxiosResponse | undefined) {
  return response?.status === 400 && response?.data?.reason === 'Request is too large'
}

function handleRequestToLargeError<TData, TParams>(error: AxiosError, args: FetchDataArgs<TData, TParams>) {
  const { headers, method = 'post', params, url } = args
  observability.captureException(`Request is too large ${method} ${url}`, {
    error,
    tags: { url, method },
    extra: { params, headers },
  })

  analytics.track('Request too large error', { error })

  throw new errors.RequestTooLargeApiError(error.message)
}

function isSchemaValidationError(response: AxiosResponse | undefined) {
  return response?.status === 400 && response?.data?.isSchemaValidationError
}

function isPolicyViolationError(response: AxiosResponse | undefined) {
  return response?.status === 400 && response?.data?.reason === 'Policy Violation'
}

function handlePolicyViolationError<TData, TParams>(error: AxiosError, args: FetchDataArgs<TData, TParams>) {
  const { headers, method = 'post', data, params, url } = args
  observability.captureException(`Policy Violation Error ${method} ${url}`, {
    error,
    tags: { url, method },
    extra: { params, headers, data },
  })

  analytics.track('Policy Violation Error', { data, error })

  throw new errors.PolicyViolationError(error.message)
}

function handleSchemaValidationError<TData, TParams>(error: AxiosError, args: FetchDataArgs<TData, TParams>) {
  const { headers, method = 'post', params, data, url } = args
  observability.captureException(`Schema validation error ${method} ${url}`, {
    error,
    tags: { url, method },
    extra: { params, headers, data, responseData: error?.response?.data },
  })

  analytics.track('Schema validation error', { error })

  // The validations provide information about what validated in the schema.
  // It contains information about what property failed (instancePath) and additional info.
  // For example, we can add information about the available values themselves.
  const validations = (error.response?.data as any).validation.map((validation: any) => ({
    path: validation.instancePath,
  }))

  throw new errors.SchemaValidationError(validations, error.message)
}

function handleUnknownError<TData, TParams>(error: AxiosError, args: FetchDataArgs<TData, TParams>) {
  const { data, headers, method = 'post', params, url } = args
  observability.captureException(`Failed to fetch ${method} ${url}`, {
    error,
    tags: { url, method },
    extra: { params, headers, data },
  })

  analytics.track('Unrecoverable API error', { error })

  // TODO: this should be changed. We should not create a generic abstraction
  // over all possible API errors. This is very situational.
  // In general, we should move out from the "useFetchData" function.
  let info = (error?.response?.data as any)?.message
  info = info || error?.message
  info = info || `Error with fetching data from ${url}`
  throw new errors.UnknownApiError(info)
}

function isPaymentRequiredError(response: AxiosResponse | undefined) {
  return response?.status === 402
}

function handlePaymentRequiredError<TData, TParams>(error: AxiosError, args: FetchDataArgs<TData, TParams>) {
  const { headers, method = 'post', params, url } = args
  observability.captureException(`Payment required error ${method} ${url}`, {
    error,
    tags: { url, method },
    extra: { params, headers },
  })

  analytics.track('Payment Required Error', { error })

  throw new errors.PaymentRequiredError(error.message)
}
