import { NotFoundError, useFetchData } from '../api'
import { POLLING_INTERVAL, POLLING_TIMEOUT, PROJECT_DOCS } from '../lib/config'
import useDocsStore from '../store/useDocsStore'
import { toast } from 'react-toastify'
import * as observability from '../lib/observability'
import * as analytics from '../lib/analytics'
import YAML from 'yaml'
import { formatEndpoint } from '../utils/endpointsUtils'
import { useSessionStore } from '../store/useSessionStore'
import { useTechSpecStore } from '../store/useTechSpecStore'

async function sleep(timeout: number): Promise<void> {
  return new Promise((resolve) => {
    setTimeout(() => resolve(), timeout)
  })
}

export default function useDocs(document: string) {
  const getState = () => useDocsStore.getState()
  const { fetchData } = useFetchData()
  const {
    currentVersionIndex,
    setCurrentVersionIndex,
    setRevisionsAmount,
    revisionsAmount,
    setImagesContent,
    imagesContent,
  } = useTechSpecStore(store => store)
  const { sessionNum, revisionNum } = useSessionStore(store => store)

  // Start compiling the document and fill the document's "artifactNames" field
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async function compileDocument(document: string, inputs: any): Promise<string[]> {
    // currentRevision is the current page of the product summary
    const currentRevision = document === 'product-summary' ? revisionNum : currentVersionIndex + 1
    const data = JSON.stringify({ inputs })
    const url = formatEndpoint(PROJECT_DOCS, sessionNum, currentRevision, null).replace(':document', document)
    const response = await fetchData({
      url,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      data,
    })

    return response.artifacts
  }

  async function getDocumentArtifact(document: string, artifact: string): Promise<string | undefined> {
    try {
      // currentRevision is the current page of the product summary
      const currentRevision = document === 'product-summary' ? revisionNum : currentVersionIndex + 1
      const url = formatEndpoint(PROJECT_DOCS, sessionNum, currentRevision, null).replace(':document', document)

      const response = await fetchData({
        url: `${url}/${artifact}`,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      })

      return response.artifact.content
    } catch (error: any) {
      if (error instanceof NotFoundError) {
        return undefined
      } else return 'error'
    }
  }

  async function startCompilingDocument(inputs: any, revision: number) {
    const existingDocument = getState().docs[document]
    if (!existingDocument || !existingDocument.find(doc => doc.revision === revision)) {
      const artifactNames = await compileDocument(document, inputs)
      getState().updateDoc(document, revision, () => ({
        artifactNames,
        artifacts: [],
        isLoading: false,
        revision,
      }))
    }

    if (revisionsAmount && document === 'product-summary') {
      setRevisionsAmount(revisionsAmount + 1)
      setCurrentVersionIndex(revisionsAmount)
    }
  }

  const handleTimeoutError = (artifactName: string) => {
    observability.captureException(`Timeout error in retrieve ${artifactName}`, {
      tags: {
        cause: 'Artifact Timeout Error',
      },
    })
    analytics.track(`Timeout error in retrieve ${artifactName}`, {})
    toast.error(`Timeout error in retrieve ${artifactName}`)
  }

  // Fill the document's "artifacts" field.
  // It requires the "artifactNames" field to be filled.
  async function retrieveDocumentArtifacts(revision: number) {
    const artifactNames = getState().docs[document]?.[revision]?.artifactNames

    if (!artifactNames || artifactNames.length === 0) {
      return
    }

    getState().updateDoc(document, revision, () => ({ isLoading: true }))

    const now = () => new Date().getTime()
    let deadline = now() + POLLING_TIMEOUT
    let index = 0
    while (index < artifactNames.length) {
      const artifactName = artifactNames[index]
      const artifactContent = await getDocumentArtifact(document, artifactName)
      if (artifactContent === 'error') break

      if (now() > deadline) {
        handleTimeoutError(artifactName)
        break
      }
      if (!artifactContent) {
        await sleep(POLLING_INTERVAL)
      } else {
        const parsedData = YAML.parse(artifactContent)
        if (parsedData.title) {
          setImagesContent({
            ...imagesContent,
            [revisionNum || 1]: { title: parsedData.title, description: parsedData.description },
          })
        }

        getState().updateDoc(document, revision, doc => ({
          artifacts: { ...doc.artifacts, [artifactName]: parsedData },
        }))
        index++
        deadline = now() + POLLING_TIMEOUT
      }
    }

    getState().updateDoc(document, revision, () => ({ isLoading: false }))
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async function compileDocumentAndRetrieveArtifacts(revision: number, inputs?: any) {
    await startCompilingDocument(inputs, revision)
    await retrieveDocumentArtifacts(revision)
  }

  return {
    compileDocumentAndRetrieveArtifacts,
  }
}
