import loadingGif from '../../../../../assets/icons/Logo_animation_Bright.gif'
import loadingGifStart from '../../../../../assets/icons/logo-animation-bright-start.png'
import { Button as CommonButton } from '../../../../common/Button'
import styles from './Button.module.scss'
import { useIdeationChat } from '@/src-ideation/store/useChat.js'

export const Button = ({ onClick }) => {
  const isLoading = useIdeationChat(store => store.isLoading)

  return (
    <CommonButton className={styles.Button} onClick={onClick} disabled={isLoading} variant="contained">
      {isLoading ? (
        <img src={loadingGif} alt="Loading..." className={styles.loading} />
      ) : (
        <img src={loadingGifStart} alt="ready" className={styles.loading} />
      )}
    </CommonButton>
  )
}
