import React from 'react'
import useDocsStore from '../../../store/useDocsStore'
import { Tooltip } from '@mui/material'
import { Wrapper } from './CreateNewSessionStyle'
import { useSessionStore } from '../../../store/useSessionStore'
import { useGalleryStore } from '../../../store/useGalleryStore'
import { useGalleryModal } from '../../../store/useGalleryModal'
import { useTechSpecStore } from '../../../store/useTechSpecStore'
import { initialMessage } from '../../../constants/msgEnums'
import AddSession from '../../../assets/icons/add_comment.svg?react'
import { useIdeationChat } from '@/src-ideation/store/useChat.js'

const RefForwardedAddSessionIcon = React.forwardRef<HTMLDivElement, any>((props, ref) => {
  return (
    <div ref={ref}>
      <AddSession {...props} />
    </div>
  )
})

interface CreateNewSessionProps {
    startGenerate: boolean

}
export default function CreateNewSession({startGenerate}: CreateNewSessionProps) {
  const { setSessionNum, setRevisionNum, setIsGeneratedAlready } = useSessionStore(store => store)
  const { setMessages, setFullMessagesLog, setPromptVersions, setProductDescription } = useIdeationChat(store => store)
  const { setImages, storeResponse, setLoadingImages } = useGalleryStore(store => store)
  const { setImagesData } = useGalleryModal()
  const { setRevisionsAmount, setCurrentVersionIndex, setImagesContent } = useTechSpecStore(store => store)

  const docsStore = () => useDocsStore.getState()

  const newSessionClick = () => {
    setSessionNum(undefined)
    setRevisionNum(undefined)
    setIsGeneratedAlready(false)

    setMessages([{ text: initialMessage, sender: 'assistant' }])
    setFullMessagesLog([])
    setPromptVersions([])
    setProductDescription('')

    setImages([])

    setImagesData([])
    storeResponse({ 'upscale': [] })
    setLoadingImages(false)

    setRevisionsAmount(0)
    setCurrentVersionIndex(0)
    setImagesContent([])
    docsStore().resetDoc()

    console.log('CreateNewSession',startGenerate)
    if (startGenerate) window.location.href = '/ideation'
  }

  return (
    <Wrapper onClick={newSessionClick}>
      <Tooltip children={<RefForwardedAddSessionIcon />} title="Create a new session" placement="top" />
    </Wrapper>
  )
}
