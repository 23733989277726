import { useAppState } from '../store/useAppState'

export const FIRST_SEEN_ELEMENT = {
  DESIGN_INPUTS: 'Design Inputs',
  CONTROLLER: 'Controller',
  GENERATE: 'Generate',
}

export const useGlowing = () => {
  const { isFirstSeenApp, firstSeenElements, setFirstSeenElements } = useAppState(store => store)

  if (!isFirstSeenApp) {
    return {
      activateGlow: () => {},
      isDesignInputsGlow: false,
      isControllerGlow: false,
      isGenerateButtonGlow: false,
      onElementClick: () => {},
    }
  }

  const onElementClick = elementKey => {
    if (firstSeenElements[elementKey] === false) return

    switch (elementKey) {
      case FIRST_SEEN_ELEMENT.DESIGN_INPUTS:
        return setFirstSeenElements({
          ...firstSeenElements,
          [FIRST_SEEN_ELEMENT.DESIGN_INPUTS]: false,
          [FIRST_SEEN_ELEMENT.CONTROLLER]: true,
        })
      default:
        return setFirstSeenElements({ ...firstSeenElements, [elementKey]: false })
    }
  }

  const activateGlow = data => setFirstSeenElements(data)

  const isDesignInputsGlow = firstSeenElements[FIRST_SEEN_ELEMENT.DESIGN_INPUTS]
  const isControllerGlow = firstSeenElements[FIRST_SEEN_ELEMENT.CONTROLLER]
  const isGenerateButtonGlow = firstSeenElements[FIRST_SEEN_ELEMENT.GENERATE]

  return {
    activateGlow,
    isDesignInputsGlow,
    isControllerGlow,
    isGenerateButtonGlow,
    onElementClick,
  }
}
