import { Tooltip } from "@mui/material";
import { useSessionStore } from "../../../store/useSessionStore";
import { useChat } from "../../../store/useChat";
import { initialMessage } from "../../../constants/msgEnums";
import AddSession from "../../../assets/icons/add_comment.svg?react";
import { TooltipContent } from "@/components/chatSection/message/MessageStyle.ts";
import * as analytics from "@/lib/analytics.ts";
import styled from "styled-components";

interface Props {
  buttonWithText?: boolean;
}

interface NewSessionButtonProps {
  isLoading?: boolean;
}

export const NewSessionButton = styled.div<NewSessionButtonProps>`
  cursor: ${(props) => !props.isLoading && "pointer"};
  opacity: ${(props) => (props.isLoading ? 0.5 : 1)};
`;

export const NewChatButton = styled.div`
  background-color: var(--purple-5);
  border-radius: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  color: #ffffff;
  height: 3rem;
  width: 10rem;
  margin-bottom: 3rem;
  cursor: default;
`;

export default function CreateNewSession(props: Props) {
  const { setSessionNum, setRevisionNum, setIsGeneratedAlready } =
    useSessionStore((store) => store);
  const {
    setMessages,
    setFullMessagesLog,
    setPromptVersions,
    setProductDescription,
    isLoading,
  } = useChat((store) => store);

  const newSessionClick = () => {
    if (isLoading) return;
    analytics.track("Create New Session", {});

    setSessionNum(undefined);
    setRevisionNum(undefined);
    setIsGeneratedAlready(false);

    setMessages([{ text: initialMessage, sender: "assistant" }]);
    setFullMessagesLog([]);
    setPromptVersions([]);
    setProductDescription("");
  };

  return (
    <NewSessionButton isLoading={isLoading} onClick={newSessionClick}>
      <Tooltip
        title={<TooltipContent>Create a new session</TooltipContent>}
        placement="top"
      >
        {props.buttonWithText ? (
          <NewChatButton>
            <AddSession />
            <span>New Chat</span>
          </NewChatButton>
        ) : (
          <span>
            <AddSession />
          </span>
        )}
      </Tooltip>
    </NewSessionButton>
  );
}
