
import clsx from 'clsx'
import   Upload  from '../../../../../assets/icons/upload.svg?react'
import { DESIGN_INPUTS_SOURCE, MAX_UPLOAD_IMAGE_SIZE_IN_BYTES } from '../../../../../constants/index.ts'
import { useDesignInputs, ClearReason } from '../../../../../hooks/useDesignInputs'
import { FIRST_SEEN_ELEMENT, useGlowing } from '../../../../../hooks/useGlowing'
import { useQueue } from '../../../../../hooks/useQueue'
import { useDesignInputsStore } from '../../../../../store/useDesignInputsStore.ts'
import { isValidImageMimeType } from '../../../../../utils'
import '../Canvas.scss'

export const UploadImage = () => {
  const { setUploadedImage, setBase64String } = useDesignInputsStore(store => store)
  const { clearImage } = useDesignInputs()
  const { addToQueue } = useQueue()
  const { isDesignInputsGlow, onElementClick } = useGlowing()

  // Upload handler for Upload Icon
  const handleFileChange = event => {
    onElementClick(FIRST_SEEN_ELEMENT.DESIGN_INPUTS)

    const selectedImage = event.target.files[0]

    if (!isValidImageMimeType(selectedImage)) {
      return clearImage(ClearReason.InvalidFormat)
    }

    if (selectedImage.size >= MAX_UPLOAD_IMAGE_SIZE_IN_BYTES) {
      return clearImage(ClearReason.FileTooBig)
    }

    setUploadedImage(selectedImage)

    const reader = new FileReader()

    reader.onload = () => {
      const base64String = reader.result.split(',')[1] // Get the Base64 string without the data URI prefix
      setBase64String(base64String)
    }

    reader.readAsDataURL(selectedImage)

    addToQueue({ type: DESIGN_INPUTS_SOURCE.UPLOADED, image: selectedImage })
  }

  return (
    <>
      <label htmlFor="fileInput">
        <Upload className={clsx('upload-icon dark', isDesignInputsGlow && 'glowing')} />
      </label>
      <span className="text dark">Upload</span>
      <input
        className="UploadImage_fileInput"
        type="file"
        id="fileInput"
        name="image"
        accept=".jpg, .jpeg, .png"
        onChange={handleFileChange}
      />
    </>
  )
}
