import { useMemo, useRef, useState } from 'react'
import { useSpecExporter } from './useSpecExporter'
import { useOutsideClick } from '../../../hooks/useOutsideClick'
import { v4 as uuid } from 'uuid'
import * as analytics from '../../../lib/analytics'
import { saveAs } from 'file-saver'
import clsx from 'clsx'
import styles from './ExportButton.module.css'
import { Formats } from './index'

export const useExportButton = text => {
  const wrapperRef = useRef(null)
  const [open, setOpen] = useState(false)
  const exportSpec = useSpecExporter()

  const handleClickOpen = () => {
    setOpen(!open)
  }

  useOutsideClick(wrapperRef, () => setOpen(false))

  const handleClose = format => {
    setOpen(false)

    const analyticInfo = {
      document: 'Spec',
      item: Formats[format].display,
      transaction: uuid(),
      format,
    }

    analytics.track('Document Export Started', analyticInfo)

    exportFile(format)
      .then(() => analytics.track('Document Export Finished', analyticInfo))
      .catch(error => analytics.track('Document Export Failed', { ...analyticInfo, error }))
  }

  const handleMouseLeave = () => {
    if (open) {
      setOpen(false)
    }
  }

  const exportFile = async format => {
    const data = await exportSpec({
      spec: text,
      format,
    })

    const { extension } = Formats[format]
    const blob = new Blob([data])
    saveAs(blob, `tech-spec.${extension}`)
  }

  const dropdownClassname = clsx(styles.dropdown, {
    [styles.open]: open,
  })

  return useMemo(
    () => ({
      handleMouseLeave,
      handleClickOpen,
      wrapperRef,
      dropdownClassname,
      handleClose,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [open, wrapperRef]
  )
}
