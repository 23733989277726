import { useFetchData, RequestTooLargeApiError, PaymentRequiredError, SchemaValidationError } from '../api'
import { DESIGN_INPUTS_SOURCE } from '../constants'
// import { PolicyViolation } from '../components/common/PolicyViolation';
import { useController } from './useController'
import { useDesignInputs } from './useDesignInputs'
import {
  TEXT_TO_IMAGE_ENDPOINT,
  SKETCH_TO_IMAGE_ENDPOINT,
  VARIATION_IMAGE_ENDPOINT,
  IMAGE_ERASE_ENDPOINT,
  GENERATE_IMAGE_ASPECT_RATIO,
  UPLOAD_TO_IMAGE_ENDPOINT,
} from '../lib/config'
import { useGalleryStore } from '../store/useGalleryStore'
import { useSessionStore } from '../store/useSessionStore'
import { useQueue } from './useQueue'

import * as analytics from '../lib/analytics'
import * as observability from '../lib/observability'
import { UPLOAD_ERROR_MESSAGE } from '../constants/msgEnums'
import { formatEndpoint } from '../utils/endpointsUtils'
import { useIdeationChat } from '@/src-ideation/store/useChat.js'

interface Monitor {
  start: (name: string, props?: Record<string, unknown>) => void
  finished: (name: string, props?: Record<string, unknown>, response?: any) => void
  failed: (name: string, error: any) => void
}

const monitor: Monitor = {
  start: (name, props) => analytics.track(`${name} Started`, props!),
  finished: (name, props) => {
    const eventProps = {
      ...props,
    }
    analytics.track(`${name} Finished`, eventProps)
  },
  failed: (name, error) => {
    observability.captureException(`${name} Failure`, {
      error,
      tags: { name },
    })

    analytics.track(`${name} Failed`, { error })
  },
}

const makeRequestWithAnalytics = async <T>(
  name: string,
  eventProps: Record<string, unknown>,
  request: () => Promise<T>
): Promise<T> => {
  try {
    monitor.start(name, eventProps)
    const response = await request()
    monitor.finished(name, eventProps, response)
    return response
  } catch (error) {
    monitor.failed(name, error)
    throw error
  }
}

const generateVariantImage2Image = (
  fetchData: any,
  text: string,
  variantPayload: any,
  creativeness: number,
  endpoint: string
) => {
  const data = {
    refId: variantPayload.id,
    text,
    creativeness,
    intensity: creativeness === 1 ? 'strong' : 'subtle',
  }

  const eventProps = { creativeness }
  const NAME = 'Generate Variant2Image'
  return makeRequestWithAnalytics(NAME, eventProps, () => fetchData({ url: endpoint, data }))
}

export const generateSketch2Image = async (
  fetchData: any,
  text: string,
  sketchBase64: string,
  creativeness: number,
  endpoint: string
) => {
  const data = {
    text,
    draw: sketchBase64,
    creativeness,
    aspectRatio: GENERATE_IMAGE_ASPECT_RATIO,
  }

  const eventProps = { text, creativeness }
  const NAME = 'Generate Sketch2Image'
  return makeRequestWithAnalytics(NAME, eventProps, () => fetchData({ url: endpoint, data }))
}

const generateImage2Image = async (
  fetchData: any,
  text: string,
  creativeness: number,
  reference: any,
  endpoint: string
) => {
  const data = {
    text,
    reference,
    creativeness,
    aspectRatio: GENERATE_IMAGE_ASPECT_RATIO,
  }

  const eventProps = {
    text,
    referenceName: reference.name,
    referenceMimeType: reference.mimeType,
  }

  const NAME = 'Generate Image2Image'
  return makeRequestWithAnalytics(NAME, eventProps, () => fetchData({ url: endpoint, data }))
}

const generateText2Image = async (fetchData: any, text: string, creativeness: number, endpoint: string) => {
  const data = {
    text,
    creativeness,
    aspectRatio: GENERATE_IMAGE_ASPECT_RATIO,
  }

  const eventProps = { text }
  const NAME = 'Generate Text2Image'
  return makeRequestWithAnalytics(NAME, eventProps, () => fetchData({ url: endpoint, data }))
}

const generateImageWithEraserMask = async (fetchData: any, creativeness: number, payload: any, endpoint: string) => {
  const data = {
    prompt: payload.prompt,
    refId: payload.imageId,
    mask: payload.mask,
    creativeness: creativeness,
  }

  const NAME = 'Generate ImageWithEraserMask2Image'
  return makeRequestWithAnalytics(NAME, payload, () => fetchData({ data, url: endpoint }))
}

export const useGenerateImageRequest = () => {
  const promptVersions = useIdeationChat((store: any) => store.promptVersions)
  const variantPayload = useGalleryStore((store: any) => store.variantPayload)
  const { sessionNum, revisionNum } = useSessionStore(store => store)
  const { base64String, sketchBase64, uploadedImage, magicEraserPayload } = useDesignInputs()
  const { queue } = useQueue()
  const { value: creativeness } = useController()
  const { fetchData } = useFetchData()

  const lastItem = queue[queue.length - 1]
  const lastItemType = lastItem?.type
  const text = promptVersions[promptVersions.length - 1]

  if (lastItemType === DESIGN_INPUTS_SOURCE.MAGIC_ERASER) {
    const endpoint = formatEndpoint(IMAGE_ERASE_ENDPOINT, sessionNum, revisionNum, magicEraserPayload.imageId)
    return () =>
      generateImageWithEraserMask(
        fetchData,
        creativeness,
        {
          prompt: text,
          ...magicEraserPayload,
        },
        endpoint
      )
  }

  if (lastItemType === DESIGN_INPUTS_SOURCE.VARIANT) {
    const endpoint = formatEndpoint(VARIATION_IMAGE_ENDPOINT, sessionNum, revisionNum, variantPayload.imageId)
    return () => generateVariantImage2Image(fetchData, text, variantPayload, creativeness, endpoint)
  }

  if (lastItemType === DESIGN_INPUTS_SOURCE.SKETCH) {
    const sketchEndpoint = formatEndpoint(SKETCH_TO_IMAGE_ENDPOINT, sessionNum, revisionNum)
    return () => generateSketch2Image(fetchData, text, sketchBase64!, creativeness, sketchEndpoint)
  }

  const imageGenerateEndpoint = formatEndpoint(TEXT_TO_IMAGE_ENDPOINT, sessionNum, revisionNum)
  if (lastItemType === DESIGN_INPUTS_SOURCE.UPLOADED) {
    const uploadToImageEndpoint = formatEndpoint(UPLOAD_TO_IMAGE_ENDPOINT, sessionNum, revisionNum)
    return () =>
      generateImage2Image(
        fetchData,
        text,
        creativeness,
        {
          image: base64String,
          strength: 0,
          name: uploadedImage!.name,
          mimeType: uploadedImage!.type,
        },
        uploadToImageEndpoint
      )
  }

  return () => generateText2Image(fetchData, text, creativeness, imageGenerateEndpoint)
}

export const generateImageErrors = (error: any): string | JSX.Element => {
  if (error instanceof RequestTooLargeApiError) {
    return 'Provided image is too large.'
  }

  if (error instanceof PaymentRequiredError) {
    return 'Not enough credits to Generate'
  }

  if (error instanceof SchemaValidationError) {
    if (error!.validations!.find((validation: any) => validation.path === '/reference/mimeType')) {
      return UPLOAD_ERROR_MESSAGE
    }

    analytics.track('API Error', {
      scope: 'Image Generation',
      reason: 'Schema Validation',
      errorMessage: error?.message,
    })

    return `Oops, We have encountered an internal issue, our team has been notified.`
  }

  if (error?.errorType === 'Inappropriate Prompt') {
    // return <PolicyViolation />;
  }

  return "I'm sorry... Due to overload on the servers I couldn't generate the images for you. Please try again in a few seconds."
}
