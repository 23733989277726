import React, { useEffect, useLayoutEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { useFetchData } from '../api'
import { Footer } from '@/src-ideation/components/Footer'
import { LeftSection } from '@/src-ideation/components/LeftSection'
import { LeoProModal } from '@/src-ideation/components/LeoProModal'
import { OnboardingForm } from '@/src-ideation/components/OnboardingForm'
import { RightSection } from '@/src-ideation/components/RightSection'
import { useCheckKYC } from '../hooks/useCheckKYC'
import { generateImageErrors, useGenerateImageRequest } from '../hooks/useGenerateImageRequest.ts'
import * as analytics from '../lib/analytics'
import { FEATURE_NO_GENERATE, FEATURE_PRODUCT_SUMMARY, IMAGE_LIST_ENDPOINT, LEO_SPEC_ENDPOINT } from '../lib/config'
import { useGalleryStore } from '../store/useGalleryStore'
import { useTechSpecStore } from '../store/useTechSpecStore'
import { parseMessagesLog } from '../utils/parseMessagesLog'
import 'photoswipe/dist/photoswipe.css'
import { useUserCredits } from '../hooks/useUserCredits'
import { useGalleryModal } from '../store/useGalleryModal'
import { useAppState } from '../store/useAppState'
import useDocs from '../hooks/useDocs'
import { extractInputContent } from '../utils/extractInputContent'
import { mockDataObject } from '../utils'
import { useSessionStore } from '../store/useSessionStore'
import { useDesignInputsStore } from '@/src-ideation/store/useDesignInputsStore'
import { GoToChatScreen } from '@/src-ideation/components/GoToChatScreen/GoToChatScreen'
import useScreenSize from '@/components/useScreenSize/useScreenSize.ts'
import { useIdeationChat } from '@/src-ideation/store/useChat.js'

// const regex = /<h1>(.*?)<\/h1>/

export const Home = React.memo(() => {
  const { setLoadingImages, storeResponse } = useGalleryStore(store => store)
  const { specVersions, setSpecVersions, setLoading, setRevisionsAmount, revisionsAmount } = useTechSpecStore(
    store => store
  )
  const {
    fullMessagesLog,
    lastGenFullMassageLog,
    setLastGenFullMassageLog,
    setIsGenerated,
    promptVersions,
    productDescription,
  } = useIdeationChat(store => store)
  const { isGeneratedAlready } = useSessionStore(store => store)
  const currentTextPrompt = promptVersions[promptVersions.length - 1]
  const { compileDocumentAndRetrieveArtifacts } = useDocs('product-summary')
  const { getUserCredits } = useUserCredits()
  const { fetchData } = useFetchData()
  const generateImagesRequest = useGenerateImageRequest()
  const { setImagesData, imagesData } = useGalleryModal()
  const { isVisible, setIsVisible } = useCheckKYC()
  const [previousSpecVersions, setPreviousSpecVersions] = useState({})
  const addSpec = text => setSpecVersions([...specVersions, text])
  const { setIsFirstSeenApp } = useAppState(store => store)
  const generateImages = async () => {
    setLoadingImages(true)
    try {
      const data = FEATURE_NO_GENERATE ? mockDataObject : await generateImagesRequest()
      storeResponse(data)
      return data
    } catch (error) {
      const errorMessage = generateImageErrors(error)
      toast.error(errorMessage, { closeOnClick: false })
    } finally {
      setLoadingImages(false)
    }
  }
  const { setMagicEraserImage } = useDesignInputsStore(store => store)
  const screenSize = useScreenSize()
  const isMobile = screenSize.width < 800
  const [isLoaded, setIsLoaded] = useState(false)
  const [startGenerate, setStartGenerate] = useState(false)
  const generateSpec = async () => {
    try {
      if (lastGenFullMassageLog === fullMessagesLog) {
        return previousSpecVersions
      }
      // Add empty spec to show pagination
      addSpec('')
      setLoading(true)
      analytics.track('Generate Spec Started')
      const data = await fetchData({ url: LEO_SPEC_ENDPOINT, data: { messages: parseMessagesLog(fullMessagesLog) } })
      analytics.track('Generate Spec Finished')

      const [_opening, content, _closing] = extractInputContent(data.content, '<html>', '</html>')
      if (content.length > 0) {
        addSpec(content)
      }
      setLastGenFullMassageLog(fullMessagesLog)
      setPreviousSpecVersions(data)
      return data
    } catch (error) {
      analytics.track('Generate Spec Failed', { error })
      toast.error(
        "I'm sorry... Due to overload on the servers I couldn't generate the technical spec for you. Please try again in a few seconds."
      )
      setSpecVersions([...specVersions]) // removeEmptySpec
    } finally {
      setLoading(false)
    }
  }

  const onGenerate = async () => {
    analytics.track('Generate Started')
    setStartGenerate(true)
    setIsGenerated(false)
    try {
      const inputs = { 'product-description': currentTextPrompt }

      const promise1 = generateImages()

      let promise2 = null
      if (!isGeneratedAlready && productDescription) {
        if (FEATURE_PRODUCT_SUMMARY) {
          promise2 = compileDocumentAndRetrieveArtifacts(revisionsAmount, inputs).then(() =>
            setRevisionsAmount(revisionsAmount + 1)
          )
        } else {
          promise2 = generateSpec()
        }
      }

      await Promise.all([promise1, promise2])
        .then(res => {
          const imgData = res[0] && res[0].upscale
          setImagesData([...imagesData, ...imgData])
        })
        .catch(err => {
          console.log(err)
          return null
        })

      setIsGenerated(true)
      analytics.track('Generate Finished')
      await getUserCredits()
    } catch (error) {
      analytics.track('Generate Failed', { error })
      console.error('Error during the spec and image generation', error)
    } finally {
      setStartGenerate(false)
    }
  }

  useEffect(() => {
    setMagicEraserImage(<img src="" alt="" />)
    fetchData({ url: IMAGE_LIST_ENDPOINT, method: 'GET', params: { limit: 1 } })
      .then(result => {
        if (result.images.length === 0) {
          setIsFirstSeenApp(true)
        }
      })
      .catch(error => console.error(error))
    setIsLoaded(true)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useLayoutEffect(() => {
    isLoaded && localStorage.removeItem('isIdeationRedirect')
  }, [isLoaded])

  if (isMobile) {
    return <GoToChatScreen />
  }

  return (
    <>
      <div className="my-component-container">
        <LeftSection {...{ addSpec, generateImages, startGenerate }} />
        <div className="divider"></div>
        <RightSection {...{ onGenerate }} />
      </div>
      <Footer {...{ onGenerate }} />

      <LeoProModal
        type={'kyc'}
        modalIsOpen={isVisible}
        setModalIsOpen={() => setIsVisible(true)}
        hideCloseButton={true}
      >
        <h1 style={{ textAlign: 'left' }}>Complete your Profile</h1>
        <OnboardingForm {...{ submitCallback: () => setIsVisible(false) }} setCloseModal={() => setIsVisible(false)} />
      </LeoProModal>
    </>
  )
})
