export function extractInputContent(str: string, startTag: string, endTag: string) {
  const startIndex = str.indexOf(startTag)
  const endIndex = str.indexOf(endTag)

  if (startIndex !== -1 && endIndex !== -1) {
    const opening = str.slice(0, startIndex)
    const content = str.slice(startIndex + startTag.length, endIndex)
    const closing = str.slice(endIndex + endTag.length)

    return [opening, content, closing]
  }
  return [str, '', '']
}
