import styled from 'styled-components'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4vh;
  height: 92vh;
`

export const SessionsWrapper = styled.div`
  display: flex;
  justify-content: left;
  flex-wrap: wrap;
  gap: 2vh;
  width: 70%;
  margin-top: 5%;
  margin-left: 10.2%;
`

export const LoadingWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 10%;

  img {
    width: 20vh;
    height: 20vh;
  }
`
