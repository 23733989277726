import { BASE_URL } from "../lib/config.ts";
import { useState } from "react";
import * as observability from "../lib/observability.ts";
import * as analytics from "../lib/analytics.ts";
import * as errors from "./errors.ts";
import { getSessionToken } from "@descope/react-sdk";
import {
  handleNotFoundError,
  handleSchemaValidationError,
  handleUnknownError,
} from "./error-handlers.ts";
import { fetchPreSignUrls } from "./presign.ts";
import {
  ArgsType,
  ChatPayloadType,
  Message,
  ResponseDataType,
  SessionInfoType,
  UrlDocumentType,
} from "@/store/useChat.ts";

const Static = {
  TextDecoder: new TextDecoder(),
};

export type SubmitChatMessageResult = {
  messages: Message[];

  isValid: boolean;

  // If isValid is false, this field indicates the reason.
  invalidReason: string;
};

export type StreamProgressEvent = {
  type: "progress";
  payload: {
    delta: string;
  };
};

export type StreamReferencesEvent = {
  type: "references";
  payload: {
    references: any;
  };
};

export type StreamEndEvent = {
  type: "end";
  payload: {
    // TODO: might be better to type this, but it is as the response data was
    // when the response wasn't streamed - so we keep this aligned.
    messages: any;
  };
};

export type StreamEvent =
  | StreamProgressEvent
  | StreamReferencesEvent
  | StreamEndEvent;

export type StreamEventsHandler = (streamEvents: StreamEvent[]) => void;

export type SubmitChatMessageOptions = {
  withSearch: boolean;
  stream?: boolean;
  onStreamEvents?: StreamEventsHandler;
};

/**
 * Given a stream chunk
 * @param chunk Buffer as received from the fetch response async iterator
 * @param eventHandler callback to invoke on stream progress event
 * @returns
 */
async function handleStreamChunk(
  chunkList: ArrayBuffer[],
  eventsHandler: StreamEventsHandler | undefined,
): Promise<StreamEvent[]> {
  const decodedList: any = [];

  chunkList.forEach((chunk: ArrayBuffer) => {
    decodedList.push(Static.TextDecoder.decode(chunk));
  });

  const decodedJoined = decodedList.join("");

  const events = decodedJoined
    .split("\n")
    .filter((line: string) => line !== "")
    .map((line: string) => JSON.parse(line));

  if (eventsHandler) {
    eventsHandler(events);
  }

  return events;
}

export function useChatApi() {
  const [urlsList, setUrlsList] = useState<UrlDocumentType[]>([]);

  async function submitChatMessage(
    messages: Message[],
    sessionNum: number,
    revisionNum: number,
    opts: SubmitChatMessageOptions,
  ): Promise<SubmitChatMessageResult> {
    const url = `${BASE_URL}/api/v4/session/${sessionNum}/revision/${revisionNum}/chat`;

    const sessionToken = getSessionToken();

    const isStream = true;

    const options = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionToken}`,
      },
      body: JSON.stringify({
        messages,
        agent: "desktop",
        withSearch: opts.withSearch,
        stream: isStream,
      }),
    };

    const data: ChatPayloadType = JSON.stringify({
      messages,
      agent: "desktop",
      withSearch: opts.withSearch,
      stream: isStream,
    });

    let chunk: any = null;

    try {
      const response = await fetch(url, options);

      const params = { sessionNum, revisionNum };

      await handleError(response, params, data);

      let responseData = null;

      if (!isStream) {
        responseData = await response.json();
      } else {
        // For some reason "response.body" is not async iterable as it should be.
        // Therefore, we should iterate using the reader.
        // See: https://developer.mozilla.org/en-US/docs/Web/API/Streams_API/Using_readable_streams
        const reader = response.body?.getReader();

        let entry = await reader?.read();
        let chunksToDecode = [];
        while (!entry?.done) {
          chunk = entry?.value;

          if (!chunk) {
            continue;
          }

          chunksToDecode.push(chunk);

          let events: any;
          try {
            events = await handleStreamChunk(
              chunksToDecode,
              opts.onStreamEvents,
            );
          } catch (e: any) {
            if (e instanceof SyntaxError) {
              entry = await reader?.read();
              if (entry?.done) {
                throw new errors.UnknownApiError(`JSON Invalid Error ${e}`);
              }
              continue;
            } else {
              throw new errors.UnknownApiError(
                `Handle Stream Chunk Error ${e}`,
              );
            }
          }

          chunksToDecode = [];

          const endEvents = events.filter((event: any) => event.type === "end");
          if (endEvents.length > 0) {
            // The "end" event's payload, is the same response as without streaming.
            // Therefore, we can continue the same.
            if (responseData != null || endEvents.length > 1) {
              throw new Error("Multiple end events received");
            }

            // We already filtered only the "end" events so we know that
            // payload.messages exist.
            responseData = (endEvents[0].payload as any).messages;
          }

          entry = await reader?.read();
        }

        if (responseData == null) {
          throw new Error("Stream did not return an 'end' event");
        }
      }

      const myData = { ...responseData };
      const messages = await responseMapper(myData);

      return {
        messages,
        isValid: responseData.isValid,
        invalidReason: responseData.invalidReason,
      };
    } catch (error: any) {
      const errorChunk = Static.TextDecoder.decode(chunk);
      observability.captureException(`Chat Api Failure`, {
        error,
        tags: { url },
        extra: { messages, errorChunk },
      });

      analytics.track("Chat Message error", { error });

      throw new errors.UnknownApiError(`Error with fetching data from ${url}`);
    }
  }

  async function handleError(
    response: Response,
    params: SessionInfoType,
    data: ChatPayloadType,
  ) {
    if (response.ok) {
      return;
    }
    const error = await response.json();
    const args: ArgsType = {
      headers: response.headers,
      url: response.url,
      method: "PUT",
      params,
      data,
    };

    if (error.isSchemaValidationError) {
      handleSchemaValidationError(error, args);
    }

    if (response.status === 404) {
      handleNotFoundError(error, args);
    }

    if (response.status === 400 && error?.reason === "Policy Violation") {
      observability.captureException(
        `Policy Violation Error PUT ${response.url}`,
        {
          error,
          tags: { url: response.url },
        },
      );

      analytics.track("Policy Violation Error", {
        error,
      });

      throw new errors.PolicyViolationError(error.message);
    }

    if (response.status === 424 && error?.reason === "Policy Violation") {
      observability.captureException(
        `Azure OpenAi Policy Violation Error PUT ${response.url}`,
        {
          error,
          tags: { url: response.url },
        },
      );

      analytics.track("Azure OpenAI's content management policy", {
        error,
      });

      throw new errors.PolicyViolationError(error.message);
    }
    handleUnknownError(error, args);
  }

  async function postProcessMessages(messages: Message[]) {
    const presignInputArray: UrlDocumentType[] = [];
    const webUrls: UrlDocumentType[] = [];

    messages.forEach((message: Message) => {
      if (message.references) {
        Object.keys(message.references.citations).forEach(
          (citationKey: string) => {
            const citation = message.references?.citations[citationKey];
            if (!citation) {
              return;
            }

            switch (citation.type) {
              case "web": {
                const site = citation.site;
                const url = citation.url;
                webUrls.push({
                  index: message.references?.index || "",
                  name: site,
                  url,
                });
                break;
              }
              case "document": {
                const documentName = citation.documentName;
                const page = citation.pageNumber;

                if (
                  urlsList.findIndex(
                    (obj: UrlDocumentType) =>
                      obj.name === documentName && obj.page === page,
                  ) === -1
                ) {
                  presignInputArray.push({
                    name: documentName || "",
                    index: message.references?.index || "",
                    page,
                  });
                }
                break;
              }
            }
          },
        );
      }
    });

    const urls = await fetchPreSignUrls(presignInputArray);
    setUrlsList([...urls.documents, ...webUrls]);

    return messages.map((message: Message) => {
      let textWithCitation: string | any = message.text;

      // const isContainsTable = containsTable(textWithCitation);

      if (message.references) {
        let citationIndex = 1;
        Object.keys(message.references.citations).forEach(
          (citationReference: string) => {
            if (message.text.includes(citationReference)) {
              const citation = message.references?.citations[citationReference];
              if (citation == undefined) {
                return;
              }

              switch (citation.type) {
                case "web":
                  textWithCitation = textWithCitation.replaceAll(
                    citationReference,
                    `[${citationIndex}_separator${citation.site}](${citation.url})`,
                  );
                  break;
                case "document": {
                  const documentName = citation.documentName;
                  const page = citation.pageNumber;
                  const urlLink =
                    [...urls.documents, ...urlsList].find(
                      (obj: UrlDocumentType) =>
                        obj.name === documentName && obj.page === page,
                    )?.url + "#page=3";

                  const linkName = documentName?.split(".pdf")[0];

                  textWithCitation = textWithCitation.replaceAll(
                    citationReference,
                    `[${citationIndex + "_separator" + linkName + "_separator" + page}](${urlLink}) `,
                  );
                  break;
                }
              }

              citationIndex = citationIndex + 1;
            }
          },
        );
      }

      const finalChar = textWithCitation.charAt(textWithCitation.length - 2);
      if (finalChar === ".") {
        textWithCitation = textWithCitation.trim().slice(0, -1);
      }

      return { ...message, text: textWithCitation };
    });
  }

  async function responseMapper(
    responseData: ResponseDataType,
  ): Promise<Message[]> {
    // I have only extracted the inner logic so we can expose it
    // in order to post process messages without having the
    // api response itself.
    return await postProcessMessages(responseData.items);
  }

  return { submitChatMessage, postProcessMessages };
}
