import React from 'react'
import { Outlet, useLocation } from 'react-router-dom'
import { ROUTE } from '../../router/Router.tsx'
import { MemoizedSpinner } from '../spinner/Spinner.tsx'
import { useChat } from '@/store/useChat.ts'
import { Header } from '@/src-ideation/components/Header'
import { InfoPoPUp } from '@/components/infoPoPUp/InfoPoPUp.tsx'

function checkIfQueryParamExists(allSearchParams: string, queryParam: string): boolean {
  return allSearchParams.indexOf(queryParam) !== -1
}

interface LayoutProps {
  isSessionLoading: boolean
  isAuthenticated: boolean
  isUserLoading: boolean
  showPopUp: boolean
  onHandleClose: () => void
}
export const Layout: React.FC<LayoutProps> = React.memo(({ isSessionLoading, isAuthenticated, isUserLoading, showPopUp, onHandleClose }) => {
  const location = useLocation()
  const { isKycFetching } = useChat(store => store)
  const isLoading =
    location.pathname === ROUTE.SIGN_IN && checkIfQueryParamExists(location.search, 'descope-login-flow')

  return (
    <div style={{ position: 'relative' }}>
      {isAuthenticated && location.pathname !== ROUTE.CHAT && <Header {...{ onNewSessionClicked: () => {} }} />}

      {showPopUp && <InfoPoPUp onHandleClose={onHandleClose} />}

      {isSessionLoading || isUserLoading || isLoading || isKycFetching ? (
        <div
          style={{
            position: 'absolute',
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            background: '#231c2b',
            zIndex: 1111,
            height: '100vh',
          }}
        >
          <div
            className="container-center"
            style={{
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <MemoizedSpinner />
          </div>
        </div>
      ) : (
        ''
      )}
      <main>
        <Outlet />
      </main>
    </div>
  )
})
