
import clsx from 'clsx'
import   DrawIcon  from '../../../../../assets/icons/draw.svg?react'
import { FIRST_SEEN_ELEMENT, useGlowing } from '../../../../../hooks/useGlowing'
import { UploadImage } from './UploadImage'
import '../Canvas.scss'

export const EmptyCanvas = ({ canvasOpen, handleOpenCanvas }) => {
  const { isDesignInputsGlow, onElementClick } = useGlowing()

  const onSketchClick = e => {
    e.stopPropagation()
    onElementClick(FIRST_SEEN_ELEMENT.DESIGN_INPUTS)
    if (!canvasOpen) {
      handleOpenCanvas()
    }
  }

  return (
    <div className="EmptyCanvas">
      <div className="content" onClick={onSketchClick}>
        <DrawIcon className={clsx('draw-icon dark', isDesignInputsGlow && 'glowing')} />
        <span className="text dark" style={{ cursor: 'pointer' }}>
          Sketch
        </span>
      </div>
      <div className="content">
        <UploadImage />
      </div>
    </div>
  )
}
