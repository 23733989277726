
import { Box, Stack } from '@mui/material'
import { Button } from '../../../components/common/Button'
import styles from './Plan.module.scss'

export const Plan = () => {
  const plan = 'Basic'

  const upgradeHandler = () => {
    // TODO api
  }

  const cancelPlanHandler = () => {
    // TODO api
  }

  return (
    <Box className={styles.Plan}>
      <Box>
        <h2>Your Plan</h2>
        <span className={styles.greenText}>{plan}</span>
      </Box>

      <Stack direction="row" spacing={2} height="fit-content" flexWrap="wrap" justifyContent="flex-end">
        <Button variant="outlined" color="secondary" onClick={upgradeHandler} className={styles.button} rounded>
          Upgrade plan
        </Button>
        <Button variant="outlined" color="warning" onClick={cancelPlanHandler} className={styles.button} rounded>
          Cancel plan
        </Button>
      </Stack>
    </Box>
  )
}
