import React from "react";
import styles from "../../Footer.module.scss";
import Slack from '@/src-ideation/assets/icons/Slack_icon_2019 1.svg?react'
import {SLACK_URL, SUPPORT_URL} from "@/lib/config.ts";

export const SlackChat: React.FC = () => {
    return <div className={styles.supportBlock}>
        <a href={`mailto:${SUPPORT_URL}`}>support@getleo.ai</a>
        {
            <a
                target="_blank"
                rel="noopener noreferrer"
                href={SLACK_URL}
                className={styles.chat}
            >
                <Slack className={styles.icon}/> Chat support
            </a>
        }
    </div>
}
