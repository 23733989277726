
import { Wrapper, ImageOfSession, TextWrapper, Title, SessionDate } from './sessionCardStyle'
import { SessionCardProps } from '../../../hooks/sessions/useLoadSessionData'
import { dateFormatConversion } from '../../../utils/dateFormatConversion'
import useSessionThumbnail from '../../../hooks/sessions/useSessionThumbnail'
import loadingGif from '../../../assets/icons/Logo_animation_Bright.gif'

function SessionThumbnail(props: { sessionNum: number }) {
  const sessionThumbnail = useSessionThumbnail(props.sessionNum)

  let url = ''
  if (!sessionThumbnail.isLoading && !sessionThumbnail.error) {
    url = sessionThumbnail.data
  }

  if (sessionThumbnail.isLoading) {
    url = loadingGif
  }

  return <ImageOfSession src={url} />
}

export default function SessionCard(props: SessionCardProps) {
  const formattedDate = dateFormatConversion(props.createdAt || '')

  return (
    <Wrapper onClick={() => props.onCardClick()}>
      <SessionThumbnail sessionNum={props.sessionNum} />
      <TextWrapper>
        <Title>{props.headline}</Title>
        <SessionDate>{formattedDate}</SessionDate>
      </TextWrapper>
    </Wrapper>
  )
}
