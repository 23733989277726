import React, { useEffect, useState } from 'react'
import { extractTextFromHTML } from '../../utils/extractTextFromHTML'
import { CopyToClipboardButton } from './CopyToClipboardButton'
import { ExportButton } from './ExportButton'

import { FEATURE_SPEC_EXPORT } from '../../lib/config'

export const CopyAndExportControls = ({ text, isInSpecComp = false, hideExportButton = false }) => {
  const [plainText, setPlainText] = useState('')
  useEffect(() => {
    const plainText = extractTextFromHTML(text)
    setPlainText(plainText)
  }, [text])

  return (
    <div style={{ display: 'flex', alignItems: 'center', position: 'relative' }}>
      <CopyToClipboardButton {...{ plainText }} />
      {FEATURE_SPEC_EXPORT && !hideExportButton && <ExportButton {...{ text, isInSpecComp }} />}
    </div>
  )
}
