import { IpcRendererEvent } from 'electron'

export type OpenPDFOptions = {
  url: string
}

export type resizeAppWindowOptions = {
  isOpen: boolean
}

export type receivedDataOptions = {
  [key: string]: any
}

export type receivedDataCallback = (event: IpcRendererEvent, data: any) => void

const app = () => (window as any).app

export async function openPDF(opts: OpenPDFOptions) {
  await app().openPDF(opts)
}

export async function resizeAppWindow(opts: resizeAppWindowOptions) {
  await app().resizeAppWindow(opts)
}

export function onReceivedData(callback: receivedDataCallback) {
  app().onReceivedData(callback)
}

export function onInputSubmitted(callback: receivedDataCallback | null) {
  app().onInputSubmitted(callback)
}
export function openInputWindow() {
  app().openInputWindow()
}

export function submitInput(inputText: string) {
  app().submitInput(inputText)
}
