import { useQuery } from '@tanstack/react-query'
import createInstance from '../../api/createInstance'
import { LIST_SESSION_ENDPOINT } from '../../lib/config'
import * as observability from '../../lib/observability'
import * as analytics from '../../lib/analytics'
import { toast } from 'react-toastify'
import { SessionMetadata } from './useLoadSessionData'

export default function useGetSessionsMetadataList() {
  return useQuery({
    queryKey: ['sessions-list'],
    queryFn: async () => {
      const api = createInstance()

      try {
        const response = await api({
          url: LIST_SESSION_ENDPOINT,
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        })

        return response.data.sessions.filter(({ agent }: SessionMetadata) => agent !== 'desktop') as SessionMetadata[]
      } catch (error) {
        observability.captureException(`List Sessions Metadata error GET ${LIST_SESSION_ENDPOINT}`, {
          error,
          tags: { url: LIST_SESSION_ENDPOINT, method: 'GET' },
        })

        analytics.track('List Sessions Failure', { error })
        toast.error('Unable to get list sessions')

        throw new Error('List Sessions Failure')
      }
    },
    retry: false,
    // staleTime: Infinity,
  })
}
