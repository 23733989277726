import styled from 'styled-components'
export interface FilledProps {
  percentage?: number | undefined
}

export const StyledLoadingBar = styled.div`
  width: 100%;
  height: 1vh;
  background: rgba(242, 238, 232, 0.3);
`

export const Filled = styled.div<FilledProps>`
  width: ${props => (props.percentage ? props.percentage : 0)}%;
  height: 1vh;
  background: rgba(242, 238, 232, 1);
`

export const Text = styled.div`
  font-weight: 700;
  padding: 0.2vh 2vh;
  text-align: center;
`
