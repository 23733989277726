
import clsx from 'clsx'
import PrevIcon from '../../../../assets/icons/arrow-prev.svg?react'
import NextIcon from '../../../../assets/icons/arrow-next.svg?react'
import { useTechSpecStore } from '../../../../store/useTechSpecStore'
import styles from './Pagination.module.scss'

export const Pagination = () => {
  const { specVersions, setText, currentVersionIndex, setCurrentVersionIndex, revisionsAmount } = useTechSpecStore(
    store => store
  )

  const handlePrev = () => {
    const newIndex = currentVersionIndex - 1
    if (newIndex >= 0) {
      setText(specVersions[newIndex])
      setCurrentVersionIndex(newIndex)
      return
    }
    setCurrentVersionIndex(currentVersionIndex)
  }

  const handleNext = () => {
    const newIndex = currentVersionIndex + 1
    if (newIndex < specVersions.length || newIndex < revisionsAmount) {
      setText(specVersions[newIndex])
      return setCurrentVersionIndex(newIndex)
    }
    setCurrentVersionIndex(currentVersionIndex)
  }

  const isPrevDisabled = currentVersionIndex === 0
  const isNextDisabled = specVersions.length - 1 === currentVersionIndex || revisionsAmount - 1 === currentVersionIndex

  return (
    <div className={clsx(styles.Pagination, styles.dark)}>
      <button className={clsx(isPrevDisabled && styles.disable)} onClick={handlePrev}>
        <PrevIcon />
      </button>
      <span className={styles.index}>{currentVersionIndex + 1}</span>
      <button className={clsx(isNextDisabled && styles.disable)} onClick={handleNext}>
        <NextIcon />
      </button>
    </div>
  )
}
