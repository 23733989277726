
import clsx from 'clsx'
import { Stack } from '@mui/material'
import { Button } from '../../Button'
import { Feedback } from '../../Feedback'
import styles from './ModalFooter.module.scss'

export const ModalFooter = ({ clearHandler, saveHandler }) => {
  return (
    <div onClick={e => e.stopPropagation()} className={clsx(styles.ModalFooter)}>
      <Feedback />
      <Stack direction="row" spacing={1}>
        <Button
          onClick={clearHandler}
          variant="contained"
          color="secondary"
          style={{
            marginRight: '2vh',
          }}
        >
          Clear
        </Button>
        <Button onClick={saveHandler} variant="contained">
          Save
        </Button>
      </Stack>
    </div>
  )
}
