import { create } from 'zustand'

// TODO: realistically this should be in the chat
export type ChatMessage = {
  sender: string
  text: string
}

export type ChatMessageEntry = {
  tag: 'chat-message'
  message: ChatMessage
}

export type SpecEntry = {
  tag: 'spec'
  text: string
}

export type GeneratedImageEntry = {
  tag: 'generated-image'
  upscaleUrls: string[]
}

export type SketchedImageEntry = {
  tag: 'sketched-image'
  data: string
  encoding: 'base64'
}

export type UploadedImageEntry = {
  tag: 'uploaded-image'
  data: string
  encoding: 'base64'
}

export type Entry = ChatMessageEntry | SpecEntry | GeneratedImageEntry | SketchedImageEntry | UploadedImageEntry

interface AuditState {
  entries: Entry[]
  appendEntries: (entries: Entry[]) => void
  insertOrReplaceIfSameTag: (entry: Entry) => void
}

export const useAudit = create<AuditState>(set => ({
  entries: [],

  appendEntries: (entries: Entry[]) =>
    set(state => {
      return {
        entries: state.entries.concat(entries),
      }
    }),

  insertOrReplaceIfSameTag: (entry: Entry) =>
    set(state => {
      const newEntries = [...state.entries]
      if (state.entries[state.entries.length - 1] && state.entries[state.entries.length - 1].tag === entry.tag) {
        newEntries[newEntries.length - 1] = entry
      } else {
        newEntries.push(entry)
      }

      return { entries: newEntries }
    }),
}))
