import React, { useState } from 'react'
import { Tooltip } from '@mui/material'
import MagicEraserIcon from '../../../../assets/icons/magic-eraser.svg?react'
import EditIcon from '../../../../assets/icons/edit-icon_2.svg?react'
import { useDesignInputsStore } from '../../../../store/useDesignInputsStore.ts'
import { ModalWindow } from '../../../ModalWindow'
import { Canvas } from './Canvas'
import styles from './styles.module.scss'
import * as analytics from '../../../../lib/analytics'
import { useGalleryModal } from '../../../../store/useGalleryModal'
export const MagicEraserButton = ({ imageRef, onImageStyle, type, imageUrl }) => {
  const { magicEraserImage, setMagicEraserImage, setMagicEraserMask } = useDesignInputsStore(store => store)
  const { setHide, setOpenModal } = useGalleryModal(store => store)
  const [isCanvasOpen, setIsCanvasOpen] = useState(false)

  const handleClick = event => {
    event.stopPropagation() // Prevent to open modal window

    if (type && type === 'edit') {
      analytics.track('Img Modal - Edit Image Button Clicked', { imageUrl })
    } else {
      analytics.track('Edit Image Button Clicked', { imageUrl })
    }

    // check if we open the same image
    if (imageRef.current !== magicEraserImage) {
      setMagicEraserMask([]) // Clear the mask state
    }
    setHide(true)
    setMagicEraserImage(imageRef.current)
    setIsCanvasOpen(true)
  }

  const handleClose = () => {
    if (type && type === 'edit') {
      analytics.track('Img Modal - Edit Image Button Closed', { imageUrl })
    } else {
      analytics.track('Edit Image Button Closed', { imageUrl })
    }
    setHide(false)
    setOpenModal(false)
    setIsCanvasOpen(false)
  }

  return (
    <>
      <Tooltip title="magic eraser" placement="top">
        <button onClick={handleClick} className={onImageStyle ? onImageStyle : styles.button}>
          {type === 'edit' ? <>Edit <EditIcon width={16} height={16} /></> : <MagicEraserIcon height={16} />}
        </button>
      </Tooltip>

      <ModalWindow isOpen={isCanvasOpen} {...{ handleClose }}>
        <Canvas {...{ handleClose, imageUrl }} />
      </ModalWindow>
    </>
  )
}
