import { BASE_URL } from "../lib/config.ts";
import * as observability from "../lib/observability.ts";
import * as analytics from "../lib/analytics.ts";
import * as errors from "./errors.ts";
import { getSessionToken } from "@descope/react-sdk";
import {
  handleNotFoundError,
  handleSchemaValidationError,
  handleUnknownError,
} from "./error-handlers.ts";
import { UrlDocumentType } from "@/store/useChat.ts";

const docToId = (doc: UrlDocumentType) => `${doc.index},${doc.name},${doc.page}`

type DocumentsCache = {
  [id: string]: UrlDocumentType
};

const Cache: DocumentsCache = {};

export async function fetchPreSignUrls(
  allDocuments: UrlDocumentType[],
): Promise<{ documents: UrlDocumentType[] }> {
  const url = `${BASE_URL}/api/v1/presign/chat-citation`;

  const sessionToken = getSessionToken();

  // Only send documents that don't exist in existingUrls
  const documents = allDocuments
    .filter(doc => !Object.keys(Cache).includes(docToId(doc)));

  if (documents.length == 0) {
    return { documents: Object.values(Cache) };
  }

  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${sessionToken}`,
    },
    body: JSON.stringify({ documents }),
  };

  try {
    const response = await fetch(url, options);
    const result = await handleResponse(response);
    const newDocuments = result.documents as UrlDocumentType[];
    newDocuments.forEach(doc => Cache[docToId(doc)] = doc);
    return { documents: Object.values(Cache)};
  } catch (error: any) {
    observability.captureException(`Presign urls Failure`, {
      error,
      tags: { url },
      extra: { documents },
    });

    analytics.track("Presign Urls Error", { error });

    throw new errors.UnknownApiError(`Error with fetching data from ${url}`);
  }
}

async function handleResponse(response: Response) {
  if (!response.ok) {
    const error = await response.json();
    const args: any = {
      headers: response.headers,
      url: response.url,
      method: "POST",
    };

    if (error.isSchemaValidationError) {
      handleSchemaValidationError(error, args);
    }

    if (response.status === 404) {
      handleNotFoundError(error, args);
    }

    handleUnknownError(error, args);
  }

  return response.json();
}
