
import LikeIcon from '../../assets/icons/like.svg?react'
import FilledLikeIcon from '../../assets/icons/like-filled.svg?react'

export const DislikeButton = ({ disliked, clickHandler, onImageStyle = null }) => {
  return (
    <button className={onImageStyle ? onImageStyle : 'DislikeButton'} onClick={event => clickHandler(event, 'dislike')}>
      {disliked ? <FilledLikeIcon /> : <LikeIcon />}
    </button>
  )
}
