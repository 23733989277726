import { useFetchData } from '../../../api'
import * as config from '../../../lib/config'

export const TargetFormat = {
  Text: 'text',
  Markdown: 'markdown',
  PDF: 'pdf',
  Word: 'docx',
}

export function useSpecExporter() {
  const { fetchData } = useFetchData()
  return async function ({ spec, format }) {
    return await fetchData({
      url: config.LEO_SPEC_EXPORT_ENDPOINT,
      method: 'POST',
      params: {},
      headers: {},
      responseType: 'blob',
      data: {
        source: spec,
        sourceFormat: 'html',
        targetFormat: format,
      },
    })
  }
}
