import styled from 'styled-components'

export const GoToChatScreen = () => {
  return (
    <GoToChatScreenWrap style={{ color: '#fff', padding: '2rem' }}>
      <h2>
        We apologize, but Ideation capabilities are only available on a wide screen.
        <div>Please try adjusting the width of your window if possible.</div>
        <div>Alternatively, you can continue chatting with Leo in the chat section.</div>
      </h2>
    </GoToChatScreenWrap>
  )
}

const GoToChatScreenWrap = styled.div`
  position: absolute;
  top: 3.2rem;
  right: 0;
  width: 100%;
  background: var(--dark-50);
  height: calc(100vh - 3.2rem);
`
