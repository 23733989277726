import { create } from 'zustand'
import { useAudit } from './useAudit'

export const useTechSpecStore = create(set => ({
  loading: false,
  setLoading: boolean => set(() => ({ loading: boolean })),

  specVersions: [],
  setSpecVersions: specVersions => set({ specVersions }),

  isSpecWindowExpanded: false,
  setIsSpecWindowExpanded: isSpecWindowExpanded => set({ isSpecWindowExpanded }),
  isDesignWindowExpanded: false,
  setIsDesignWindowExpanded: isDesignWindowExpanded => set({ isDesignWindowExpanded }),

  text: '',
  setText: text => set({ text }),

  revisionsAmount: 0,
  setRevisionsAmount: revisionsAmount => set({ revisionsAmount }),

  currentVersionIndex: 0,
  setCurrentVersionIndex: currentVersionIndex => set({ currentVersionIndex }),

  imagesContent: [],
  setImagesContent: imagesContent => set({ imagesContent }),
}))

useTechSpecStore.subscribe((state, prevState) => {
  const audit = useAudit.getState()
  if (state.text !== prevState.text) {
    audit.appendEntries([
      {
        tag: 'spec',
        text: state.text,
      },
    ])
  }
})
