import React, { useContext, useEffect, useRef, useState } from 'react'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { PolicyViolationError, useFetchData } from '../../../../api'
import { FIRST_SEEN_ELEMENT, useGlowing } from '../../../../hooks/useGlowing'
import { LEO_CHAT_ENDPOINT, CREATE_SESSION_ENDPOINT, CREATE_REVISION_ENDPOINT } from '../../../../lib/config'
import { PolicyViolation } from '../../../common/PolicyViolation'
import { Button } from './Button'
import './ChatInput.scss'

import * as analytics from '../../../../lib/analytics'
import * as observability from '../../../../lib/observability'
import { useGalleryModal } from '../../../../store/useGalleryModal'
import { useSessionStore } from '../../../../store/useSessionStore'
import { initialMessage } from '../../../../constants/msgEnums'
import { useIdeationChat } from '@/src-ideation/store/useChat.js'
import { FeatureFlagsContext } from '@/lib/featureFlags.ts'

const showAlert = error => {
  if (error instanceof PolicyViolationError) {
    toast.error(<PolicyViolation />)
  } else {
    const defaultAndUnrealisticErrorMessage =
      "I'm sorry... Due to overload on the servers Leo isn't available right now. Please try again in a few seconds."
    toast.error(defaultAndUnrealisticErrorMessage)
  }
}

const TextareaAutosizeChat = ({ setInputHeight, handleSubmit, value, style, ...rest }) => {
  const textareaRef = useRef(null)
  const [windowHeight, setWindowHeight] = useState(0)

  useEffect(() => {
    const handleResize = () => setWindowHeight(window.innerHeight)
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  useEffect(() => {
    textareaRef.current.style.height = 'auto'
    const scrollHeight = textareaRef.current.scrollHeight
    textareaRef.current.style.height = `${scrollHeight}px`
    setInputHeight(scrollHeight)
  }, [value, setInputHeight, windowHeight])

  const handleKeyPress = event => {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault() // Prevents the addition of a new line
      handleSubmit()
    }
  }

  return (
    <textarea
      placeholder="Type here"
      className="input-field"
      ref={textareaRef}
      rows={1}
      onKeyDown={handleKeyPress}
      autoFocus
      {...{ style, value }}
      {...rest}
    />
  )
}

export const ChatInput = ({ setInputHeight, setImagePrompt }) => {
  const featureFlags = useContext(FeatureFlagsContext)

  const {
    fullMessagesLog,
    setFullMessagesLog,
    messages,
    setMessages,
    inputText,
    setInputText,
    isLoading,
    setIsLoading,
    setProductDescription,
    productDescription,
  } = useIdeationChat(store => store)
  const { sessionNum, setSessionNum, revisionNum, setRevisionNum, isGeneratedAlready, setIsGeneratedAlready } =
    useSessionStore(store => store)
  const { fetchData } = useFetchData()
  const { activateGlow } = useGlowing()
  const { setRequestMessage } = useGalleryModal()

  const runHandleSubmit = useRef(true)

  useEffect(() => {
    if (productDescription && featureFlags.updateUserInputFromCadToIdeation && runHandleSubmit.current) {
      runHandleSubmit.current = false // Set it to false immediately to prevent double triggering
      handleSubmit()
    }
  }, [productDescription])

  const getSessionAndRevision = async () => {
    if (sessionNum === undefined) {
      const newSession = await fetchData({
        url: CREATE_SESSION_ENDPOINT,
        method: 'PUT',
        data: {
          headline: 'New Session',
          agent: 'ideation',
        },
      })

      setSessionNum(newSession.sessionNum)
      setRevisionNum(newSession.revisionNum)
      setIsGeneratedAlready(false)

      return newSession
    } else if (isCreateNewRevision()) {
      const newRevision = await fetchData({
        url: CREATE_REVISION_ENDPOINT.replace(':sessionNum', sessionNum),
        method: 'PUT',
      })
      // const newRevision = await createNewRevision(String(sessionNum))

      setRevisionNum(newRevision.revisionNum)
      setIsGeneratedAlready(false)

      return {
        sessionNum,
        revisionNum: newRevision.revisionNum,
      }
    } else {
      return {
        sessionNum,
        revisionNum,
      }
    }
  }

  const isCreateNewRevision = () => {
    return isGeneratedAlready
  }

  const formatChatEndpoint = (sessionNum, revisionNum) => {
    let url = LEO_CHAT_ENDPOINT
    url = url.replace(':sessionNum', sessionNum).replace(':revisionNum', revisionNum)
    return url
  }

  const handleSubmit = async () => {
    if (!inputText.trim().length || isLoading) return

    const inputMessage = { text: inputText.trim(), sender: 'user' }
    const newFullMessages = [...fullMessagesLog, { text: inputText.trim(), sender: 'user' }]
    const newMessages = [...messages, inputMessage]

    setInputText('') // clear input
    setMessages([...newMessages]) // immediately show message into chat
    setRequestMessage(inputText.trim())
    setIsLoading(true) // Start loading

    // send message to the server
    try {
      const sessionAndRevision = await getSessionAndRevision()
      const chatEndpoint = formatChatEndpoint(sessionAndRevision.sessionNum, sessionAndRevision.revisionNum)
      analytics.track('Chat Message Sent', { type: 'ideation', inputText: inputText.trim() })
      const content = await fetchData({ url: chatEndpoint, data: { messages: [inputMessage] }, method: 'PUT' })
      analytics.track('Chat Message Received', { content })

      setMessages([{ text: initialMessage, sender: 'assistant' }, ...content.items])

      const latestProductDescription = content.items
        .reverse()
        .find(obj => obj.sender === 'description' && !obj.chatNum)?.text
      setProductDescription(latestProductDescription)

      if (latestProductDescription) {
        setImagePrompt(latestProductDescription)
        setFullMessagesLog([...newFullMessages, { text: latestProductDescription, sender: 'assistant' }])
      }

      activateGlow({ [FIRST_SEEN_ELEMENT.DESIGN_INPUTS]: true, [FIRST_SEEN_ELEMENT.GENERATE]: true })
    } catch (error) {
      observability.captureException(`Chat Failure`, {
        error,
        tags: { url: LEO_CHAT_ENDPOINT },
        extra: { messages: newFullMessages },
      })

      analytics.track('Chat Message Failed', { error: error?.message })

      setInputText(inputText.trim()) // restore input value
      setMessages(newMessages.slice(0, -1)) // remove last user's message from chat
      setProductDescription('')

      showAlert(error)
    } finally {
      setIsLoading(false)
      runHandleSubmit.current = true // Reset it to true for the next submission
    }
  }

  const handleTextChange = event => setInputText(event.target.value)

  return (
    <div className="input-container">
      <div className="input-container-padding">
        <TextareaAutosizeChat
          setInputHeight={setInputHeight}
          value={inputText}
          onChange={handleTextChange}
          handleSubmit={handleSubmit}
        />
      </div>
      <div className="input-button-padding">
        <div className="input-button-container">
          <Button {...{ onClick: handleSubmit }} />
          <ToastContainer theme="colored" autoClose={4000} />
        </div>
      </div>
    </div>
  )
}
