
import clsx from 'clsx'
import { SPINNER_COLOR, Spinner } from '../../Spinner'
import { useThemeContext } from '../../../hooks/useThemeContext.jsx'
import styles from './Gallery.module.scss'

export const ImageSkeleton = ({ className }) => {
  const { isDark } = useThemeContext()
  return (
    <div className={clsx(styles.ImageSkeleton, className)}>
      <Spinner {...{ size: '20vh', color: isDark ? SPINNER_COLOR.BRIGHT : SPINNER_COLOR.DARK }} />
    </div>
  )
}
