import React, { useState } from 'react'
import clsx from 'clsx'
import { Box } from '@mui/material'
import TollIcon from '../../../assets/icons/toll.svg?react'
import TriangleIcon from '../../../assets/icons/triangle.svg?react'
import { Button } from '../../../components/common/Button'
import styles from './HoursBalance.module.scss'

export const HoursBalance = () => {
  const STEP = 100
  const [value, setValue] = useState(STEP)
  const balance = 858.2
  const price = 0.1
  const cost = value * price

  const onLessClick = () => {
    if (value <= STEP) return
    setValue(value - STEP)
  }
  const onMoreClick = () => setValue(value + STEP)

  const onPurchase = () => {
    // TODO
  }

  return (
    <Box className={styles.HoursBalance}>
      <div className={styles.mainInfo}>
        <h2>Hours Balance</h2>

        <div className={styles.balanceBlock}>
          <TollIcon width={45} height={25} />
          <span className={styles.balance}>{balance}</span>
        </div>
      </div>

      <div className={styles.form}>
        <div className={styles.contentBlock}>
          <div className={clsx(styles.wrapperBlock, styles.inputWrapper)}>
            <button onClick={onLessClick}>
              <TriangleIcon className={styles.less} />
            </button>
            <input className={styles.input} type="number" step={100} disabled value={value} />
            <button onClick={onMoreClick}>
              <TriangleIcon />
            </button>
          </div>
          <div className={clsx(styles.wrapperBlock, styles.priceWrapper)}>${cost}</div>
        </div>
        <Button className={styles.submitBtn} variant="contained" onClick={onPurchase}>
          Buy
        </Button>
      </div>

      <p className={styles.description}>Buy more credits for fast generation</p>
    </Box>
  )
}
