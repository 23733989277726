import { create } from 'zustand'

export const useGalleryModal = create(set => ({
  imageUrl: null,
  setImageUrl: imageUrl => set({ imageUrl }),
  openModal: false,
  setOpenModal: openModal => set({ openModal }),
  requestMessage: null,
  setRequestMessage: requestMessage => set({ requestMessage }),
  hide: false,
  setHide: hide => set({ hide }),
  imagesData: [],
  setImagesData: imagesData => set({ imagesData }),
  currentImageUrl: '',
  setCurrentImageUrl: currentImageUrl => set({ currentImageUrl }),
}))
