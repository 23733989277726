import React, { useState, useEffect } from 'react'
import styles from './ProjectDocsContainer.module.scss'
// import {  UndoIcon } from '../../../assets/icons/undo-docs.svg?react'
// import {  RedoIcon } from '../../../assets/icons/redo.svg?react'
import { useTechSpecStore } from '../../../store/useTechSpecStore'
import { Button } from '../../common/Button'
import { WindowHeader } from '../../common/WindowHeader'
import { Pagination } from '../EditableTextWindow/Pagination'
import { ExportButton } from '../../CopyAndExportControls/ExportButton'
import { FEATURE_BOM, FEATURE_COST, FEATURE_GANTT, FEATURE_TECH_SPEC } from '../../../lib/config'
import useDocs from '../../../hooks/useDocs'
import TechnicalSpecs from '../TechnicalSpec/TechnicalSpecs'
import { CopyAndExportControls } from '../../CopyAndExportControls'

type DocsContent = {
  name: string
  label: string
  featureFlag: string | boolean
}

type Props = {
  onClose: () => void
}
export const ProjectDocsContainer = (props: Props) => {
  const { specVersions, currentVersionIndex } = useTechSpecStore(store => store)

  const [tab, setTab] = useState<string>('technical-spec')
  const { compileDocumentAndRetrieveArtifacts } = useDocs(tab)

  const [content, setContent] = React.useState<string | undefined>()

  const handleContentChange = (content: string) => {
    setContent(content)
  }

  const docsContent: DocsContent[] = [
    { name: 'technical-spec', label: 'Tech Spec', featureFlag: FEATURE_TECH_SPEC },
    { name: 'bom', label: 'BOM', featureFlag: FEATURE_BOM },
    { name: 'gantt', label: 'Gantt', featureFlag: FEATURE_GANTT },
    { name: 'cost', label: 'Cost', featureFlag: FEATURE_COST },
  ]

  useEffect(() => {
    compileDocumentAndRetrieveArtifacts(currentVersionIndex).then(() => null)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tab])

  return (
    <div className={styles.techSpecContainer}>
      <WindowHeader
        title="Project Docs"
        onMinMaxClick={false}
        displayChildrenWithTitle
        style={{
          borderRight: 'none',
          borderLeft: 'none',
          borderTop: 'none',
          borderRadius: '16px 16px 0 0',
        }}
      >
        {specVersions.length > 1 && <Pagination />}
      </WindowHeader>

      <div className={styles.content}>
        {tab === 'technical-spec' ? (
          <TechnicalSpecs onContentChange={handleContentChange} name={tab} isDialog />
        ) : tab === 'bom' ? (
          <span>bom</span>
        ) : tab === 'gantt' ? (
          <span>gantt</span>
        ) : tab === 'cost' ? (
          <span>cost</span>
        ) : null}
      </div>

      <div className={styles.footer}>
        <div className={styles.tabsWrapper}>
          {docsContent.map(obj =>
            obj.featureFlag ? (
              <Button
                key={obj.name}
                className={tab === obj.name ? styles.tabsButton : styles.unselected}
                onClick={() => setTab(obj.name)}
              >
                {obj.label}
              </Button>
            ) : null
          )}
        </div>

        <div className={styles.actions}>
          {/*<UndoIcon className={styles.icon} />*/}
          {/*<RedoIcon className={styles.icon} />*/}
          {/*<Button className={styles.discardButton}>Discard</Button>*/}
          <Button onClick={props.onClose} className={styles.saveButton}>
            Save
          </Button>
          <CopyAndExportControls hideExportButton text={content} />
          <ExportButton {...{ text: content, isInSpecComp: false }} ThreeDotsIcon />
        </div>
      </div>
    </div>
  )
}
