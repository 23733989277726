
import { Box } from '@mui/material'
import { Button } from '../../../components/common/Button'
import styles from './BillingAndPayments.module.scss'

export const BillingAndPayments = () => {
  const price = `$${10} / mo`
  const period = 'Monthly'
  const date = 'Jul 22, 2023'

  return (
    <Box className={styles.BillingAndPayments}>
      <div className={styles.main}>
        <h2>Billing & Payment</h2>

        <Button variant="outlined" color="secondary" rounded>
          Invoice History
        </Button>
      </div>

      <div className={styles.details}>
        <div className={styles.row}>
          <span className={styles.description}>Price </span>
          <span className={styles.value}>{price}</span>
        </div>
        <div className={styles.row}>
          <span className={styles.description}>Billing period</span>
          <span className={styles.value}>{period}</span>
        </div>
        <div className={styles.row}>
          <span className={styles.description}>Renewal date</span>
          <span className={styles.value}>{date}</span>
        </div>
      </div>
    </Box>
  )
}
