import * as config from '../lib/config'
import { useFetchData } from '../api'
import { useState } from 'react'

export function useShareImageUrl() {
  const { fetchData } = useFetchData()
  const [isLoadingShareImageUrl, setIsLoadingShareImageUrl] = useState(() => false)
  const [shareImageUrl, setShareImageUrl] = useState(() => '')
  const [didFetchShareImageUrl, setDidFetchShareImageUrl] = useState(() => false)

  async function fetchShareImageUrl(url: string): Promise<void> {
    setIsLoadingShareImageUrl(true)

    const result = await fetchData({
      url: `${config.IMAGE_SHARE_ENDPOINT}?url=${url}`,
      method: 'GET',
      responseType: undefined,
      params: {},
      headers: {},
      data: {},
    })

    setShareImageUrl(result.watermarkUrl)
    setIsLoadingShareImageUrl(false)
    setDidFetchShareImageUrl(true)
  }

  return {
    fetchShareImageUrl,
    shareImageUrl,
    isLoadingShareImageUrl,
    didFetchShareImageUrl,
  }
}
