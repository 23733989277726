import Dislike from "@/assets/icons/dislike.svg?react";
import Like from "@/assets/icons/like.svg?react";
import DoubleLike from "@/assets/icons/double-like.svg?react";
import BoldDislike from "@/assets/icons/bold-dislike.svg?react";
import BoldLike from "@/assets/icons/bold-like.svg?react";
import BoldDoubleLike from "@/assets/icons/bold-double-like.svg?react";
import SimpleToolbar from "@/components/simpleToolbar/SimpleToolbar";
import * as analytics from "../../../lib/analytics.ts";

const Ids = {
  Like: "like",
  Dislike: "dislike",
  DoubleLike: "double-like",
  Copy: "copy",
};

const Items = [
  {
    id: Ids.Dislike,
    tooltip: "Bad answer",
    component: () => <Dislike />,
    boldComponent: () => <BoldDislike />,
  },
  {
    id: Ids.Like,
    tooltip: "Good answer",
    component: () => <Like />,
    boldComponent: () => <BoldLike />,
  },
  {
    id: Ids.DoubleLike,
    tooltip: "Awesome answer",
    component: () => <DoubleLike />,
    boldComponent: () => <BoldDoubleLike />,
  },
];

export type Props = {
  messageText: string;
  displayFeedbackOption: (id: string) => void;
};

export default function MessageToolbar(_props: Props) {
  function handleItemClick(id: string) {
    const { messageText, displayFeedbackOption } = _props;
    displayFeedbackOption(id);
    analytics.track("Message Sentiment Changed", {
      type: id,
      messageText,
    });
  }

  return <SimpleToolbar items={Items} onClick={(e) => handleItemClick(e.id)} />;
}
