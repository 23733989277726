import { useState } from 'react'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import 'react-toastify/dist/ReactToastify.css'
import SessionCard from '../SessionCard/sessionCard'
import SessionsPagination from '../SessionsPagination/sessionsPagination'
import { SessionsWrapper, Wrapper, LoadingWrapper } from './sessionsPageStyle'
import useLoadSessionData, { SessionMetadata } from '@/src-ideation/hooks/sessions/useLoadSessionData'
import loadingGif from '@/src-ideation/assets/icons/Logo_animation_Bright.gif'
import useGetSessionsMetadataList from '@/src-ideation/hooks/sessions/useGetSessionsMetadataList'
import { GoToChatScreen } from '@/src-ideation/components/GoToChatScreen/GoToChatScreen'
import useScreenSize from '@/components/useScreenSize/useScreenSize.ts'

export default function SessionsPage() {
  const [page, setPage] = useState<number>(1)
  const { getAndLoadSessionData } = useLoadSessionData()
  const sessionsListResponse = useGetSessionsMetadataList()
  const screenSize = useScreenSize()

  if (sessionsListResponse.isLoading) {
    return (
      <LoadingWrapper>
        <img src={loadingGif} alt="Loading..." />
      </LoadingWrapper>
    )
  }

  if (sessionsListResponse.isError) {
    return <ToastContainer theme="colored" autoClose={4000} />
  }

  const historicalSessions = sessionsListResponse?.data || []

  const sessionsPerPage = 6
  const pagesNumbersAmount = 4
  const pad = 1

  const numOfPages = Math.ceil(historicalSessions.length / sessionsPerPage)

  const indexOfLast = page * sessionsPerPage
  const indexOfFirst = indexOfLast - sessionsPerPage
  const currentSessionsList = historicalSessions.slice(indexOfFirst, indexOfLast)

  const changePage = (num: number, arrowNavigation?: boolean) => {
    if (arrowNavigation) {
      setPage(currentPage => currentPage + num)
    } else {
      setPage(num)
    }
  }

  const isMobile = screenSize.width < 800

  if (isMobile) {
    return <GoToChatScreen />
  }

  return (
    <Wrapper>
      <SessionsWrapper>
        {currentSessionsList.map((sessionInfo: SessionMetadata, index: number) => (
          <SessionCard
            key={sessionInfo.thumbnailUrl + index}
            headline={sessionInfo.headline}
            createdAt={sessionInfo.createdAt}
            sessionNum={sessionInfo.sessionNum}
            thumbnailUrl={sessionInfo.thumbnailUrl}
            onCardClick={() => {
              getAndLoadSessionData(sessionInfo.sessionNum)
            }}
          />
        ))}
      </SessionsWrapper>

      <SessionsPagination
        numOfPages={numOfPages}
        setPage={changePage}
        currentPage={page}
        pagesNumbersAmount={pagesNumbersAmount}
        pad={pad}
      />
    </Wrapper>
  )
}
