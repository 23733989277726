/**
 * This is a bad file - it was copy pasted from the ideation.
 * 
 * It lacks context, all such constant needs to be defined where they are used
 * to follow locality of behaviour.
 * 
 * For example, have a file that defines a "feedback" hook and put these constants there.
 * 
 * Currently those constants are used as is and it is not obvious/maintainable.
 */
export const doubleLikeTitles: string[] = [
  "Boom!🤩 Looks like I'm onto something. What specifically caught your eye?",
  "Bam!🚀 Double like! Feeling pumped. What exactly got you excited?",
  "Nailed it!👊 Double like from you means a lot. What stood out the most?",
  "Score!💥 Double like! Looks like I hit the bullseye. What made you so happy?",
  "Yes!🤩 Feeling motivated. What part did you find most impressive?",
];

export const likeTitles: string[] = [
  "Nice!🙂 Happy to hear that. What specifically did you like?",
  "Cool!🙂 Glad you enjoyed it. What part stood out to you",
  "Great!🙂 What specifically did you find appealing? your expectations?",
  "Fantastic!🙂 What made it stand out for you?",
  "Awesome!🙂 What aspect resonated with you?",
];
export const dislikeTitles: string[] = [
  "Oh no,😢 sorry to hear that. Back to the drawing board. What didn't work for you?",
  "That's a bummer.😢 Time to regroup. What didn't you like?",
  "Darn, sorry about that.😢 What specifically didn't meet your expectations?",
  "I'm sorry to hear that.😢 Let's make it right. What can I do to improve?",
  "Oh man, my bad.😢 Let's fix this. What went wrong, and how can I do better?",
];

export const likeOptions: string[] = [
  "Correct",
  "Easy to understand",
  "Complete",
  "Other",
];

export const dislikeOptions: string[] = [
  "Didn't give me an answer",
  "Not factually correct",
  "Too short",
  "Did not fully follow instructions",
  "Other",
];
