import { create } from 'zustand'
import { initialMessage } from '../constants/msgEnums'
// import { useAudit } from './useAudit'

export const useIdeationChat = create(set => ({
  creativenessControllerValue: 0.5,
  setCreativenessControllerValue: value => set({ creativenessControllerValue: value }),

  promptVersions: [],
  setPromptVersions: promptVersions => set({ promptVersions }),

  fullMessagesLog: [],
  setFullMessagesLog: fullMessagesLog => set({ fullMessagesLog }),

  lastGenFullMassageLog: [],
  setLastGenFullMassageLog: lastGenFullMassageLog => set({ lastGenFullMassageLog }),

  isGenerated: false,
  setIsGenerated: isGenerated => set({ isGenerated }),

  messages: [{ text: initialMessage, sender: 'assistant' }],
  setMessages: messages => set({ messages }),

  inputText: '',
  setInputText: inputText => set({ inputText }),

  productDescription: '',
  setProductDescription: productDescription => set({ productDescription }),

  ideationProductDescriptionFromCad: '',
  setIdeationProductDescriptionFromCad: ideationProductDescriptionFromCad => set({ ideationProductDescriptionFromCad }),

  isLoading: false,
  setIsLoading: isLoading => set({ isLoading }),
}))

// useChat.subscribe((state, prevState) => {
//   const audit = useAudit.getState()
//   let pendingMessages = []
//
//   // Chat entries
//   if (audit.entries.length === 0 && state.messages.length > 0) {
//     // Captures initial state
//     pendingMessages = state.messages
//   } else {
//     // Captures added messages
//     const prevCount = prevState.messages.length
//     pendingMessages = state.messages.slice(prevCount, state.messages.length)
//   }
//
//   audit.appendEntries(
//     pendingMessages.map(message => ({
//       tag: 'chat',
//       message,
//     }))
//   )
//
//   // Updated controller
//   if (state.creativenessControllerValue !== prevState.creativenessControllerValue) {
//     audit.insertOrReplaceIfSameTag({
//       tag: 'creativeness-controller-value',
//       value: state.creativenessControllerValue,
//     })
//   }
//
//   // TODO: handle product description update
// })
