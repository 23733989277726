import { create } from 'zustand'
import { useAudit } from './useAudit'

export const useGalleryStore = create(set => ({
  loadingImages: false,
  variantImage: null,
  variantPayload: null,
  images: [],
  setImages: images => set({ images }),
  responses: [],
  likeDislikeImages: [],
  setLoadingImages: boolean => set(() => ({ loadingImages: boolean })),
  setVariantImage: variantImage => set({ variantImage }),
  setVariantPayload: variantPayload => set({ variantPayload }),
  storeResponse: ({ upscale }) => {
    const newImages = upscale.map(({ uri }) => uri)
    const newLikeDislikeImages = upscale.map(({ uri }) => ({ uri, liked: false, disliked: false }))
    return set(store => ({
      responses: [...store.responses, { upscale }],
      images: [...newImages, ...store.images],
      likeDislikeImages: [...newLikeDislikeImages, ...store.likeDislikeImages],
    }))
  },
  removeImage: image => set(store => ({ images: store.images.filter(i => i !== image) })),
  setLikeImage: image =>
    set(store => {
      const index = store.likeDislikeImages.findIndex(i => i.uri === image)

      const newLikeDislikeImages = [...store.likeDislikeImages]
      newLikeDislikeImages[index] = {
        ...newLikeDislikeImages[index],
        liked: !newLikeDislikeImages[index].liked,
        disliked: false,
      }
      return { likeDislikeImages: newLikeDislikeImages }
    }),
  setDislikeImage: image =>
    set(store => {
      const index = store.likeDislikeImages.findIndex(i => i.uri === image)
      const newLikeDislikeImages = [...store.likeDislikeImages]
      newLikeDislikeImages[index] = {
        ...newLikeDislikeImages[index],
        liked: false,
        disliked: !newLikeDislikeImages[index].disliked,
      }
      return { likeDislikeImages: newLikeDislikeImages }
    }),
}))

useGalleryStore.subscribe((state, prevState) => {
  const audit = useAudit.getState()

  const prevCount = prevState.responses.length
  const pending = state.responses.slice(prevCount, state.responses.length)

  // TODO: handle product description update

  audit.appendEntries(
    pending.map(response => ({
      tag: 'generated-image',
      upscaleUrls: response && response.upscale.map(upscale => upscale.uri),
    }))
  )
})
