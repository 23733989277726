// Inspired by
// https://github.com/nygardk/react-share

// https://github.com/nygardk/react-share/blob/master/src/FacebookShareButton.ts
export interface FacebookProps {
  url: string
  quote?: string
  hashtag?: string
}

// https://github.com/nygardk/react-share/blob/master/src/LinkedinShareButton.ts
export interface LinkedInProps {
  url: string
  title?: string
  summary?: string
  source?: string
}

export interface TwitterProps {
  url: string
  text?: string
  via?: string
  hashtags?: string[]
  related?: string[]
}

export interface RedditProps {
  url: string
  title?: string
}

/* eslint-disable  @typescript-eslint/no-explicit-any */
function urlSearchParams(object: any): URLSearchParams {
  const ans = new URLSearchParams()

  Object.entries(object).forEach(([key, value]) => {
    if (value !== undefined && value !== null) {
      ans.append(key, value as string)
    }
  })

  return ans
}

export const linkedin = (props: LinkedInProps) => `https://linkedin.com/shareArticle?${urlSearchParams(props)}`
export const facebook = ({ url, hashtag, quote }: FacebookProps) =>
  `https://www.facebook.com/sharer/sharer.php?${urlSearchParams({
    u: url,
    hashtag,
    quote,
  })})}`
export const twitter = (props: TwitterProps) => `https://twitter.com/share?${urlSearchParams(props)}`
export const reddit = (props: LinkedInProps) => `https://www.reddit.com/submit?${urlSearchParams(props)}`
