import styled from "styled-components";

interface Props {
  displayHistory: boolean;
  displayChat: boolean;
}

export const ComponentWrapper = styled.div<Props>`
  background-color: var(--dark-100);
  padding: ${(props) => (props.displayHistory ? "4rem" : 0)};
  width: ${(props) =>
    !props.displayHistory ? 0 : props.displayChat ? "28rem" : "100%"};
  transition: all 0.4s ease;
  position: ${(props) => (!props.displayChat ? "absolute" : "static")};
  z-index: 999;
`;

export const ListWrapper = styled.div`
  height: 70vh;
  overflow: auto;
`;

export const SessionsTime = styled.div`
  font-size: 0.7rem;
  color: #ffffff;
`;

export const CloseButton = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 2rem;
  cursor: pointer;
  overflow: auto;
`;
export const NewSessionButton = styled.div`
  overflow: auto;
`;
