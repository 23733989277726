import React, { useState } from 'react'
import clsx from 'clsx'
import { useDesignInputs } from '../../../hooks/useDesignInputs'
import { useQueue } from '../../../hooks/useQueue'
import { WindowHeader } from '../../common/WindowHeader'
import { Canvas } from './Canvas'
import { Controller } from './Controller'
import { VariantImage } from './VariantImage'
import { EmptyCanvas } from './Canvas/EmptyCanvas'
import styles from './DesignInputs.module.scss'

const DesignInputsContent = ({ canvasOpen, setCanvasOpen }) => {
  const { imageSrc } = useDesignInputs()
  const { queue } = useQueue()
  const isEmptyCanvasVisible = queue.length === 0 && !canvasOpen

  return (
    <>
      {isEmptyCanvasVisible ? (
        <EmptyCanvas {...{ canvasOpen, handleOpenCanvas: () => setCanvasOpen(true) }} />
      ) : imageSrc ? (
        <VariantImage />
      ) : (
        <Canvas {...{ canvasOpen, setCanvasOpen }} />
      )}
    </>
  )
}

export const DesignInputs = () => {
  const [canvasOpen, setCanvasOpen] = useState(false)

  return (
    <>
      <WindowHeader title="Design Inputs" />
      <div className={clsx(styles.canvasContainer, styles.dark)}>
        <div id="designCanvas" className={styles.canvasWrapper}>
          <DesignInputsContent {...{ canvasOpen, setCanvasOpen }} />
        </div>
        <Controller />
      </div>
    </>
  )
}
