import React, { ReactNode, useContext, useState } from 'react'
import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import rehypeMathjax from 'rehype-mathjax'
import remarkMath from 'remark-math'
// import rehypeKatex from "rehype-katex";

import {
  AssistantMessage,
  GoToIdeationWrapper,
  ImageDescriptionBanner,
  ImageDescriptionMessage,
  MessageWrapper,
  ReadMoreButton,
  ReadMoreTitle,
  StyledLink,
  TextExpansion,
  ToolbarWrapper,
  TooltipContent,
  UserMessage,
} from './MessageStyle.ts'
import MessageToolbar from './MessageToolbar.tsx'
import {Message as MessageType, useChat, WebCitation} from '@/store/useChat.ts'
import { initialMessage } from '@/constants/msgEnums.ts'
import { FeatureFlagsContext } from '@/lib/featureFlags.ts'
import { CopyToClipboardButton } from '@/components/CopyToClipboardButton/CopyToClipboardButton.tsx'
import { Tooltip } from '@mui/material'
import SentimentsFeedback from '@/components/chatSection/sentimentsFeedback/SentimentsFeedback.tsx'
import { dislikeOptions, dislikeTitles, doubleLikeTitles, likeOptions, likeTitles } from '@/constants/feedbackText.ts'
import * as analytics from '@/lib/analytics.ts'
import { fileNames } from '@/constants/sourceFileNames.ts'
import { ROUTE } from '@/router/Router.tsx'
import { NavLink, useNavigate } from 'react-router-dom'
import { useIdeationChat } from '@/src-ideation/store/useChat.js'
import {useSessionStore} from "@/src-ideation/store/useSessionStore.ts";
import {useGalleryStore} from "@/src-ideation/store/useGalleryStore";
import {useGalleryModal} from "@/src-ideation/store/useGalleryModal";
import {useTechSpecStore} from "@/src-ideation/store/useTechSpecStore";
import useDocsStore from "@/src-ideation/store/useDocsStore.ts";
// import { FIRST_SEEN_ELEMENT } from '@/src-ideation/hooks/useGlowing.js'
// import * as observability from '@/src-ideation/lib/observability.ts'
// import { LEO_CHAT_ENDPOINT } from '@/src-ideation/lib/config.ts'

interface MessageProps {
  message: MessageType
  addMessages: (newMessages: MessageType[]) => void
  addFullMessages: (newMessages: MessageType[]) => void
  setImagePrompt: (text: string) => void
  isLastMessage: boolean
}
interface LinkRendererProps {
  children?: ReactNode
  href?: string
}
interface MarkdownRendererProps {
  children: string
}
export const Message: React.FC<MessageProps> = ({ message, isLastMessage }) => {
  const chat = useChat()
  const navigate = useNavigate()

  const isPreDefinedMessage = message.text?.includes('Got it')

  const { setMessages, setFullMessagesLog, setPromptVersions, setProductDescription, setInputText, ideationProductDescriptionFromCad, inputText } = useIdeationChat(
    store => store
  )
  const { setSessionNum, setRevisionNum, setIsGeneratedAlready } = useSessionStore(store => store)
  const { setImages, storeResponse, setLoadingImages } = useGalleryStore(store => store)
  const { setImagesData } = useGalleryModal()
  const { setRevisionsAmount, setCurrentVersionIndex, setImagesContent } = useTechSpecStore(store => store)

  const docsStore = () => useDocsStore.getState()

  const moveAnswerToTop = (text: string) => {
    const regex = /<answer>([\s\S]*?)<\/answer>/i
    const match = regex.exec(text)

    if (match) {
      const answerContent = match[1].trim()
      const newText = text.replace(regex, '').trim()
      return answerContent + '\n\nDetailed answer:\n\n' + newText
    } else {
      return text
    }
  }
  const textForCopy = () => {
    let copyText = message.text
    const refObj = message.references ? message.references.citations : {}

    const regex = /\[([^[\]]+)\]\(([^()]+)\)/g

    const matches = copyText.matchAll(regex)

    let references: string[] | string = []

    for (const match of matches) {
      const data = match[1].split('_separator')
      const citationNumber = data[0]
      const fileName = fileNames[data[1]] || data[1]
      const pageNumber = data[2] || 1
      const link = match[2]

      const isLinkWeb = ():boolean=>{
        for (const key in refObj) {
          if (refObj[key].type === 'web' && 'url' in refObj[key] && (refObj[key] as WebCitation).url === link) {
            return true
          }
        }
        return false
      }

      const textToReplace = `[${citationNumber}]`
      const referenceOfText = isLinkWeb() ? `
      [${citationNumber}] ${link}` : `[${citationNumber}] ${fileName}, p.${pageNumber}`

      copyText = copyText.replace(match[0], textToReplace)
      if (!references.includes(referenceOfText)) {
        references.push(referenceOfText)
      }
    }

    copyText = moveAnswerToTop(copyText)

    references = references
      .sort((a, b) => {
        const numA = parseInt(a.trim().charAt(1))
        const numB = parseInt(b.trim().charAt(1))

        return numA - numB
      })
      .join('\n')

    return copyText + '\n' + references
  }

  const copyText = textForCopy()

  let displayReadMore = false

  let answerText = message.text

  let longText = ''

  const IdeationButtonText = 'Click here to go to ideation mode'

  if (message.text.includes('<answer>') && message.text.includes('</answer>')) {
    const answerRegex = /<answer>(.*?)<\/answer>/s
    const match = message.text.match(answerRegex)
    answerText = match ? match[1] : message.text
    longText = message.text.split('<answer>')[0]
    displayReadMore = message.sender === 'assistant'
  }

  const featureFlags = useContext(FeatureFlagsContext)

  const [toolbarHover, setToolbarHover] = useState(false)

  const [showFullText, setShowFullText] = useState(false)

  const [sentimentsFeedbackOptions, setSentimentsFeedbackOptions] = useState<string | boolean>(false)

  const toggleShowFullText = () => {
    if (!showFullText) {
      analytics.track('Read More Pressed', {})
    }

    setShowFullText(!showFullText)
  }

  const MessageComponent =
    message.sender === 'user'
      ? UserMessage
      : ['assistant', 'virtual'].includes(message.sender)
        ? AssistantMessage
        : ImageDescriptionMessage

  const isLastMessageWhileLoading = isLastMessage && chat.isLoading

  const showToolbar =
    !isLastMessageWhileLoading &&
    (toolbarHover || isLastMessage) &&
    featureFlags.messageSentiments &&
    message.sender === 'assistant' &&
    message.text !== initialMessage

  function LinkRenderer(props: LinkRendererProps) {
    const text = props.children || ''
    let indexAndLink: any = ''
    if (typeof text === 'string') {
      indexAndLink = text.split('_separator')
    }

    const index = indexAndLink[0]
    const link = fileNames[indexAndLink[1]] || indexAndLink[1]
    const page = indexAndLink[2] || 1

    const onLinkClick = () => {
      analytics.track('Citation Link Pressed', {
        url: props.href,
        link,
      })
    }

    return (
      <Tooltip
        title={
          <TooltipContent>
            <a onClick={onLinkClick} href={props.href} target="_blank" rel="noreferrer">
              {link} , p.{page}
            </a>
          </TooltipContent>
        }
        placement="top"
      >
        <StyledLink onClick={onLinkClick} href={props.href} target="_blank" rel="noreferrer">
          {index}
        </StyledLink>
      </Tooltip>
    )
  }

  const newIdeationSession = () => {
    setSessionNum(undefined)
    setRevisionNum(undefined)
    setIsGeneratedAlready(false)

    setMessages([{ text: initialMessage, sender: 'assistant' }])
    setFullMessagesLog([])
    setPromptVersions([])
    setProductDescription('')

    setImages([])

    setImagesData([])
    storeResponse({ 'upscale': [] })
    setLoadingImages(false)

    setRevisionsAmount(0)
    setCurrentVersionIndex(0)
    setImagesContent([])
    docsStore().resetDoc()
  }

  const handleClick = async (event: any) => {
    event.preventDefault()

    newIdeationSession()

    if (featureFlags.updateUserInputFromCadToIdeation && ideationProductDescriptionFromCad !== inputText) {
      await setInputText(ideationProductDescriptionFromCad)
      await setProductDescription(ideationProductDescriptionFromCad)
    }

    navigate(ROUTE.IDEATION)
  }

  const MarkdownRenderer = ({ children }: MarkdownRendererProps) => {
    return (
      <ReactMarkdown
        remarkPlugins={[remarkGfm, remarkMath]}
        rehypePlugins={[rehypeMathjax]}
        // rehypePlugins={[rehypeKatex, rehypeMathjax]}
        components={{
          a: LinkRenderer,
        }}
      >
        {children}
      </ReactMarkdown>
    )
  }

  const options =
    sentimentsFeedbackOptions === 'like' || sentimentsFeedbackOptions === 'double-like' ? likeOptions : dislikeOptions

  const titles =
    sentimentsFeedbackOptions === 'double-like'
      ? doubleLikeTitles
      : sentimentsFeedbackOptions === 'like'
        ? likeTitles
        : dislikeTitles

  const sentimentTitle = titles[Math.floor(Math.random() * titles.length)]

  return (
    <MessageComponent>
      <MessageWrapper
        onMouseEnter={() => !sentimentsFeedbackOptions && setToolbarHover(true)}
        onMouseLeave={() => !sentimentsFeedbackOptions && setToolbarHover(false)}
      >
        {message.sender === 'description' ? (
          <ImageDescriptionBanner>
            <h2 style={{ marginBlock: '0' }}>Product Description</h2>
          </ImageDescriptionBanner>
        ) : (
          ''
        )}

        {answerText.includes(IdeationButtonText) ? (
          <GoToIdeationWrapper>
            <span>Let's brainstorm more about the design concept!</span>

            <NavLink to={ROUTE.IDEATION} onClick={handleClick}>
              <span>Click to start</span>
            </NavLink>
          </GoToIdeationWrapper>
        ) : (
          <MarkdownRenderer>{answerText}</MarkdownRenderer>
        )}

        <TextExpansion showText={showFullText}>
          <ReadMoreTitle>Detailed answer</ReadMoreTitle>
          <MarkdownRenderer>{longText}</MarkdownRenderer>
        </TextExpansion>

        {displayReadMore && (
          <ReadMoreButton onClick={toggleShowFullText}>{showFullText ? 'Read less' : 'Read more'}</ReadMoreButton>
        )}

        {!isPreDefinedMessage && (
          <ToolbarWrapper showToolbar={showToolbar}>
            <MessageToolbar displayFeedbackOption={id => setSentimentsFeedbackOptions(id)} messageText={message.text} />
            <CopyToClipboardButton plainText={copyText} />
          </ToolbarWrapper>
        )}

        {sentimentsFeedbackOptions && (
          <SentimentsFeedback
            onClose={() => setSentimentsFeedbackOptions(false)}
            sentimentsFeedbackType={sentimentsFeedbackOptions}
            options={options}
            sentimentTitle={sentimentTitle}
          />
        )}
      </MessageWrapper>
    </MessageComponent>
  )
}
