import {useUser} from "@descope/react-sdk";
import {useEffect, useState} from "react";

export const useAvatar = () => {
    const { user } = useUser()
    const [imageSrc, setImageSrc] = useState('')

    useEffect(() => {
        async function updateImageSrc() {
            if (user) {
                const id = user.name || user.email || 'Leo'
                const { url } = await fetch(`https://ui-avatars.com/api/?name=${id}&background=aaaaff&rounded=true`)
                setImageSrc(url)
            }
        }

        if (user && !imageSrc) {
            updateImageSrc()
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user])

    return {
        imageSrc: (user || {}).picture || imageSrc,
    }
}
