import styles from './FeedbackModal.module.scss'
import { Checkbox, FormControlLabel, FormGroup } from '@mui/material'
import { Button } from '@/src-ideation/components/common/Button'
import React, { useEffect, useRef, useState } from 'react'
import { Stars } from '@/src-ideation/components/Footer/components/Stars'
import * as analytics from '@/src-ideation/lib/analytics'
import  CloseButton  from '@/src-ideation/assets/icons/x-button.svg?react'

export const FeedbackModal = ({ setModalIsOpen }) => {
  const [currentLeoVersion, setCurrentLeoVersion] = useState(0)
  const [needAnAI, setNeedAnAI] = useState(0)
  const [improvingTheTechnicalSpecificationOutputs, setTechnicalSpecificationOutputs] = useState(false)
  const [improvingThe2DConceptOutputs, setConceptOutputs] = useState(false)
  const [adding2DConceptsEditingAbilities, setConceptsEditing] = useState(false)
  const [transformingA2DConceptIntoA3DAsset, setFrom2Dto3D] = useState(false)
  const [isOther, setIsOther] = useState(false)
  const [otherText, setOtherText] = useState('')
  const textareaRef = useRef(null)
  const submitRef = useRef(null)

  // transformingA2DConceptIntoA3DAsset

  const data = {
    currentLeoVersion,
    needAnAI,
    improvingTheTechnicalSpecificationOutputs,
    improvingThe2DConceptOutputs,
    adding2DConceptsEditingAbilities,
    transformingA2DConceptIntoA3DAsset,
    other: isOther ? otherText : '',
  }

  const onClick = () => {
    setModalIsOpen(false)
    analytics.track('Feedback Submitted', data)
  }

  const feedBackCheckboxQuestions = [
    {
      value: 'Improving the technical specification outputs',
      startValue: improvingTheTechnicalSpecificationOutputs,
      method: () => {
        setTechnicalSpecificationOutputs(!improvingTheTechnicalSpecificationOutputs)
      },
    },
    {
      value: 'Improving the 2D concept outputs',
      startValue: improvingThe2DConceptOutputs,
      method: () => {
        setConceptOutputs(!improvingThe2DConceptOutputs)
      },
    },
    {
      value: 'Adding 2D concepts editing abilities',
      startValue: adding2DConceptsEditingAbilities,
      method: () => {
        setConceptsEditing(!adding2DConceptsEditingAbilities)
      },
    },
    {
      value: 'Transforming a 2D concept into a 3D asset',
      startValue: transformingA2DConceptIntoA3DAsset,
      method: () => {
        setFrom2Dto3D(!transformingA2DConceptIntoA3DAsset)
      },
    },
    {
      value: 'Other - please describe',
      startValue: isOther,
      method: () => {
        toggleFocus()
      },
    },
  ]

  const toggleFocus = () => {
    setIsOther(prevState => !prevState)
  }

  useEffect(() => {
    if (isOther) {
      textareaRef.current.focus()
    } else {
      textareaRef.current.blur()
    }
  }, [isOther])

  return (
    <div className={styles.feedbackWrap}>
      <div className={styles.feedbackHeader}>
        <h4 className={styles.feedbackTitle}>A Quick Feedback?</h4>
        <CloseButton onClick={() => setModalIsOpen(false)} className={styles.closeButton} />
      </div>
      <div className={styles.feedbackRow}>
        <div className={styles.feedbackLeft}>
          <p>What specific features would you like to see improved/added</p>
          <FormGroup>
            {feedBackCheckboxQuestions.map(({ value, method, startValue }) => {
              return (
                <FormControlLabel
                  className={styles.checkboxLabel}
                  key={value.replaceAll(' ', '')}
                  label={value}
                  control={<MyCustomCheckbox startValue={startValue} onClick={method} />}
                />
              )
            })}
          </FormGroup>
        </div>
        <div className={styles.feedbackRight}>
          <div>
            <p>How much did you like the current Leo version?</p>
            <Stars
              rating={currentLeoVersion}
              setRating={num => {
                setCurrentLeoVersion(num)
              }}
            />
            <p>How much do you need an AI ideation copilot like Leo?</p>
            <Stars
              rating={needAnAI}
              setRating={num => {
                setNeedAnAI(num)
              }}
            />
          </div>
        </div>
        <div className={styles.feedbackBottom}>
          <textarea
            value={isOther ? otherText : ''}
            ref={textareaRef}
            onFocus={() => setIsOther(true)}
            onChange={e => setOtherText(e.target.value)}
          />
          <div ref={submitRef} className={styles.ButtonWrap}>
            <Button className={styles.button} variant="contained" color="primary" size="large" onClick={onClick}>
              <span
                style={{
                  padding: '1vh 0.4vh',
                  fontSize: '20px',
                  fontWeight: '700',
                  display: 'block',
                }}
              >
                Submit
              </span>
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}

const checkboxStyles = {
  root: {
    color: 'rgb(116, 147, 255)', // Change the color to your preference
    '&$checked': {
      color: 'rgb(116, 147, 255)', // Change the checked color to your preference
    },
  },
  checked: {},
}

function MyCustomCheckbox({ startValue, onClick }) {
  const handleChange = () => {
    onClick()
  }

  return (
    <Checkbox
      icon={<span className={styles.checkbox} />}
      checkedIcon={<span className={styles.checkboxChacked} />}
      style={checkboxStyles.root}
      iconStyle={checkboxStyles.root}
      checkedIconStyle={checkboxStyles.checked}
      checked={startValue}
      onChange={handleChange}
      inputProps={{ 'aria-label': 'custom checkbox' }}
    />
  )
}
