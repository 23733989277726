import { useFetchData } from '../api'
import { TALK_TO_US } from '../lib/config'
import { toast } from 'react-toastify'
import { useUser } from '@descope/react-sdk'

export function useUserTalkToUs() {
  const { fetchData } = useFetchData()

  const { user } = useUser()

  async function postUserTalkToUs(message: string): Promise<void> {
    return await fetchData({
      url: TALK_TO_US,
      method: 'POST',
      data: {
        data: {
          textMessage: message,
          email: user.email,
          firstName: user.name?.split(' ')[0],
          lastName: user.name?.split(' ')[1],
        },
      },
    }).catch(error => {
      toast.error(error.message)
    })
  }

  return {
    postUserTalkToUs,
  }
}
