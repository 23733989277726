import { useLocation } from 'react-router-dom'
import { useTechSpecStore } from '../../../../store/useTechSpecStore'
import { ROUTE } from '@/router/Router'
import React, { useEffect } from 'react'
import styles from '../Gallery.module.scss'
import clsx from 'clsx'
import CenterBackgroundIconCream from '../../../../assets/icons/icon-cream.svg?react'
import GridBackgroundDark from '../../../../assets/icons/grid-background-dark.svg?react'
import { Img } from './Img'

export const GalleryGrid = ({ isEmpty, images }) => {
  const location = useLocation()
  const { isDesignWindowExpanded } = useTechSpecStore(store => store)

  const isGalleryPage = location.pathname === ROUTE.GALLERY

  /** this will stop the saving image by right click */
  const disableRightClick = event => {
    const target = event.target
    if (target.tagName === 'IMG' && target.closest('.pswp__img')) {
      event.preventDefault()
    }
  }

  useEffect(() => {
    document.addEventListener('contextmenu', disableRightClick)
    return () => {
      document.removeEventListener('contextmenu', disableRightClick)
    }
  }, [])

  if (isEmpty) {
    return (
      <div className={styles.GalleryEmpty}>
        <div className={styles.gridBlock}>
          {/* OR GridBackgroundWhite for light theme */}
          <GridBackgroundDark />
        </div>
        <div className={styles.iconBlock}>
          {/* OR CenterBackgroundIconGray for light theme */}
          <CenterBackgroundIconCream />
        </div>
      </div>
    )
  }

  const skeletonClass = images.length === 3 && isDesignWindowExpanded && styles.expanded

  return (
    <div className={clsx(styles.Gallery, isGalleryPage && styles.galleryPage)}>
      <div className={styles.galleryLayout}>
        {images.map((url, index) => {
          return <Img key={index} url={url} index={index} skeletonClass={skeletonClass} />
        })}
      </div>
    </div>
  )
}
