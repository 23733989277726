
import Modal from 'react-modal'
import CloseButton from '@/src-ideation/assets/icons/x-button.svg?react'
import './LeoProModal.scss'
import { useGalleryModal } from '../../store/useGalleryModal'
import * as analytics from '../../lib/analytics'

Modal.setAppElement('#root') // replace #root with the id of your app's root element if it's different

const imgModelStyles = isHide => {
  return {
    overlay: {
      opacity: isHide ? 0 : 1,
      backgroundColor: 'rgba(28, 21, 36, 0.97)',
      width: '100%',
      height: '100%',
      top: '0',
      left: '50%',
      transform: 'translateX(-50%)',
    },
    content: {
      maxHeight: '90vh',
    },
  }
}

const defaultStyles = {
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.6)',
    width: '100%',
    height: '100%',
  },
  content: {},
}

export const LeoProModal = ({
  children,
  modalIsOpen,
  type,
  setModalIsOpen,
  title = '',
  hideCloseButton = false,
  analyticsMessage = '',
}) => {
  const { hide } = useGalleryModal(store => store)

  const onClose = () => {
    setModalIsOpen(false)
    if (analyticsMessage) analytics.track(analyticsMessage)
  }

  return (
    <Modal
      style={type === 'imgModal' ? imgModelStyles(hide) : defaultStyles}
      isOpen={modalIsOpen}
      onRequestClose={() => setModalIsOpen(false)}
      className={`leo-pro-modal-content${type ? ` ${type}` : ''}`}
    >
      {hideCloseButton ? null : <CloseButton onClick={onClose} className={`close-button`} />}
      <h2>{title}</h2>
      {children}
    </Modal>
  )
}
