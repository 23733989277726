import React, { useEffect, useState } from 'react'
import { useGalleryStore } from '../../../store/useGalleryStore'
import { GalleryGrid } from './GellaryComponent/GalleryGrid'
import { GalleryModal } from './GalleryModal'

const GALLERY_STATE = {
  EMPTY: 'EMPTY',
  LOADING: 'LOADING',
  FULL: 'FULL',
}

const LOADING_ARRAY = ['', '', '']

export const Gallery = ({ onGenerate }) => {
  const { images, loadingImages } = useGalleryStore(store => store)

  const [state, setState] = useState(GALLERY_STATE.EMPTY)
  const [imagesToShow, setImagesToShow] = useState([])

  useEffect(() => {
    if (loadingImages) {
      setState(GALLERY_STATE.LOADING)
      setImagesToShow([...LOADING_ARRAY, ...imagesToShow])
    } else if (!!images.length) {
      setState(GALLERY_STATE.FULL)
      setImagesToShow(images)
    } else {
      setState(GALLERY_STATE.EMPTY)
      setImagesToShow([])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [images, loadingImages])

  return (
    <>
      <GalleryGrid {...{ isEmpty: state === GALLERY_STATE.EMPTY, images: imagesToShow }} />
      <GalleryModal {...{ onGenerate }} />
    </>
  )
}
