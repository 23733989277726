
import { useEffect, useRef } from 'react'
import './TextareaAutosize.css'

const TextareaAutosize = props => {
  const textareaRef = useRef(null)

  useEffect(() => {
    textareaRef.current.style.height = 'auto'
    const scrollHeight = textareaRef.current.scrollHeight
    textareaRef.current.style.height = `${scrollHeight}px`
  }, [])

  return (
    <textarea
      placeholder="Type here"
      className="input-field"
      ref={textareaRef}
      rows={1}
      {...props}
      style={{
        // overflow: 'auto',
        ...props.style,
      }}
      autoFocus
    />
  )
}

export default TextareaAutosize
