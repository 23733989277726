import styled from "styled-components";

export const InputContainer = styled.div`
  border-radius: var(--leo-radius);
  border: 1px solid #231c2b80;
  background-color: var(--leo-white);
  display: flex;
  align-items: center;
  //max-height: 30vh;

  &-padding {
    display: flex;
    flex-direction: row;
    overflow-y: hidden;
    box-sizing: border-box;
    width: 100%;
    padding-left: 1rem;
    justify-content: center;
    align-items: center;

    .input-field {
      background-color: var(--leo-white);
      overflow-y: scroll;
      width: 100%;
      resize: none;
      border: none;
      outline: none;
      min-height: 2rem;
    }
  }
`;
export const InnerInputContainer = styled.div`
  //display: flex;
  //flex-direction: row;
  overflow-y: hidden;
  box-sizing: border-box;
  width: 100%;
  padding-left: 0.57rem;
  justify-content: center;
  align-items: center;
`;
export const InputField = styled.textarea`
  background-color: var(--leo-white);
  overflow-y: auto;
  width: 100%;
  resize: none;
  border: none;
  outline: none;
  //min-height: 2rem;
  max-height: 13rem;
  display: flex;
`;

export const InputButtonContainer = styled.div`
  display: flex;
  align-items: flex-end;
  height: 100%;
  padding: 0.3rem;
`;
