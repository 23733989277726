import React, { useEffect, useRef } from 'react'
import { useLocation } from 'react-router-dom'
import clsx from 'clsx'
import DownloadIcon from '../../../../assets/icons/download.svg?react'
import leoWatermark from '../../../../assets/icons/Leo_watermark.png'
import * as analytics from '../../../../lib/analytics'
import { FEATURE_SHARE_IMAGE, FEATURE_MAGIC_ERASER } from '../../../../lib/config'
import { ROUTE } from '@/router/Router'
import { LikeButton } from '../../../LikeAndDislikeControls/LikeButton'
import { DislikeButton } from '../../../LikeAndDislikeControls/DislikeButton'
import { MagicEraserButton } from './MagicEraserButton'
import { VariationButton } from './VariationButton'
import { ShareButton } from './ShareButton'
import styles from './styles.module.scss'
import { useGalleryStore } from '../../../../store/useGalleryStore'
import { Tooltip } from '@mui/material'
import { useIdeationChat } from '@/src-ideation/store/useChat.js'

export const IMAGE_ID = 'myImage'

export const GalleryImage = ({ url }) => {
  const location = useLocation()
  const imageRef = useRef(null)
  const canvasRef = useRef(null)
  const { likeDislikeImages, setDislikeImage, setLikeImage } = useGalleryStore(store => store)
  // const { liked, disliked, clickLikeDislikeHandler } = useLikeDislike()
  const currantImage = likeDislikeImages.find(image => image.uri === url)
  const { liked, disliked } = currantImage || {}
  const promptVersions = useIdeationChat(store => store.promptVersions)

  const downloadVariant = async e => {
    e.stopPropagation()
    analytics.track('Download Image Variant Clicked', { url, prompt: promptVersions[promptVersions.length - 1] })
    downloadImageWithWatermark(canvasRef, url)
  }

  useEffect(() => {
    if (liked || disliked) {
      analytics.track('Image Sentiment Changed', {
        url,
        liked,
        disliked,
        prompt: promptVersions[promptVersions.length - 1],
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [url, liked, disliked])

  const handleDislikeClick = () => {
    // clickLikeDislikeHandler(e, 'dislike')
    setDislikeImage(url)
  }

  const handleLikeClick = () => {
    // clickLikeDislikeHandler(e, 'like')
    setLikeImage(url)
  }

  /** this will stop the saving image by right click */
  const handleContextMenu = event => {
    event.preventDefault()
  }

  const isGalleryPage = location.pathname === ROUTE.GALLERY
  if (isGalleryPage) {
    // Image without actions
    return (
      <div className={clsx(styles.GalleryImage, styles.galleryPage)}>
        <img
          id="myImage"
          alt=""
          ref={imageRef}
          className={clsx(styles.image, styles.galleryPageImage)}
          src={url}
          onContextMenu={handleContextMenu}
          data-testid="gallery-image"
        />
      </div>
    )
  }

  return (
    <div className={styles.GalleryImage}>
      <div className={styles.topBackground}>
        <LikeButton
          liked={liked}
          clickHandler={handleLikeClick}
          onImageStyle={clsx(styles.button, styles.ImageLikeButton)}
        />
        <DislikeButton
          disliked={disliked}
          clickHandler={handleDislikeClick}
          onImageStyle={clsx(styles.button, styles.ImageDislikeButton)}
        />

        {FEATURE_MAGIC_ERASER && <MagicEraserButton {...{ imageRef, imageUrl: url }} />}
        <VariationButton {...{ imageRef }} />

        <Tooltip title="Download image" placement="top">
          <button onClick={downloadVariant} className={styles.button}>
            <DownloadIcon id="downloadVariants" fill="var(--leo-white)" />
          </button>
        </Tooltip>

        {FEATURE_SHARE_IMAGE && <ShareButton {...{ url }} />}
      </div>

      <canvas ref={canvasRef} style={{ display: 'none' }} />
      <img
        id={IMAGE_ID}
        alt=""
        ref={imageRef}
        className={styles.image}
        src={url}
        onContextMenu={handleContextMenu}
        data-testid="concepts-image"
      />
    </div>
  )
}

/** This function suppose to download the Image by embedding a watermark to it */
export const downloadImageWithWatermark = async (canvasRef, url) => {
  try {
    const canvas = canvasRef.current
    const ctx = canvas.getContext('2d')
    const image = new Image()
    const watermark = new Image()

    image.crossOrigin = 'anonymous' // Set cross-origin to enable image retrieval from a different domain

    image.onload = () => {
      canvas.width = image.width
      canvas.height = image.height

      ctx.drawImage(image, 0, 0) // Draw the original image

      watermark.onload = () => {
        // Position the watermark image
        const x = 10
        const y = canvas.height - watermark.height - 10

        ctx.globalAlpha = 0.5 // Adjust the opacity as needed
        ctx.drawImage(watermark, x, y) // Draw the watermark image

        // Download the image with watermark
        const link = document.createElement('a')
        link.href = canvas.toDataURL('image/png')
        link.download = 'image_with_watermark.png'
        link.click()
      }

      watermark.src = leoWatermark // Path to your watermark image
    }

    image.src = url // Path to your original image
  } catch (error) {
    console.error(error)
  }
}
