
import { Filled, StyledLoadingBar, Text } from './LoadingBarStyle'

export interface Props {
  total: number
  value: number
  text?: string
}
const LoadingBar = (props: Props) => {
  return (
    <StyledLoadingBar>
      <Filled percentage={(props.value / props.total) * 100} />
      {props.text && (
        <>
          <Text>{`${props.value}/${props.total} ${props.text}`}</Text>
          <Text>Feel free to close the window while Leo is writing your technical spec</Text>
        </>
      )}
    </StyledLoadingBar>
  )
}

export default LoadingBar
