
import { DESIGN_INPUTS_SOURCE, MAX_UPLOAD_IMAGE_SIZE_IN_BYTES } from '../../../../constants/index.ts'
import { ClearReason, useDesignInputs } from '../../../../hooks/useDesignInputs'
import { useQueue } from '../../../../hooks/useQueue'
import { isValidImageMimeType } from '../../../../utils'
import styles from './VariantImage.module.scss'

export const UploadedImage = () => {
  const { imageSrc, clearImage } = useDesignInputs()
  const { addToQueue } = useQueue()

  // Upload handler for Uploaded image
  // This happens when the uploaded image has changed and not when from scratch. There is another
  // component for this and it's a bit confusing
  const handleFileChange = event => {
    const selectedImage = event.target.files[0]

    if (!isValidImageMimeType(selectedImage)) {
      return clearImage(ClearReason.InvalidFormat)
    }

    if (selectedImage.size >= MAX_UPLOAD_IMAGE_SIZE_IN_BYTES) {
      return clearImage(ClearReason.FileTooBig)
    }

    // Add to queue
    addToQueue({ type: DESIGN_INPUTS_SOURCE.UPLOADED, image: selectedImage })
  }

  return (
    <>
      <label htmlFor="fileInput" style={{ cursor: 'pointer' }}>
        <img src={imageSrc} alt="" />
      </label>
      <input
        className={styles.fileInput}
        type="file"
        id="fileInput"
        name="image"
        accept="image/*"
        onChange={handleFileChange}
      />
    </>
  )
}
