
import { useUser } from '@descope/react-sdk'
import { Box } from '@mui/material'
import { Avatar } from '../../../components/common/Avatar'
import { Button } from '../../../components/common/Button'
import styles from './AccountDetails.module.scss'

export const AccountDetails = () => {
  const { user } = useUser()

  if (!user) return null

  const { name, email } = user
  const editHandler = () => {
    // TODO
  }

  return (
    <Box className={styles.AccountDetails}>
      <Box display="flex" alignItems="center" justifyContent="space-between" flexWrap="wrap">
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Box className={styles.avatarContainer}>
            <Avatar size="9vh" />
          </Box>
          <Box className={styles.info}>
            <h2 className={styles.name}>{name}</h2>
            <p className={styles.email}>{email}</p>
          </Box>
        </Box>
        <Box display="flex" justifyContent="flex-end" flex={1} marginTop={4}>
          <Button variant="outlined" color="secondary" onClick={editHandler} rounded>
            Edit Profile
          </Button>
        </Box>
      </Box>
    </Box>
  )
}
