import styled , {keyframes} from "styled-components";


const flash = keyframes`
  0%, 50%, 100% {
    //opacity: 1;
      border: 1px solid #f2eee840;
  }
  25%, 75% {
    //opacity: 0.6;
      border: 1px solid rgba(256, 256, 256, 0);
  }
`;

export const StyledPopUpWrapper = styled.div`
    position: absolute;
    top: 3.5rem;
    right: 9.8rem;
    z-index: 11;
    @media (max-width: 768px) {
        display: none;
    }
`

export const StyledPopUp = styled.div`
    position: relative;
    max-width: 19rem;
    padding: 1.6rem 1.3rem;
    background: rgba(28, 21, 36, 0.8);
    color: white;
    border-radius: 1rem;
    font-size: 0.9rem;
    animation: ${flash} 4s linear infinite;
`

export const StyledPopUpClose = styled.div`
    z-index: 12;
    position: absolute;
    top: 0.8rem;
    right: 0.8rem;
    cursor: pointer;
    font-size: 0.6rem;
    svg {
        width: 0.6rem;
        height: 0.6rem;
        opacity: 0.5;
    }
    &:hover {
        svg {
            opacity: 1;
        }
    }
`
