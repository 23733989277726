import { useAudit } from './useAudit'
import { create } from 'zustand'

type State = {
  lines: any[],
  saveLinesToStore: (lines: any[]) => void,

  uploadedImage: any | null,
  setUploadedImage: (uploadedImage: string | null) => void,

  base64String: string | null,
  setBase64String: (base64String: string | null) => void,

  sketchBase64: string | null,
  setSketchBase64: (sketchBase64: string | null) => void,

  magicEraserImage: JSX.Element | null,
  setMagicEraserImage: (magicEraserImage: JSX.Element) => void,

  magicEraserMask: any[],
  setMagicEraserMask: (magicEraserMask: any[]) => void,

  magicEraserPayload: Record<string, unknown>,
  setMagicEraserPayload: (magicEraserPayload: Record<string, unknown>) => void,

  queue: { type: string, image: any }[],
  setQueue: (queue: { type: string, image: any }[]) => void,
}

export const useDesignInputsStore = create<State>(set => ({
  lines: [],
  saveLinesToStore: lines => set({ lines }),

  uploadedImage: null,
  setUploadedImage: uploadedImage => set({ uploadedImage }),

  base64String: null,
  setBase64String: base64String => set({ base64String }),

  sketchBase64: null,
  setSketchBase64: sketchBase64 => set({ sketchBase64 }),

  magicEraserImage: null, // should be an <img />
  setMagicEraserImage: magicEraserImage => set({ magicEraserImage }),
  magicEraserMask: [],
  setMagicEraserMask: magicEraserMask => set({ magicEraserMask }),

  magicEraserPayload: {},
  setMagicEraserPayload: magicEraserPayload =>
    set(store => {
      return { magicEraserPayload: { ...store.magicEraserPayload, ...magicEraserPayload } }
    }),

  queue: [
    // {
    //   type: one of DESIGN_INPUTS_SOURCE
    //   image: any
    // }
  ],
  setQueue: queue => set({ queue }),
}))

useDesignInputsStore.subscribe((state, prevState) => {
  const audit = useAudit.getState()

  // This case is managed in the component
  // Solution below is problematic since it overloads the cpu
  // if (state.sketchBase64 && state.sketchBase64 !== prevState.sketchBase64) {
  //   audit.insertOrReplaceIfSameTag([{
  //     tag: "sketched-image", data: state.sketchBase64, encoding: 'base64'
  //   }]);
  // }

  if (state.base64String && state.base64String !== prevState.base64String) {
    audit.insertOrReplaceIfSameTag({
      tag: 'uploaded-image',
      data: state.base64String,
      encoding: 'base64',
    })
  }
})
