import { useState } from 'react'

// TODO: I think we should refactor this a bit
// To much knowledge about caller (e.g. the event).
// Also, caller might want to introduct logic on click (analytics for example)
export function useLikeDislike(initialLiked = false, initialDisliked = false) {
  const [liked, setLiked] = useState(initialLiked)
  const [disliked, setDisliked] = useState(initialDisliked)

  const clickLikeDislikeHandler = (event, action) => {
    event.stopPropagation()

    if (action === 'like' && disliked) {
      setDisliked(false)
    } else if (action === 'dislike' && liked) {
      setLiked(false)
    }

    if (action === 'like') {
      setLiked(prevState => !prevState)
      // TODO API CALL - like or remove the like
    } else if (action === 'dislike') {
      setDisliked(prevState => !prevState)
      // TODO API CALL - dislike or remove the dislike
    }
  }

  return { liked, disliked, clickLikeDislikeHandler }
}
