
import { WindowHeader } from '../common/WindowHeader'
import { Chat } from './Chat'
import { DesignInputs } from './DesignInputs'
import styles from './LeftSection.module.scss'
import CreateNewSession from '../Sessions/CreateNewSession/CreateNewSession'

// import ExportSessionButton from './ExportSessionButton/ExportSessionButton'

export const LeftSection = ({ addSpec, generateImages, startGenerate }) => {
  return (
    <div className={styles.LeftSection}>
      <WindowHeader title="Leo">
        <CreateNewSession startGenerate={startGenerate} />
      </WindowHeader>
      <div className={styles.chatContainer}>
        <Chat generateImages={generateImages} setSpec={addSpec} />
      </div>
      <DesignInputs />
    </div>
  )
}
