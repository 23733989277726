import React, { useEffect } from 'react'
import styles from './Document.module.css'
import useDocsStore from '../../../store/useDocsStore'
import { Spinner, SPINNER_COLOR } from '../../Spinner'
import clsx from 'clsx'
import { useTechSpecStore } from '../../../store/useTechSpecStore'

export type ContentChangeHandler = (content: string) => void

export interface Props {
  name: string
  contentRef?: any
  isDialog?: boolean
  onContentChange: ContentChangeHandler
}

export default function Document(props: Props) {
  const docName = props.name
  const state = useDocsStore()
  const { currentVersionIndex } = useTechSpecStore(store => store)
  const data: any = state.getDoc(docName, currentVersionIndex)
  const artifactNames =
    (state.docs && state.docs[docName] && state.docs[docName]?.[currentVersionIndex]?.artifactNames) || []
  const contentRef = React.useRef<any>(null)

  useEffect(() => {
    props.onContentChange(contentRef.current?.innerHTML)
  }, [contentRef, props])

  if (!state.hasDoc(docName, currentVersionIndex)) {
    return <></>
  }

  const isProductSummary: boolean = data && data.artifacts && !!data.artifacts['product-summary.response']

  const renderContent = (
    content: any,
    classNameOfContent?: string,
    elementIndex?: any,
    parentIndex?: any
  ): JSX.Element | JSX.Element[] | string | any => {
    const hiddenTitles: string[] = ['title', 'description', ...artifactNames]
    if (Array.isArray(content)) {
      return content.map((item, arrayIndex: number) => renderContent(item, undefined, arrayIndex + 1, parentIndex))
    } else if (typeof content === 'object') {
      return Object.keys(content || {}).map((key, index) => {
        const sectionIndex: any = parentIndex
          ? parentIndex + '.' + (index + 1)
          : isProductSummary
          ? index - 1
          : index + 1

        return key === 'title' || key === 'description' || Object.keys(content).length === 1 ? (
          renderContent(content[key], key)
        ) : (
          <>
            {!hiddenTitles.includes(key) && (
              <h2
                style={{ paddingLeft: parentIndex && '2vh' }}
                key={`${key}_sub-title_${index}`}
                className={styles['sub-title']}
              >
                {sectionIndex > 0 && `${sectionIndex}.${key}:`}
              </h2>
            )}

            <div key={`${key}_${index}`}>{renderContent(content[key], key, index, sectionIndex)}</div>
          </>
        )
      })
    } else {
      const classNameForElement: string = classNameOfContent ? classNameOfContent : 'content'
      const Element: 'h1' | 'h6' | 'div' =
        classNameForElement === 'title' ? 'h1' : classNameForElement === 'description' ? 'h6' : 'div'

      return (
        <Element key={content} className={styles[classNameForElement]}>
          {classNameForElement === 'content' && parentIndex && parentIndex + '.'}
          {classNameForElement === 'content' && elementIndex && elementIndex + ' '}
          {content}
        </Element>
      )
    }
  }

  return (
    <div className={clsx(styles.container, props.isDialog && styles.isDialog)}>
      <div ref={contentRef}>{renderContent(data.artifacts)}</div>
      <div className={styles.loadingContainer}>
        {state.isDocLoading(docName, currentVersionIndex) && <Spinner {...{ color: SPINNER_COLOR.BRIGHT }} />}
      </div>
    </div>
  )
}
