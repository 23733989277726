import styled from 'styled-components'

export const ContainerInput = styled.div`
  padding: 0.5rem;
  background: #ffffff;
  border-radius: 10px;
  border: 4px solid black;
  max-height: 13rem;
  display: flex;
  justify-content: center;
`

export const InputField = styled.input`
  width: 100%;
  overflow-y: hidden;
  padding-left: 0.57rem;
  border: none;
  outline: none;
`
