import { createBrowserRouter, Navigate } from "react-router-dom";
import { Root } from "./Root.js";
import { SignIn, SignUp } from "@/src-ideation/pages/Auth";
import "../styles/index.scss";
import { Chat } from "../components/chatSection/chat/Chat.tsx";
import KycForm from "@/components/kycForm/KycForm.tsx";
import {Home} from "@/src-ideation/pages/Home.jsx"
import {Profile} from "@/src-ideation/pages/Profile"
import {Gallery} from "@/src-ideation/pages/Gallery"
import SessionsPage from "@/src-ideation/components/Sessions/SessionsPage/sessionsPage"

export const ROUTE = {
  MAIN: "/",
  IDEATION: "/ideation",
  CHAT: "/",
  SIGN_IN: "/sign-in",
  SIGN_UP: "/sign-up",
  KYC_PAGE: "/kyc-page",
  PROFILE: '/profile',
  GALLERY: '/gallery',
  SESSIONS: '/sessions',
};

export const router = createBrowserRouter([
  {
    path: ROUTE.MAIN,
    element: <Root />,
    errorElement: <Navigate to={ROUTE.MAIN} />,
    children: [
      {
        path: ROUTE.IDEATION,
        element: <Home />,
      },
      {
        path: ROUTE.MAIN,
        element: <Chat />,
      },
      {
        path: ROUTE.SIGN_IN,
        element: <SignIn />,
      },
      {
        path: ROUTE.SIGN_UP,
        element: <SignUp />,
      },
      {
        path: ROUTE.KYC_PAGE,
        element: <KycForm />,
      },
      {
        path: ROUTE.PROFILE,
        element: <Profile />,
      },
      {
        path: ROUTE.GALLERY,
        element: <Gallery />,
      },
      {
        path: ROUTE.SESSIONS,
        element: <SessionsPage />,
      },
    ],
  },
]);
