import { useNavigate } from 'react-router-dom'
import { LIST_SESSION_ENDPOINT } from '../../lib/config'
import * as observability from '../../lib/observability'
import * as analytics from '../../lib/analytics'
import YAML from 'yaml'
import { toast } from 'react-toastify'
import { useSessionStore } from '../../store/useSessionStore'
import { ROUTE } from '@/router/Router.tsx'
import { useFetchData } from '../../api'
import { useGalleryStore } from '../../store/useGalleryStore'
import useDocsStore from '../../store/useDocsStore'
import { useTechSpecStore } from '../../store/useTechSpecStore'
import { useGalleryModal } from '../../store/useGalleryModal'
import { initialMessage } from '../../constants/msgEnums'
import { useIdeationChat } from '@/src-ideation/store/useChat.js'

export interface SessionMetadata {
  sessionNum: number
  headline?: string
  createdAt?: string
  thumbnailUrl?: any
  agent?: string
}

export type CardClickHandler = () => void
export interface SessionCardProps extends SessionMetadata {
  onCardClick: CardClickHandler
}
export default function useLoadSessionData() {
  const { setSessionNum, setRevisionNum, setIsGeneratedAlready } = useSessionStore(store => store)
  const { setMessages, setFullMessagesLog, setPromptVersions, setProductDescription } = useIdeationChat(store => store)
  const { storeResponse, setImages } = useGalleryStore(store => store)
  const { setImagesData, imagesData } = useGalleryModal()
  const { setRevisionsAmount, setCurrentVersionIndex, setImagesContent } = useTechSpecStore(store => store)
  const navigate = useNavigate()
  const { fetchData } = useFetchData()
  const getState = () => useDocsStore.getState()

  async function getAndLoadSessionData(sessionNum: number) {
    try {
      const response = await fetchData({
        url: `${LIST_SESSION_ENDPOINT}/${sessionNum}`,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      })
      getState().resetDoc()
      const sessionData = response.session
      const revisions = sessionData.revisions || []
      const revisionsAmount = revisions.length

      setSessionNum(sessionNum)
      setRevisionNum(revisions.length)
      setIsGeneratedAlready(revisions[revisionsAmount - 1]?.images.length > 0)

      const revisionsWithDocs = revisions.filter((obj: any) => obj.docs.length > 0).length

      setRevisionsAmount(revisionsWithDocs)
      setCurrentVersionIndex(revisionsWithDocs > 0 ? revisionsWithDocs - 1 : revisionsWithDocs)

      loadChatData(revisions)
      loadLastProductSummary(revisions)
      loadTechnicalSpecs(revisions)
      loadImages(revisions)
      navigate(ROUTE.IDEATION)
    } catch (error) {
      observability.captureException(`GET Sessions error  ${LIST_SESSION_ENDPOINT}/${sessionNum}`, {
        error,
        tags: { url: `${LIST_SESSION_ENDPOINT}/${sessionNum}`, method: 'GET' },
      })

      analytics.track(`Unable to get Session ${sessionNum}`, { error })
      toast.error(`Unable to get Session ${sessionNum}`)

      throw new Error(`Unable to get Session ${sessionNum}`)
    }
  }

  const loadChatData = (revisions: any[]) => {
    const sessionsMessages: any[] = [{ text: initialMessage, sender: 'assistant' }]
    const sessionsPromptVersions: string[] = []

    revisions
      .sort((a: any, b: any) => a.num - b.num)
      .forEach((revision: any) =>
        revision.chats
          .sort((a: any, b: any) => a.chatNum - b.chatNum)
          .forEach((chat: any) => {
            let text = chat.text
            let sender = chat.sender

            if (chat.text.includes('<Product Description>')) {
              //this is for backward compatibility.chat sender was not defined as 'description' so we check if the current message is a product description.

              const splitDescriptionMessage = chat.text.split('</Product Description>\n')
              text = splitDescriptionMessage[0]
                .replace('<Product Description>\n', '')
                .replace('</Product Description>', '')
              sender = 'description'

              if (revision.num === revisions.length) {
                setProductDescription(text)
              }

              sessionsPromptVersions.push(text)
              sessionsMessages.push({ sender, text })
              sessionsMessages.push({ sender: 'assistant', text: splitDescriptionMessage[1] })
            } else {
              if (sender === 'description') {
                if (revision.num === revisions.length) {
                  setProductDescription(text)
                }

                sessionsPromptVersions.push(text)
              }

              sessionsMessages.push({ sender, text })
            }
          })
      )
    setPromptVersions(sessionsPromptVersions)
    setMessages(sessionsMessages)
    setFullMessagesLog(sessionsMessages)
  }
  const loadImages = (revisions: any[]) => {
    setImages([])
    setImagesData([])
    const upscaleArray: any[] = []
    revisions
      .sort((a: any, b: any) => a.num - b.num)
      .forEach((revision: any) => {
        setImagesData([...imagesData, ...revision.images])

        revision.images.forEach((imageObj: any) => {
          const { sessionNum, revisionNum, imageNum, url } = imageObj
          upscaleArray.unshift({
            id: { sessionNum, revisionNum, imageNum },
            uri: url,
          })
        })
      })

    setImagesData(upscaleArray)
    storeResponse({ 'upscale': upscaleArray })
  }
  const loadLastProductSummary = (revisions: any[]) => {
    const imageData: any = {}
    revisions.forEach(revision => {
      if (revision.docs.length === 0) {
        getState().updateDoc('product-summary', revision.num - 1, () => ({
          artifactNames: ['product-summary.response'],
          artifacts: [],
          isLoading: false,
          revision: revision.num - 1,
        }))

        return null
      }

      const productSummaryDoc = revision.docs.find(
        (doc: any) => doc.type === 'product-summary/product-summary.response' && doc.documentNum === 1
      )

      if (!productSummaryDoc) return null

      const content = productSummaryDoc.data.content
      const type = productSummaryDoc.type.split('/')

      const revisionNumber = revision.num
      const document = type[0]
      const artifactName = type[1]
      const parsedData = YAML.parse(content)
      const artifactNames = [artifactName]
      getState().updateDoc(document, revisionNumber, () => ({
        artifactNames,
        artifacts: [],
        isLoading: false,
        revision: revisionNumber - 1,
      }))

      imageData[revision.num] = { title: parsedData.title, description: parsedData.description }

      getState().updateDoc(document, revisionNumber - 1, doc => ({
        artifacts: { ...doc.artifacts, [artifactName]: parsedData },
      }))
    })
    setImagesContent(imageData)
  }

  const loadTechnicalSpecs = (revisions: any[]) => {
    revisions.forEach(revision => {
      const artifactNames: string[] = [
        'introduction.response',
        'product-overview.response',
        'mechanical-requirements.response',
        'electrical-requirements.response',
        'software-requirements.response',
        'interfaces-and-interactions.response',
        'environment-and-conditions.response',
        'safety-and-compliance.response',
        'appendices.response',
      ]
      getState().updateDoc('technical-spec', revision.num, () => ({
        artifactNames,
        artifacts: [],
        isLoading: false,
        revision: revision.num - 1,
      }))

      revision.docs
        .sort((a: any, b: any) => a.documentNum - b.documentNum)
        .forEach((doc: any) => {
          const content = doc.data.content
          const type = doc.type.split('/')
          const revisionNumber = revision.num
          const document = type[0]
          const artifactName = type[1]
          if (!artifactNames.includes(artifactName)) return

          const parsedData = YAML.parse(content)

          getState().updateDoc(document, revisionNumber - 1, doc => ({
            artifacts: { ...doc.artifacts, [artifactName]: parsedData },
          }))
        })
    })
  }

  return {
    getAndLoadSessionData,
  }
}
