import { useEffect } from 'react'
import { DESIGN_INPUTS_SOURCE } from '../constants/index.ts'
import { useDesignInputs } from './useDesignInputs'
import { useIdeationChat } from '@/src-ideation/store/useChat.js'

const TOOLTIP_MESSAGE = {
  LEFT: 'Drag the slider here to have Leo highly guided by your design inputs and text.',
  RIGHT: 'Drag the slider here to have Leo consider your design inputs and text as loose inspiration.',
}

const DEFAULT_LABELS = {
  labels: { left: 'guided', right: 'creative' },
  tooltips: { left: TOOLTIP_MESSAGE.LEFT, right: TOOLTIP_MESSAGE.RIGHT },
}

const DEFAULT_SETTINGS = {
  defaultValue: 0.5,
  step: 0.01,
  min: 0,
  max: 1,
}

export const CONTROLLER_SETTINGS = {
  [DESIGN_INPUTS_SOURCE.NONE]: {
    valueKey: 'creativeness',
    ...DEFAULT_SETTINGS,
    ...DEFAULT_LABELS,
  },
  [DESIGN_INPUTS_SOURCE.VARIANT]: {
    valueKey: 'variationStrength',
    ...DEFAULT_SETTINGS,
    ...DEFAULT_LABELS,
  },
  [DESIGN_INPUTS_SOURCE.UPLOADED]: {
    valueKey: 'referenceStrength',
    ...DEFAULT_SETTINGS,
    ...DEFAULT_LABELS,
  },
  [DESIGN_INPUTS_SOURCE.MAGIC_ERASER]: {
    valueKey: 'magicEraserStrength',
    ...DEFAULT_SETTINGS,
    ...DEFAULT_LABELS,
  },
  [DESIGN_INPUTS_SOURCE.SKETCH]: {
    valueKey: 'creativenessControllerValue',
    ...DEFAULT_SETTINGS,
    ...DEFAULT_LABELS,
    // If you want to add marks, add it like so:
    // marks: [{ value: 0 }, { value: 0.5 }, { value: 1 }],
  },
}

export const useController = () => {
  const { creativenessControllerValue, setCreativenessControllerValue } = useIdeationChat(store => store)
  const { source } = useDesignInputs()

  useEffect(() => {
    // Reset controller value to defaultValue if source was changed
    const defaultValue = CONTROLLER_SETTINGS[source]?.defaultValue || 0.5
    setCreativenessControllerValue(defaultValue)
    // TODO: this is a mistake. Fix this and use the dependencies.
    // eslint-disable-next-line
  }, [source])

  const settings = CONTROLLER_SETTINGS[source] || {}

  return {
    setValue: setCreativenessControllerValue,
    value: creativenessControllerValue,
    settings,
  }
}
