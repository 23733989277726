import React, { useEffect, useRef, useState } from 'react'
import { finishGenerated } from '@/constants/msgEnums.ts'
import { useChat } from '@/store/useChat.ts'
import { ChatInput } from '../chatInput/ChatInput.tsx'
import { Message } from '../message/Message.tsx'
import {
  ChangingText,
  ChatContainer,
  ChatSection,
  ChatWindowStyled,
  ChatWrapper,
  LoadingWrapper,
  SessionButtonWrapper,
  SessionsHistoryWrapper,
  SlackChatWrapper,
  Wrapper,
} from './chatStyle.ts'
import CreateNewSession from '../../sessions/createNewSession/CreateNewSession.tsx'
import loadingGif from '@/assets/icons/Logo_animation_Bright.gif'
import Suggestions from '@/components/chatSection/suggestions/Suggestions.tsx'
import SessionsHistoryList from '@/components/sessionsHistoryList/SessionsHistoryList.tsx'
import PartRetrieval from '@/components/chatSection/partRetrieval/partRetrieval.tsx'
import useScreenSize from '@/components/useScreenSize/useScreenSize.ts'
import { HeaderMemo } from '@/src-ideation/components/Header'
import { commitTitleChange } from '@/components/sessionsHistoryList/SessionHistoryListItem.tsx'
import { useSessionStore } from '@/store/useSessionStore.ts'
import { SlackChat } from '@/src-ideation/components/Footer/components/SlackChat'

interface ChatWindowProps {
  inputHeight: number
  chatSectionHeight: number
  addMessages: (newMessages: any[]) => void
  addFullMessages: (newMessages: any[]) => void

  // Why is setImagePrompt here? - we don't generate nothing in the cad!
  // Is it just copy pasted from ideation?
  setImagePrompt: (text: string) => void
}

const ChatWindow: React.FC<ChatWindowProps> = React.memo(
  ({
    addMessages,
    addFullMessages,

    // Why is setImagePrompt here? - we don't generate nothing in the cad!
    // Is it just copy pasted from ideation?
    setImagePrompt,
  }) => {
    const { messages, isLoading, isRouterLoading, isStreaming } = useChat(store => store)
    const { sessionNum } = useSessionStore(store => store)

    const chatRef = useRef<HTMLDivElement>(null)
    const loadingText: string[] = [
      'Scanning the internet for insights...',
      'Ensuring information integrity...',
      'Extracting insights from books.../Extracting insights from handbooks.../Extracting insights from literature.../Extracting insights from standards.../Extracting insights from technical forums.../Verifying against industry best practices...',
      'Fusing multiple data sources...',
      'Double-checking for accuracy...',
    ]

    let intervalId: any

    const [textIndex, setTextIndex] = useState(0)

    useEffect(() => {
      if (chatRef.current) {
        chatRef.current.scrollTop = chatRef.current.scrollHeight

        const secondToLastMessageElement = chatRef.current.children[chatRef.current.children.length - 2]
        if (secondToLastMessageElement) {
          secondToLastMessageElement.scrollIntoView({ behavior: 'smooth' })
        }
      }
    }, [messages])

    useEffect(() => {
      if (isLoading) {
        intervalId = setInterval(() => {
          if (textIndex < 4) {
            setTextIndex(currentNumber => currentNumber + 1)
          }
        }, 3000)
      } else {
        setTextIndex(0)
      }

      return () => {
        clearInterval(intervalId)
      }
    }, [loadingText, isLoading])

    const getRandomText = () => {
      const textList = loadingText[textIndex].split('/')
      const shuffledArray = textList.slice()

      for (let i = shuffledArray.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1))
        ;[shuffledArray[i], shuffledArray[j]] = [shuffledArray[j], shuffledArray[i]]
      }
      const chosenText = shuffledArray.slice(0, 3)

      return (
        <div>
          <div>{chosenText[0]}</div>
        </div>
      )
    }

    useEffect(() => {
      const userMasage = messages.filter(message => message.sender === 'user')
      if (userMasage.length === 1 && sessionNum) {
        commitTitleChange(sessionNum, userMasage[0].text)
      }
    }, [messages, sessionNum])

    return (
      <ChatWindowStyled ref={chatRef} isMessages={messages.length > 1}>
        {messages.map((message, index) => {
          return message.sender === 'component' ? (
            <PartRetrieval message={message.text} />
          ) : (
            <Message
              key={index}
              message={message}
              addMessages={addMessages}
              addFullMessages={addFullMessages}
              setImagePrompt={setImagePrompt}
              isLastMessage={index === messages.length - 1}
            />
          )
        })}

        {messages.length === 1 && <Suggestions />}

        {(isRouterLoading || isLoading) && (
          <LoadingWrapper>
            <img src={loadingGif} alt="Loading..." />

            {isRouterLoading ? (
              <ChangingText key={textIndex}>Processing</ChangingText>
            ) : !isStreaming ? (
              <ChangingText key={textIndex}>
                {textIndex === 2 ? <span>{getRandomText()}</span> : loadingText[textIndex]}
              </ChangingText>
            ) : null}
          </LoadingWrapper>
        )}
      </ChatWindowStyled>
    )
  }
)

export const Chat: React.FC = React.memo(() => {
  const { isGenerated, fullMessagesLog, setFullMessagesLog, promptVersions, setPromptVersions, messages, setMessages } =
    useChat(store => store)
  const screenSize = useScreenSize()

  const [inputHeight, setInputHeight] = useState(0)
  const [chatSectionHeight, setChatSectionHeight] = useState(0)
  const [displaySessionPage, setDisplaySessionPage] = useState(false)
  const chatSectionRef = useRef<HTMLDivElement>(null)

  const setImagePrompt = (text: string) => setPromptVersions([...promptVersions, text])

  useEffect(() => {
    if (chatSectionRef.current) {
      setChatSectionHeight(chatSectionRef.current.offsetHeight)
    }
  }, [chatSectionRef])

  /** Side effect - once the generating part is complete this will be adding a generate completed message to the chat */
  useEffect(() => {
    if (isGenerated) {
      const lastMessage = messages[messages.length - 1]
      const hasMatchingText = lastMessage && lastMessage.text === finishGenerated
      if (!hasMatchingText) {
        addMessages([
          {
            text: finishGenerated,
            sender: 'assistant',
          },
        ])
      }
    }
  }, [isGenerated])

  const addMessages = (newMessages: any[]) => setMessages([...messages, ...newMessages])

  const addFullMessages = (newMessages: any[]) => setFullMessagesLog([...fullMessagesLog, ...newMessages])

  const onNewSessionClicked = () => {
    setDisplaySessionPage(showSessionPage => {
      // resizeAppWindow({ isOpen: !showSessionPage });
      return !showSessionPage
    })
  }

  const displayChat = !displaySessionPage || screenSize.width > 750

  return (
    <Wrapper>
      <SessionsHistoryWrapper displayHistory={displaySessionPage} displayChat={displayChat}>
        {displaySessionPage && (
          <SessionsHistoryList
            onNewSessionClicked={onNewSessionClicked}
            displayHistory={displaySessionPage}
            displayChat={displayChat}
          />
        )}
      </SessionsHistoryWrapper>
      {
        <ChatWrapper displayChat={displayChat}>
          <HeaderMemo onNewSessionClicked={onNewSessionClicked} />

          <ChatContainer>
            <ChatSection height={screenSize.height} ref={chatSectionRef}>
              <SessionButtonWrapper>
                <CreateNewSession />
              </SessionButtonWrapper>
              <ChatWindow
                inputHeight={inputHeight}
                chatSectionHeight={chatSectionHeight}
                addMessages={addMessages}
                addFullMessages={addFullMessages}
                setImagePrompt={setImagePrompt}
              />
              <ChatInput setInputHeight={setInputHeight} setImagePrompt={setImagePrompt} />
            </ChatSection>
          </ChatContainer>
        </ChatWrapper>
      }
      {window.innerWidth >= 1362 && (
        <SlackChatWrapper>
          <SlackChat />
        </SlackChatWrapper>
      )}
    </Wrapper>
  )
})
