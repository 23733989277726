import { create } from 'zustand'
import { devtools } from 'zustand/middleware'

export type Doc = {
  isLoading: boolean
  artifactNames: string[]
  artifacts: string[]
  revision: number
}

export type State = {
  docs: { [name: string]: Doc[] }

  isDocLoading: (name: string, revision: number) => boolean
  hasDoc: (name: string, revision: number) => boolean
  updateDoc: (name: string, revision: number, setter: (doc: Doc) => Partial<Doc>) => void
  resetDoc: () => void
  getDoc: (name: string, revision: number) => Doc | undefined
}

export default create<State>()(
  devtools((set, get) => ({
    docs: {},
    isDocLoading: (name: string, revision: number) => get().docs[name]?.[revision]?.isLoading || false,
    hasDoc: (name: string, revision: number) =>
      get().docs[name] && get().docs[name].findIndex(doc => doc.revision === revision) !== -1,
    getDoc: (name: string, revision: number) => get().docs[name]?.find(doc => doc.revision === revision),
    updateDoc: (name: string, revision: number, setter: (doc: Doc) => Partial<Doc>) => {
      const docs = get().docs[name] || []
      const existingDocIndex = (docs || []).findIndex(x => x.revision === revision)

      if (existingDocIndex >= 0) {
        docs[existingDocIndex] = {
          ...docs[existingDocIndex],
          ...setter(docs[existingDocIndex]),
        }
      } else {
        docs.push(setter({ revision } as any) as any)
      }

      set({
        ...get(),
        docs: {
          ...get().docs,
          [name]: docs,
        },
      })
    },

    resetDoc: () => {
      set({
        docs: {},
      })
    },
  }))
)
