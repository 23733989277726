import styled from "styled-components";
import { TextField, Box } from "@mui/material";
export const Wrapper = styled(Box)`
  display: grid;
  position: absolute;
  bottom: 15px;
  left: 7px;
  gap: 0.8rem;

  @media (max-width: 600px) {
    width: 96.5%;
    & .MuiInputBase-root {
      cursor: pointer;
      min-height: 5rem;
    }
  }

  @media (min-width: 600px) {
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
    width: 97.5%;
    & .MuiInputBase-root {
      cursor: pointer;
      min-height: 7rem;
    }
  }
`;
export const CustomTextarea = styled(TextField)({
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#A2B6FF",
      cursor: "pointer",
      borderWidth: "0.5px",
    },
    "&:hover fieldset": {
      borderColor: "#7e99fb",
      cursor: "pointer",
    },
  },
  "& .MuiInputBase-input": {
    color: "#A2B6FF",
    fontSize: "1.1rem",
    cursor: "pointer",
  },

  "&:hover .MuiInputBase-input": {
    color: "#7e99fb",
    fontSize: "1.1rem",
    cursor: "pointer",
  },
  "& .MuiInputLabel-root": {
    color: "#A2B6FF",
    cursor: "pointer",
    // width: "97%",
    fontSize: "1.1rem",
  },
  "&:hover .MuiInputLabel-root": {
    color: "#7e99fb",
    cursor: "pointer",
  },
});

export const Card = styled.div`
  border: 1px solid var(--border-grey);
  border-radius: 12px;
  padding: 0.7rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  cursor: pointer;
  min-height: 5rem;

  div {
    font-size: 0.875rem;
    font-weight: bold;
    padding-bottom: 0.2rem;
  }

  span {
    font-size: 0.875rem;
    color: #ececec;
    opacity: 0.5;
  }
`;
