// this message appearing once after generate is completed
export const generateCompletedMessage =
  "Here you go! Check out the new design concepts and tech spec and let me know if you'd like to make any changes. You can type here and/or add design inputs."

// this is the initial message showing once start the app
export const initialMessage = 'Hi, I’m Leo, your engineering design copilot. 👋  \n\n**What would you like to design?**'

// this is the message showing once after edit the messages in chat
export const editedMessage =
  "Ok, I've updated the description. Click the Generate button to generate new design concepts and tech spec."

export const userEditPrefix = 'Use the following edited product desciption'

export const finishGenerated =
  "Here you go! Check out the new design concepts and tech spec and let me know if you'd like to make any changes. You can type here and/or add design inputs."

export const VARIATIONS_MESSAGE =
  'In order to create variants, you can adjust the slider, and then click on the generate button'

export const UPLOAD_ERROR_MESSAGE = 'Invalid file format. Please select a png or jpg image.'

export const UPLOAD_ERROR_FILE_TOO_BIG = 'File is too large. Please select a smaller image.'
