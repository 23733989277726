import React, { useEffect, useState } from 'react'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import  CopyIcon  from '../../../assets/icons/copy.svg?react'
import { useThemeContext } from '../../../hooks/useThemeContext.jsx'

import * as analytics from '../../../lib/analytics'

export const CopyToClipboardButton = ({ plainText }) => {
  const { isDark } = useThemeContext()
  const [copied, setCopied] = useState(false)

  useEffect(() => {
    if (copied) {
      setTimeout(() => setCopied(false), 2000)
    }
  }, [copied])

  const handleCopy = () => {
    setCopied(true)
    analytics.track('Spec Copy Clicked')
  }

  if (!plainText) {
    return null
  }

  return (
    <CopyToClipboard
      className="CopyToClipboardButton"
      text={plainText}
      options={{ format: 'text/plain' }}
      onCopy={handleCopy}
    >
      <button>{copied ? '✔️' : <CopyIcon fill={isDark ? 'var(--leo-white)' : 'var(--leo-black)'} />}</button>
    </CopyToClipboard>
  )
}
