import styled from "styled-components";
interface ContainerProps {
  align?: string;
}
export const Container = styled.div<ContainerProps>`
  display: flex;
  flex-direction: row;
  justify-content: ${(props) => (props.align === "left" ? "start" : "end")};
`;

export const Items = styled.div`
  display: flex;
  align-items: baseline;
  flex-direction: row;
  gap: 9px;
  margin-right: 0.3rem;
`;

export const Item = styled.div`
  cursor: pointer;
  padding: 3px;

  :hover {
    svg {
      width: 140%;
      height: 140%;
      transition-property: width, height;
      transition-duration: 0.1s;
      transition-timing-function: ease-in;
    }
  }
`;
