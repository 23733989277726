import React, { useCallback, useEffect, useState } from 'react'
import clsx from 'clsx'
import { Tooltip } from '@mui/material'
import FacebookIcon from '@mui/icons-material/Facebook'
import TwitterIcon from '@mui/icons-material/Twitter'
import LinkedInIcon from '@mui/icons-material/LinkedIn'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import RedditIcon from '@mui/icons-material/Reddit'
import DoneIcon from '@mui/icons-material/Done'
import ShareIcon from '../../../../assets/icons/share.svg?react'
import styles from './styles.module.scss'
import { useShareImageUrl } from '../../../../hooks/useShareImageUrl'
import { LoadingIcon } from '../../../icons'

import * as socialLinks from '../../../../lib/socialLinks'
import * as analytics from '../../../../lib/analytics'

function openUrl(url) {
  window.open(url, '_blank', 'width=400,height=400', false)
}

const SHARE_TITLE = 'check out my cool design using Leo AI @ app.getleo.ai'
const SHARE_HASHTAGS = ['LeoAI', 'GenAI']

const createInitialItems = () => ({
  linkedin: {
    display: 'Share on LinkedIn',
    isLoading: false,
    icon: () => <LinkedInIcon fontSize="small" />,
    invoke: url =>
      openUrl(
        socialLinks.linkedin({
          url,
          title: SHARE_TITLE,
        })
      ),
  },
  twitter: {
    display: 'Share on Twitter',
    isLoading: false,
    icon: () => <TwitterIcon fontSize="small" />,
    invoke: url =>
      openUrl(
        socialLinks.twitter({
          url,
          text: SHARE_TITLE,
          hashtags: SHARE_HASHTAGS,
        })
      ),
  },
  facebook: {
    display: 'Share on Facebook',
    isLoading: false,
    icon: () => <FacebookIcon fontSize="small" />,
    invoke: url =>
      openUrl(
        socialLinks.facebook({
          url,
          quote: SHARE_TITLE,
          hashtag: SHARE_HASHTAGS,
        })
      ),
  },
  reddit: {
    display: 'Share on Reddit',
    isLoading: false,
    icon: () => <RedditIcon fontSize="small" />,
    invoke: url =>
      openUrl(
        socialLinks.reddit({
          url,
          title: SHARE_TITLE,
        })
      ),
  },
  copy: {
    display: 'Copy Link',
    isLoading: false,
    icon: () => <ContentCopyIcon />,
    invoke: url => navigator.clipboard.writeText(url),
  },
})

const useDropdown = () => {
  const [isOpen, setIsOpen] = useState(false)

  return {
    toggle: () => setIsOpen(!isOpen),
    close: () => setIsOpen(false),
    isOpen: isOpen,
  }
}

export const ShareButton = ({ url, onImageStyle, type }) => {
  const dropdown = useDropdown()
  const [items, setItems] = useState(createInitialItems)
  const { didFetchShareImageUrl, isLoadingShareImageUrl, fetchShareImageUrl, shareImageUrl } = useShareImageUrl()

  function setItemLoading(itemKey) {
    if (!didFetchShareImageUrl) {
      const item = { ...items[itemKey], isLoading: true }
      setItems({ ...items, [itemKey]: item })
    }
  }

  const invokeItem = useCallback(
    async itemKey => {
      const item = items[itemKey]
      await item.invoke(shareImageUrl)

      if (itemKey === 'copy') {
        // Replace the icon
        setItems({
          ...items,
          [itemKey]: {
            ...item,
            isLoading: false,
            icon: () => <DoneIcon />,
          },
        })
      }
    },
    [items, setItems, shareImageUrl]
  )

  function isItemLoading(itemKey) {
    return items[itemKey].isLoading
  }

  useEffect(() => {
    if (didFetchShareImageUrl) {
      setItems(items =>
        Object.entries(items).reduce(
          (acc, entry) => ({
            ...acc,
            [entry[0]]: {
              ...entry[1],
              isLoading: false,
            },
          }),
          {}
        )
      )
    }
  }, [setItems, isLoadingShareImageUrl, didFetchShareImageUrl])

  useEffect(() => {
    if (didFetchShareImageUrl) {
      Promise.all(
        Object.entries(items)
          .filter(([_key, item]) => item.isLoading)
          .map(([key, _item]) => invokeItem(key))
      )
    }
  }, [didFetchShareImageUrl, shareImageUrl, items, invokeItem])

  async function handleItemClick(itemKey) {
    analytics.track('Share Image Button Clicked', { itemKey, url })

    // For now, don't allow multiple loads at once
    if (isLoadingShareImageUrl) {
      return
    }

    if (didFetchShareImageUrl) {
      analytics.track('Share Image Button Clicked', { itemKey: 'copy link', url })
      await invokeItem(itemKey)
      return
    }

    setItemLoading(itemKey)

    if (!isLoadingShareImageUrl) {
      await fetchShareImageUrl(url)
    }
  }

  function handleClicked() {
    dropdown.toggle()
  }

  function handleInnerMouseLeave(_e) {
    dropdown.close()
  }

  return (
    <div>
      <Tooltip title="Share" placement="top">
        <button
          onClick={handleClicked}
          className={onImageStyle ? onImageStyle : clsx(styles.button, styles.shareButton)}
        >
          <ShareIcon className={styles.icon} />
        </button>
      </Tooltip>

      {dropdown.isOpen && (
        <Dropdown
          type={type}
          items={items}
          isItemLoading={isItemLoading}
          onMouseLeave={handleInnerMouseLeave}
          onItemClicked={handleItemClick}
        />
      )}
    </div>
  )
}

const Dropdown = ({ items, onMouseLeave, onItemClicked, isItemLoading, type }) => {
  return (
    <div
      className={type ? clsx(styles.dropdownContent, styles.dropdownContentModule) : styles.dropdownContent}
      onMouseLeave={onMouseLeave}
    >
      {Object.entries(items).map(([key, item]) => (
        <DropdownItem
          key={key}
          display={item.display}
          icon={item.icon}
          isLoading={isItemLoading(key)}
          onClick={() => onItemClicked(key)}
        />
      ))}
    </div>
  )
}

const DropdownItem = ({ onClick, display, icon, isLoading }) => {
  const Icon = icon

  const className = clsx(styles.item, { [styles.loading]: isLoading })

  // The style for this is applied as a child of the dropdownContent
  return (
    <button className={className} onClick={onClick}>
      {isLoading ? <LoadingIcon /> : <Icon />}
      <span>{display}</span>
    </button>
  )
}
