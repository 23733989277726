
import gifBright from '../../assets/icons/Logo_animation_Bright.gif'
import gifDark from '../../assets/icons/Logo_animation_Dark.gif'
import styles from './Spinner.module.scss'

export const SPINNER_COLOR = {
  DARK: 'DARK',
  BRIGHT: 'BRIGHT',
}

export const Spinner = ({ size = 200, color = SPINNER_COLOR.DARK }) => (
  <div className={styles.Spinner} style={{ width: size, height: size }}>
    <img src={color === SPINNER_COLOR.BRIGHT ? gifBright : gifDark} alt="Loading..." className={styles.gif} />
  </div>
)
