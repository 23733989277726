import styled, { keyframes } from "styled-components";

interface chatProps {
  displayChat: boolean;
}
interface chatWindowProps {
  isMessages: boolean;
}

interface chatHistoryProps {
  displayHistory: boolean;
  displayChat: boolean;
}

interface chatSectionProps {
  height: number;
}

export const Wrapper = styled.div`
  min-width: 270px;
  background-color: var(--dark-50);
  display: flex;
`;
export const ChatWrapper = styled.div<chatProps>`
  display: ${(props) => (props.displayChat ? "block" : "none")};
  flex: ${(props) => (props.displayChat ? 3 : 0)};
  //border-left: 1px solid var(--border-dark);
`;

export const ChatContainer = styled.div`
  display: flex;
  justify-content: center;
`;

export const ChatSection = styled.div<chatSectionProps>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 5rem var(--leo-pad) var(--leo-pad);
  height: calc(100vh - 3.2rem);
  @media (max-width: 768px) {
    height: ${({height}) => `calc(${height}px - 3.2rem)`};
  }
  overflow-y: auto;
  background-color: var(--dark-50);
  color: var(--leo-white);
  max-width: 45rem;
  width: 100%;
  position: relative;
`;

export const ChatWindowStyled = styled.div<chatWindowProps>`
  flex: 1;
  overflow-y: ${(props) => props.isMessages && "scroll"};
  position: relative;
`;

export const SessionButtonWrapper = styled.div`
  position: absolute;
  top: 1.9rem;
  right: 1.7rem;
  z-index: 1;
`

export const SessionsButtons = styled.div`
  display: flex;
  align-items: baseline;
  gap: 1rem;
  opacity: 0.6;
`;

export const SessionHistoryButton = styled.span`
  cursor: pointer;
`;

const flyOut = keyframes`
  0% {
    opacity: 1;
    transform: translateY(0);
  }
  100% {
    opacity: 0;
    transform: translateY(100%);
  }
`;

const flyIn = keyframes`
  0% {
    opacity: 0;
    transform: translateY(100%);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
`;

export const ChangingText = styled.div`
  animation: ${flyOut} 0.5s ease;
  animation: ${flyIn} 0.5s ease;
`;
export const LoadingWrapper = styled.div`
  display: flex;
  justify-content: left;
  align-items: center;

  img {
    width: 4rem;
  }
`;

export const SessionsHistoryWrapper = styled.div<chatHistoryProps>`
  width: ${(props) =>
    !props.displayHistory ? 0 : props.displayChat ? "28rem" : "100%"};
  transition: all 0.3s ease;
  position: ${(props) => (!props.displayChat ? "absolute" : "static")};
`;

export const SlackChatWrapper = styled.div`
  position: fixed;
  right: 2rem;
  bottom: 1.7rem;
  //background-color: var(--leo-black);
  //padding: 1rem 2rem; 
`
