/* eslint-disable array-callback-return */
import React, { useEffect, useRef, useState } from 'react'
import { Circle, Layer, Line, Rect, Stage } from 'react-konva'
import clsx from 'clsx'
import editCursor from '../../../../../../assets/icons/edit.png'
import eraserIcon from '../../../../../../assets/icons/circle.png'
import { useDesignInputsStore } from '../../../../../../store/useDesignInputsStore.ts'
import '../../Canvas.scss'

export const DrawingCanvas = ({ selectedShape, stageRef, disabled = false, className, id }) => {
  const { lines, saveLinesToStore } = useDesignInputsStore(store => store)

  // Size of drawing field (70vh)
  const openHeight = window.innerHeight * 0.7
  const openWidth = openHeight * (5 / 4)

  const [canvasWidth, setCanvasWidth] = useState(openWidth)
  const [canvasHeight, setCanvasHeight] = useState(openHeight)

  const containerRef = useRef(null)
  const isDrawing = useRef(false)

  useEffect(() => {
    if (id !== 'preview') return

    // It should work only for Preview block
    const updateSize = () => {
      if (containerRef.current) {
        const boundingRect = containerRef.current.getBoundingClientRect()
        setCanvasWidth(boundingRect.width)
        setCanvasHeight(boundingRect.height)
      }
    }

    const resizeObserver = new ResizeObserver(updateSize)

    if (containerRef.current) {
      resizeObserver.observe(containerRef.current)
    }

    return () => {
      resizeObserver.disconnect()
    }
  }, [id])

  const scaledLines = lines.map(line => {
    const scaleX = canvasWidth / line.originalSize.width
    const scaleY = canvasHeight / line.originalSize.height

    return {
      ...line,
      points: line.points.map((point, index) => (index % 2 === 0 ? point * scaleX : point * scaleY)),
    }
  })

  const handleMouseDown = (e, originalSize) => {
    const stage = e.target.getStage()
    const point = stage.getPointerPosition()

    let newLine = {
      shape: selectedShape,
      points: [],
      originalSize: originalSize,
    }

    if (selectedShape === 'pencil' || selectedShape === 'eraser') {
      newLine.points.push(point.x, point.y)
    } else {
      newLine.points.push(point.x, point.y, point.x, point.y)
    }

    saveLinesToStore([...lines, newLine])

    isDrawing.current = true
  }

  const handleMouseMove = e => {
    if (!isDrawing.current) {
      return
    }
    const stage = e.target.getStage()
    const point = stage.getPointerPosition()

    const newLines = lines.map((line, index) => {
      if (index !== lines.length - 1) {
        return line
      }

      const scaleX = canvasWidth / line.originalSize.width
      const scaleY = canvasHeight / line.originalSize.height
      let endX = point.x / scaleX
      let endY = point.y / scaleY
      const startX = line.points[0]
      const startY = line.points[1]

      if (selectedShape === 'pencil' || selectedShape === 'eraser') {
        return { ...line, points: [...line.points, endX, endY] }
      }

      return { ...line, points: [startX, startY, endX, endY] }
    })

    saveLinesToStore(newLines)
  }

  const handleMouseUp = e => {
    const stage = e.target.getStage()
    const point = stage.getPointerPosition()

    const newLines = lines.map((line, index) => {
      if (index !== lines.length - 1) {
        return line
      }
      const scaleX = canvasWidth / line.originalSize.width
      const scaleY = canvasHeight / line.originalSize.height
      let endX = point.x / scaleX
      let endY = point.y / scaleY

      return { ...line, points: [...line.points, endX, endY] }
    })
    saveLinesToStore(newLines)

    isDrawing.current = false
  }

  return (
    <div className={clsx('canvas-container', !!className && className)} ref={containerRef}>
      <div className="grid-background-container">
        <div
          style={{
            position: 'relative',
            width: '100%',
            height: '100%',
            top: '0',
            left: '0',
            background: 'white',
          }}
        ></div>
      </div>
      <Stage
        ref={stageRef} // assign stageRef here
        width={openWidth}
        height={openHeight}
        onMouseDown={
          disabled
            ? ''
            : e => {
                handleMouseDown(e, { width: canvasWidth, height: canvasHeight })
              }
        }
        onMouseMove={disabled ? '' : handleMouseMove}
        onMouseUp={disabled ? '' : handleMouseUp}
        style={{
          cursor: disabled
            ? ''
            : selectedShape === 'eraser'
            ? `url(${eraserIcon}) 5 5, auto`
            : `url(${editCursor}) 0 37, auto`,
          width: '100%',
          height: '100%',
        }}
      >
        <Layer>
          {scaledLines.map((line, i) => {
            if (line.shape === 'rectangle') {
              return (
                <Rect
                  key={i}
                  x={line.points[0]}
                  y={line.points[1]}
                  width={line.points[2] - line.points[0]}
                  height={line.points[3] - line.points[1]}
                  stroke="#000"
                  strokeWidth={disabled ? 1 : 3}
                />
              )
            }
            if (line.shape === 'circle') {
              // Render circle
              const startX = line.points[0]
              const startY = line.points[1]
              const endX = line.points[2]
              const endY = line.points[3]

              const centerX = (startX + endX) / 2
              const centerY = (startY + endY) / 2

              const canvasWidth = openWidth
              const canvasHeight = openHeight

              const distanceToLeftEdge = centerX
              const distanceToTopEdge = centerY
              const distanceToRightEdge = canvasWidth - centerX
              const distanceToBottomEdge = canvasHeight - centerY

              const maxAvailableWidth = Math.min(distanceToLeftEdge, distanceToRightEdge) * 2

              const maxAvailableHeight = Math.min(distanceToTopEdge, distanceToBottomEdge) * 2

              const maxRadius = Math.min(maxAvailableWidth / 2, maxAvailableHeight / 2)

              const radius = Math.sqrt(Math.pow(Math.abs(endX - startX), 2) + Math.pow(Math.abs(endY - startY), 2)) / 2

              const scaledRadius = Math.min(radius, maxRadius)

              const boundedCenterX = Math.max(scaledRadius, Math.min(centerX, canvasWidth - scaledRadius))
              const boundedCenterY = Math.max(scaledRadius, Math.min(centerY, canvasHeight - scaledRadius))

              return (
                <Circle
                  key={i}
                  x={boundedCenterX}
                  y={boundedCenterY}
                  radius={scaledRadius}
                  stroke="#000"
                  strokeWidth={disabled ? 1 : 3}
                />
              )
            }
            if (line.shape === 'pencil' || line.shape === 'line') {
              return (
                <Line
                  key={i}
                  points={line.points}
                  stroke="#000"
                  strokeWidth={disabled ? 1 : 3}
                  tension={0.5}
                  lineCap="round"
                />
              )
            }
            if (line.shape === 'eraser') {
              return (
                <Line
                  key={i}
                  points={line.points}
                  stroke="#fff" // Use white color for eraser
                  strokeWidth={disabled ? 5 : 10} // Adjust eraser size as needed
                  tension={0.5}
                  lineCap="round"
                  globalCompositeOperation="destination-out" // Use destination-out to erase
                />
              )
            }
            return null
          })}
        </Layer>
      </Stage>
    </div>
  )
}
