import React, {useEffect, useMemo, useState} from 'react'
import {useRandomSuggestions} from '@/components/chatSection/suggestions/suggestionsLists.ts'
import {Card, Wrapper} from './SuggestionsStyle.ts'
import {useChat} from '@/store/useChat.ts'

const Suggestions = React.memo(() => {
  const { setInputText, setIsSuggestionsChosen } = useChat(store => store)
  const { generateRandomSuggestions } = useRandomSuggestions()
  const [catQty, setCatQty] = useState(4)

  const randomCategories: any = useMemo(() => generateRandomSuggestions(4, 1), [])

  const handleResize = () => {
    if ( window.innerWidth >= 600 ) {
      if ( window.innerHeight < 420 ) {
        setCatQty(0)
      } else if ( window.innerHeight < 540 ) {
        setCatQty(2)
      } else {
        setCatQty(4)
      }
    } else {
      if ( window.innerHeight < 540 ) {
        setCatQty(0)
      } else if ( window.innerHeight < 720 ) {
        setCatQty(2)
      } else {
        setCatQty(4)
      }
    }
  }

  useEffect(() => {

    window.addEventListener('resize', handleResize)

    // Initial call to set the correct quantity on first render
    handleResize()

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, []);

  const catToRender = randomCategories.slice(0, catQty)

  return (
    <Wrapper>
      {catToRender.map(( suggestionObject: any ) => {
        return suggestionObject.suggestions.map(( suggestion: any ) => {
          return (
            <Card
              key={suggestion}
              onClick={() => {
                setInputText(suggestion)
                setIsSuggestionsChosen(true)
              }}
            >
              <div>{suggestionObject.title}</div>
              <span>{suggestion}</span>
            </Card>
          )
        })
      })}
    </Wrapper>
  )
})

export default Suggestions
