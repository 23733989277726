import { BASE_URL } from '../lib/config.ts'
import * as observability from '../lib/observability.ts'
import * as analytics from '../lib/analytics.ts'
import * as errors from './errors.ts'
import { handleNotFoundError, handleSchemaValidationError, handleUnknownError } from './error-handlers.ts'
import { createHeaders } from '@/api/utils.ts'
export async function createNewSession(): Promise<any> {
  const url = `${BASE_URL}/api/v3/session`

  const options = {
    method: 'PUT',
    headers: await createHeaders(),
    body: JSON.stringify({
      headline: 'New Session',
      agent: 'desktop',
    }),
  }

  try {
    const response = await fetch(url, options)
    return handleResponse(response, 'PUT')
  } catch (error: any) {
    observability.captureException(`Failed to Create Session`, {
      error,
      tags: {
        url,
        authorization: options.headers.Authorization,
      },
    })

    analytics.track('Create Session Error', { error })

    throw new errors.UnknownApiError(`Error with fetching data from ${url}`)
  }
}

export async function getSessionsList(): Promise<any> {
  const url = `${BASE_URL}/api/v3/session`

  const options = {
    method: 'GET',
    headers: await createHeaders(),
  }

  try {
    const response = await fetch(url, options)
    const data = (await handleResponse(response, 'GET')) || []
    return (data.sessions || []).filter((item: { agent: string }) => item.agent === 'desktop')
  } catch (error: any) {
    observability.captureException(`Failed to Get Sessions List`, {
      error,
      tags: { url, authorization: options.headers.Authorization },
    })

    analytics.track('Get Sessions List Error', { error })

    throw new errors.UnknownApiError(`Error with fetching data from ${url}`)
  }
}

export async function getSessionData(sessionNum: number): Promise<any> {
  const url = `${BASE_URL}/api/v3/session/${sessionNum}`

  const options = {
    method: 'GET',
    headers: await createHeaders(),
  }

  try {
    const response = await fetch(url, options)
    return await handleResponse(response, 'GET')
  } catch (error: any) {
    observability.captureException(`Failed to Get Session Data`, {
      error,
      tags: { url, authorization: options.headers.Authorization },
    })

    analytics.track('Get Session Data Error', { error })

    throw new errors.UnknownApiError(`Error with fetching data from ${url}`)
  }
}

export async function updateSessionData(
  sessionNum: number,
  opts: {
    headline?: string
  }
) {
  const url = `${BASE_URL}/api/v3/session/${sessionNum}`

  const body: any = {}
  if (opts.headline) {
    body.headline = opts.headline
  }

  const options = {
    method: 'POST',
    headers: await createHeaders(),
    body: JSON.stringify(body),
  }

  try {
    const response = await fetch(url, options)
    return handleResponse(response, 'POST')
  } catch (error: any) {
    observability.captureException(`Failed to Update Session Data`, {
      error,
      tags: { url },
    })

    analytics.track('Update Session Data Error', { error })

    throw new errors.UnknownApiError(`Failed to update session data ${url}`)
  }
}

export async function createNewRevision(sessionNum: string): Promise<any> {
  const url = `${BASE_URL}/api/v3/session/${sessionNum}/revision`

  const options = {
    method: 'PUT',
    headers: await createHeaders(),
  }

  try {
    const response = await fetch(url, options)
    return handleResponse(response, 'PUT')
  } catch (error: any) {
    observability.captureException(`Failed to Create Revision`, {
      error,
      tags: { url, authorization: options.headers.Authorization },
    })

    analytics.track('New Revision Error', { error })

    throw new errors.UnknownApiError(`Error with fetching data from ${url}`)
  }
}

async function handleResponse(response: Response, method = 'PUT') {
  if (!response.ok) {
    const error = await response.json()

    const args: any = {
      headers: response.headers,
      url: response.url,
      method,
    }

    if (error.isSchemaValidationError) {
      handleSchemaValidationError(error, args)
    }

    if (response.status === 404) {
      handleNotFoundError(error, args)
    }

    handleUnknownError(error, args)
  }
  return response.json()
}
