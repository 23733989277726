import { getSessionToken } from "@descope/react-sdk";
import {INTRODUCTORY_POP_UP} from "@/src-ideation/lib/config.ts";
import axios from "axios";
import * as analytics from "@/lib/analytics.ts";

const tenant = axios.create({
    baseURL: INTRODUCTORY_POP_UP,
    headers: {
        "Content-Type": "application/json",
    },
});

tenant.interceptors.request.use(
    (config) => {
        const sessionToken = getSessionToken();
        if (sessionToken) {
            config.headers.Authorization = `Bearer ${sessionToken}`;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

export const getTenant = async () => {
   return tenant.get('')
       .then((response) => {
           return response.data
       }).catch((error) => {
           analytics.track("Tenant get Error", { error });
       })
}

export const putTenant = async () => {
    return tenant.put('').catch((error) => {
        analytics.track("Tenant put Error", { error });
    })
}
