
import parse from 'html-react-parser'
import clsx from 'clsx'

interface ArticleProps {
  text: string
  isSpecWindowExpanded: boolean
  extraPadding: boolean
}
export const Article: React.FC<ArticleProps> = ({ text, isSpecWindowExpanded, extraPadding }) => {
  const replacements: { [key: string]: string } = {
    '<html>': '',
    '</html>': '',
    '<Title>': '<h1>',
    '</Title>': '</h1>',
    '<Description>': '<p>',
    '</Description>': '</p>',
    '<Spec>': '<p>',
    '</Spec>': '</p>',
    '<Product Description>': '<figcaption>',
    '</Product Description>': '</figcaption>',
    '^\\n': '', // Remove a newline at the beginning of the text
    '\n{2,}': '<br /><br />', // Replace multiple consecutive newlines with two <br /> tags
    '\n<p>': '<p>', // Remove newline before <p> tag
    // Add more replacements as needed
  }

  // Check if text is undefined or not
  if (!text) {
    return null // Or return a loading state or an error message
  }

  let htmlText = text

  for (const [key, value] of Object.entries(replacements)) {
    htmlText = htmlText.replace(new RegExp(key, 'g'), value)
  }

  return (
    <div
      className={clsx('Article', 'Article-dark')}
      style={{ marginTop: `${isSpecWindowExpanded ? '2vh' : ''}`, padding: extraPadding ? '4vh' : undefined }}
    >
      {parse(text)}
    </div>
  )
}
