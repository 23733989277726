import React, { useRef } from 'react'
import { Image as KonvaImage, Layer, Line, Stage } from 'react-konva'
import eraserIcon from '../../../../../../assets/icons/circle.png'
import { useDesignInputsStore } from '../../../../../../store/useDesignInputsStore.ts'
import styles from './Drawing.module.scss'
import { MASK_IMAGE_ASPECT_RATIO } from '../../../../../../lib/config'

const PAYLOAD_KEY = {
  SOURCE_IMAGE: 'sourceImage',
  ERASER_MASK: 'eraserMask',
}

export const DrawingWorkspace = ({ canvasRef, image }) => {
  const selectedShape = 'eraser'
  const { magicEraserMask, setMagicEraserMask } = useDesignInputsStore(store => store)

  // Size of drawing field (70vh)
  const canvasHeight = window.innerHeight * 0.7
  const canvasWidth = canvasHeight * MASK_IMAGE_ASPECT_RATIO

  const containerRef = useRef(null)
  const isDrawing = useRef(false)

  const handleMouseDown = (e, originalSize) => {
    e.evt.stopPropagation()
    const stage = e.target.getStage()
    const point = stage.getPointerPosition()

    let newLine = {
      shape: selectedShape,
      points: [],
      originalSize: originalSize,
    }

    if (selectedShape === 'eraser') {
      newLine.points.push(point.x, point.y)
    }

    setMagicEraserMask([...magicEraserMask, newLine])
    isDrawing.current = true
  }

  const handleMouseMove = e => {
    if (!isDrawing.current) {
      return
    }
    const stage = e.target.getStage()
    const point = stage.getPointerPosition()

    const newLines = magicEraserMask.map((line, index) => {
      if (index !== magicEraserMask.length - 1) {
        return line
      }

      const scaleX = canvasWidth / line.originalSize.width
      const scaleY = canvasHeight / line.originalSize.height
      let endX = point.x / scaleX
      let endY = point.y / scaleY

      return { ...line, points: [...line.points, endX, endY] }
    })

    setMagicEraserMask(newLines)
  }

  const handleMouseUp = e => {
    e.evt.stopPropagation()
    const stage = e.target.getStage()
    const point = stage.getPointerPosition()

    const newLines = magicEraserMask.map((line, index) => {
      if (index !== magicEraserMask.length - 1) {
        return line
      }
      const scaleX = canvasWidth / line.originalSize.width
      const scaleY = canvasHeight / line.originalSize.height
      let endX = point.x / scaleX
      let endY = point.y / scaleY

      return { ...line, points: [...line.points, endX, endY] }
    })
    setMagicEraserMask(newLines)

    isDrawing.current = false
  }

  // Calculate the scale factor to fit the image within the canvas
  const calculateScaleFactor = () => {
    if (!image) return 1

    const imageAspectRatio = image.width / image.height
    const canvasAspectRatio = canvasWidth / canvasHeight

    return imageAspectRatio > canvasAspectRatio
      ? canvasWidth / image.width // Scale by width
      : canvasHeight / image.height // Scale by height
  }

  const scaleFactor = calculateScaleFactor()

  const scaledLines = magicEraserMask.map(line => {
    const scaleX = canvasWidth / line.originalSize.width
    const scaleY = canvasHeight / line.originalSize.height

    return {
      ...line,
      points: line.points.map((point, index) => (index % 2 === 0 ? point * scaleX : point * scaleY)),
    }
  })

  return (
    <div id="Drawing" className={styles.Drawing} ref={containerRef}>
      <div className={styles.backgroundContainer}>
        <Stage
          ref={canvasRef} // assign stageRef here
          width={canvasWidth}
          height={canvasHeight}
          onMouseDown={e => {
            handleMouseDown(e, { width: canvasWidth, height: canvasHeight })
          }}
          onMouseMove={handleMouseMove}
          onMouseUp={handleMouseUp}
          style={{
            cursor: `url(${eraserIcon}) 5 5, auto`,
            width: '100%',
            height: '100%',
          }}
        >
          <Layer id={PAYLOAD_KEY.SOURCE_IMAGE}>
            <KonvaImage image={image} width={image?.width * scaleFactor} height={image?.height * scaleFactor} />
          </Layer>
          <Layer id={PAYLOAD_KEY.ERASER_MASK}>
            {scaledLines.map((line, i) => {
              if (line.shape === 'eraser') {
                return (
                  <Line
                    key={i}
                    points={line.points}
                    stroke="#fff" // Use white color for eraser
                    strokeWidth={29} // The minimal size to trigger change from MJ by only 1 circle
                    tension={0.5}
                    lineCap="round"
                    // globalCompositeOperation="destination-out" // Use destination-out to erase
                  />
                )
              }
              return null
            })}
          </Layer>
        </Stage>
      </div>
    </div>
  )
}
