import React from "react";
import ReactDOM from "react-dom/client";
import {RouterProvider} from "react-router-dom";
import {AuthProvider} from "@descope/react-sdk";
import {router} from "./router/Router.tsx";
import {ThemeProvider} from "@mui/material/styles";
import {darkTheme} from "./styles/theme.ts";
import {ThemeProvider as StyledThemeProvider} from '@emotion/react'
import {LeoThemeProvider} from '@/src-ideation/hooks/useThemeContext.jsx'
import {QueryClient, QueryClientProvider} from '@tanstack/react-query'
import {
    FeatureFlagsContext,
    createDefaultFeatureFlags,
} from "./lib/featureFlags.ts";

import * as analytics from "./lib/analytics";
import * as observability from "./lib/observability";
import {ThemeProvider as StyledComponentsThemeProvider} from 'styled-components'
import '@/src-ideation/styles/index.scss'
import InputWindow from "@/components/inputWindow/InputWindow.tsx";

observability.init();
analytics.init();

const queryClient = new QueryClient()

const urlParams = new URLSearchParams(window.location.search);
const isInputWindow:any = urlParams.get('inputWindow');

const App = () => {
    React.useEffect(() => {
        analytics.track("Component Mounted", {
            component: "App",
        });
    }, []);

    return (
        <React.StrictMode>
            <AuthProvider
                projectId="P2S4sM8KKC0gdOu0eBgmT4v0gfvf"
                baseUrl="https://auth.app.getleo.ai"
            >
                <StyledComponentsThemeProvider theme={darkTheme}>
                    <ThemeProvider theme={darkTheme}>
                        <StyledThemeProvider theme={darkTheme}>
                            <LeoThemeProvider theme={darkTheme}>
                                <QueryClientProvider client={queryClient}>
                                    <FeatureFlagsContext.Provider value={createDefaultFeatureFlags()}>
                                        <RouterProvider router={router}/>
                                    </FeatureFlagsContext.Provider>
                                </QueryClientProvider>
                            </LeoThemeProvider>
                        </StyledThemeProvider>
                    </ThemeProvider>
                </StyledComponentsThemeProvider>
            </AuthProvider>
        </React.StrictMode>
    );
};
//
// const root = ReactDOM.createRoot(document.getElementById('root'))
//
// root.render(<App />)


ReactDOM.createRoot(document.getElementById("root")!).render(
    <React.StrictMode>
        {isInputWindow ? <InputWindow/> : <App/>}
    </React.StrictMode>,
);
