import { create } from 'zustand'

export type State = {
  sessionNum?: number
  setSessionNum: (sessionNum: number | undefined) => void

  revisionNum?: number
  setRevisionNum: (revisionNum: number | undefined) => void

  isGeneratedAlready?: boolean
  setIsGeneratedAlready: (isGeneratedAlready: boolean) => void
}

export const useSessionStore = create<State>(set => ({
  sessionNum: undefined,
  setSessionNum: (sessionNum: number | undefined) => set({ sessionNum }),

  revisionNum: undefined,
  setRevisionNum: (revisionNum: number | undefined) => set({ revisionNum }),

  isGeneratedAlready: false,
  setIsGeneratedAlready: (isGeneratedAlready: boolean) => set({ isGeneratedAlready }),
}))
