
import LikeIcon from '../../assets/icons/like.svg?react'
import FilledLikeIcon from '../../assets/icons/like-filled.svg?react'

export const LikeButton = ({ liked, clickHandler, onImageStyle = null }) => {
  return (
    <button className={onImageStyle ? onImageStyle : 'LikeButton'} onClick={event => clickHandler(event, 'like')}>
      {liked ? <FilledLikeIcon /> : <LikeIcon />}
    </button>
  )
}
