import React, { useRef, useState } from 'react'
import CloseIcon from '../../../../../assets/icons/close.svg?react'
import { DESIGN_INPUTS_SOURCE } from '../../../../../constants/index.ts'
import { useQueue } from '../../../../../hooks/useQueue'
import { useUndoRedoShortcut } from '../../../../../hooks/useUndoRedoShortcut'
import { useDesignInputsStore } from '../../../../../store/useDesignInputsStore.ts'
import { convertCanvasLayersToBase64Images } from '../../../../../utils/convertCanvasToImage'
import { Button } from '../../../../common/Button'
import { ANIMATION_TIMEOUT, animateImage } from '../../animateImage'
import { DrawingWorkspace } from './Drawing'
import { ModalFooter } from '../../../../common/Canvas/ModalFooter'
import { CanvasSideBar } from './CanvasSideBar'
import styles from './Canvas.module.scss'
import { useGalleryStore } from '../../../../../store/useGalleryStore'
import { findVariation } from '../../../../../utils'
import useImage from 'use-image'

// This all concept of the Canvas and the images is really hard to follow. Sorry.
export const Canvas = ({ handleClose, imageUrl }) => {
  const canvasRef = useRef(null) // Magic eraser
  const { addToQueue } = useQueue()
  const { magicEraserMask, setMagicEraserMask, setMagicEraserPayload, magicEraserImage } = useDesignInputsStore(
    store => store
  )
  const [image] = useImage(magicEraserImage.src)
  const [selectedShape, setSelectedShape] = useState('eraser')
  const galleryStore = useGalleryStore()

  const handleShapeSelect = shape => setSelectedShape(shape)

  const undoLastDrawing = () => setMagicEraserMask(magicEraserMask.slice(0, magicEraserMask.length - 1))

  useUndoRedoShortcut(undoLastDrawing)

  const clearCanvas = e => {
    e.stopPropagation()
    setMagicEraserMask([])
  }

  const createPayload = async () => {
    // The function convertCanvasLayersToBase64Images outputs an object with
    // "sourceImage" (base64 data) and "eraseMask" (base64 data).
    // The payload needs only the eraseMask and the id of the response generated the source image.
    // So it's a bit of a mess here.
    // Also, not sure this is where we should create the payload.

    // This was previously iterating on ALL layers.
    // Now, it accepts which layer to convert.
    // It cannot get the images from the server due to CORS issues
    // which I cannot resolve ATM.
    // Still, it is unneeded to get them, let's just convert the relevant layer (mask).
    const imageData = await convertCanvasLayersToBase64Images(
      ['eraserMask'],
      canvasRef.current,
      canvasRef.current.attrs.width,
      canvasRef.current.attrs.height
    )

    const image = findVariation(imageUrl, galleryStore.responses)

    setMagicEraserPayload({
      mask: imageData.eraserMask,
      imageId: image.id,
    })
  }

  const saveCanvas = async e => {
    e.stopPropagation()

    // Check if user not added mask
    if (!magicEraserMask.length) {
      return handleClose()
    }

    // This is the original
    // const image = await createImageFromCanvas(canvasRef.current)

    // This is new:
    // We mimic some behaviour of createImageFromCanvas.
    // Basically we already have the image, so we don't need to create it from the canvas.
    // But, to fix the animation, we need to add it to the Drawing container.
    const container = document.getElementById('Drawing')
    container.appendChild(image)

    animateImage({ image })

    setTimeout(() => {
      addToQueue({ type: DESIGN_INPUTS_SOURCE.MAGIC_ERASER, image })
    }, ANIMATION_TIMEOUT)

    // Create payload before closing modal
    // TODO: this should be changed, why we create the payload here?
    // Ideally, we would have the information in the stores.
    await createPayload()
    handleClose()
  }

  const tools = {
    undo: true,
    magicEraser: true,
  }

  return (
    <>
      <div className={styles.Canvas}>
        <Button className={styles.closeBtn} onClick={() => handleClose()}>
          <CloseIcon />
        </Button>

        <div className={styles.content}>
          <CanvasSideBar {...{ handleShapeSelect, selectedShape, tools, undoLastDrawing }} />
          <div className={styles.drawingContainer}>
            <DrawingWorkspace {...{ canvasRef, image }} />
          </div>
        </div>
        <ModalFooter clearHandler={clearCanvas} saveHandler={saveCanvas} />
      </div>
    </>
  )
}
