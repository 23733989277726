import React, { useState } from "react";
import { Tooltip } from "@mui/material";
import {
  Container,
  Item,
  Items,
} from "@/components/simpleToolbar/SimpleToolbarStyle.ts";
import { TooltipContent } from "@/components/chatSection/message/MessageStyle.ts";

type ClickEvent = {
  id: string;
};

type ClickEventHandler = (event: ClickEvent) => void;

type ItemSettings = {
  id: string;
  tooltip?: string;
  component: React.ComponentType;
  boldComponent: React.ComponentType;
};

export type Align = "right" | "left";

type Props = {
  items: ItemSettings[];
  onClick: ClickEventHandler;
  align?: Align;
};

export default function SimpleToolbar(props: Props) {
  const { items, onClick, align = "right" } = props;
  const [clickedSentiment, setClickedSentiment] = useState<string>("");

  function handleItemClicked(item: ItemSettings) {
    setClickedSentiment(item.id);
    onClick({ id: item.id });
  }

  return (
    <Container align={align}>
      <Items>
        {items.map((item, index) => (
          <Item key={index} onClick={() => handleItemClicked(item)}>
            <Tooltip
              title={<TooltipContent>{item.tooltip}</TooltipContent>}
              placement="top"
              disableInteractive
            >
              {/* This div is here only because the Tooltip needs it to be active */}
              <div>
                {clickedSentiment === item.id ? (
                  <item.boldComponent />
                ) : (
                  <item.component />
                )}
              </div>
            </Tooltip>
          </Item>
        ))}
      </Items>
    </Container>
  );
}
