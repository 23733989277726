import styled from 'styled-components'

interface toolbarProps {
  showToolbar: boolean
}

interface textExpansionProps {
  showText: boolean
}

const MessageContainer = styled.div`
  padding: 10px;
  border-radius: 5px;
  //margin-bottom: 1rem;
  text-align: left;
`

export const UserMessage = styled(MessageContainer)`
  background-color: var(--dark-100);
  border-radius: 12px 12px 12px 0;
  color: var(--purple-50);

  p {
    margin: 0.5rem;
    font-size: 1rem;
  }
`

export const AssistantMessage = styled(MessageContainer)`
  p {
    font-size: 1rem;
    line-height: 1.5;
    strong {
      font-size: 1rem;
    }
  }
`

export const ImageDescriptionMessage = styled.div`
  background-color: transparent;
  padding: 1vh;
  overflow-x: hidden;
  border: 1px solid lightgray;
  border-radius: var(--leo-radius);
  p {
    font-size: 1rem;
  }
`

export const MessageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  word-break: break-word;
  margin: auto;

  a {
    color: white;
    font-weight: 700;
  }

  table {
    border: 1px solid;
    margin-bottom: 1rem;
  }

  th,
  td {
    border: 1px solid;
    padding: 0.5rem;
  }
`

export const ImageDescriptionBanner = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`

export const ToolbarWrapper = styled.div<toolbarProps>`
  display: flex;
  align-items: center;
  height: ${props => (props.showToolbar ? '2rem' : 0)};
  overflow: hidden;
  transition: height 0.5s ease;
`

export const TextExpansion = styled.div<textExpansionProps>`
  max-height: 100%;
  height: ${props => (props.showText ? '100%' : 0)};
  overflow: hidden;
  //transition: height 0.5s ease;
`

export const ReadMoreButton = styled.div`
  cursor: pointer;
  color: var(--purple-50);
  font-weight: 700;
  font-size: 1.1rem;
  text-decoration: underline;
  margin-bottom: 1rem;
`

export const ReadMoreTitle = styled.div`
  font-weight: 700;
  font-size: 1.1rem;
  margin: 1.2rem 0;
`
export const StyledLink = styled.a`
  background: var(--purple-25);
  color: var(--purple-150) !important;
  padding: 1px 7px 0 6.5px;
  border-radius: 6px;
  text-decoration: unset;
  display: inline-block;
  width: 1.2rem;
  height: 1.1rem;
  font-size: 0.7rem;
  text-align: center;
  line-height: 1.5;
  margin: 0 2px;
`

export const TooltipContent = styled.div`
  background: var(--dark-opacity-150);
  border-radius: 8px;
  padding: 0.6rem;
  font-size: 0.8rem;

  a {
    color: rgba(255, 255, 255, 0.7);
  }
`

export const GoToIdeationWrapper = styled.span`
  display: flex;
  gap: 0.4rem;
  margin-bottom: 1rem;
`
export const GoToIdeationButton = styled.a`
  color: white;
  font-weight: 700;
`
