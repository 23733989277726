import React, { useEffect, useRef, useState } from 'react'
import CloseIcon from '../../../../assets/icons/close.svg?react'
import { DESIGN_INPUTS_SOURCE } from '../../../../constants/index.ts'
import { useQueue } from '../../../../hooks/useQueue'
import { useUndoRedoShortcut } from '../../../../hooks/useUndoRedoShortcut'
import { useDesignInputsStore } from '../../../../store/useDesignInputsStore.ts'
import { Button } from '../../../common/Button'
import { CanvasSideBar } from './components/CanvasSideBar'
import { ModalFooter } from '../../../common/Canvas/ModalFooter'
import { DrawingCanvas } from './components/DrawingCanvas'
import './Canvas.scss'
import { useAudit } from '../../../../store/useAudit'

export const Canvas = ({ canvasOpen, setCanvasOpen }) => {
  // 2 different refs for Drawing and Preview containers
  const stageRef = useRef(null)
  const stagePreviewRef = useRef(null)
  const { lines, saveLinesToStore, setSketchBase64, sketchBase64 } = useDesignInputsStore(store => store)
  const { addToQueue, removeLastItem } = useQueue()
  const [selectedShape, setSelectedShape] = useState('pencil')
  const audit = useAudit()

  useEffect(() => {
    // Save lines to store to prevent clearing state if Canvas was changed to VariantImage
    saveLinesToStore(lines)

    if (!!lines.length) {
      addToQueue({ type: DESIGN_INPUTS_SOURCE.SKETCH, image: lines })
    } else {
      removeLastItem()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lines, saveLinesToStore])

  useEffect(() => {
    const handleClickOutsideCanvas = e => {
      if (canvasOpen && !e.target.closest('.open-canvas-container')) {
        setCanvasOpen(false)
      }
    }

    document.addEventListener('click', handleClickOutsideCanvas)

    return () => {
      document.removeEventListener('click', handleClickOutsideCanvas)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [canvasOpen])

  useEffect(() => {
    const convertToImage = async (desiredWidth, desiredHeight) => {
      if (lines.length === 0) return

      return new Promise((resolve, _reject) => {
        const stage = stageRef.current
        if (!stage) {
          return
        }

        const originalDataUrl = stage.toDataURL()

        let img = new Image()
        img.src = originalDataUrl
        img.onload = () => {
          const canvas = document.createElement('canvas')
          canvas.width = desiredWidth // your desired width
          canvas.height = desiredHeight // your desired height
          const ctx = canvas.getContext('2d')
          ctx.drawImage(img, 0, 0, canvas.width, canvas.height)

          const resizedDataUrl = canvas.toDataURL()
          resolve(resizedDataUrl)
        }
      })
    }

    ;(async () => {
      // Convert the lines object to a Buffer
      const desiredWidth = 512
      const desiredHeight = 512
      const imageData = await convertToImage(desiredWidth, desiredHeight)
      setSketchBase64(imageData)
    })()
  }, [lines, setSketchBase64])

  function auditCurrentSketch() {
    audit.insertOrReplaceIfSameTag({
      tag: 'sketched-image',
      encoding: 'base64',
      data: sketchBase64,
    })
  }

  const handleShapeSelect = shape => setSelectedShape(shape)

  const undoLastDrawing = () => saveLinesToStore(lines.slice(0, lines.length - 1))

  useUndoRedoShortcut(undoLastDrawing)

  const clearHandler = () => saveLinesToStore([])

  const saveHandler = () => {
    setCanvasOpen(false)
    auditCurrentSketch()
  }

  // Just moved some code.
  // Practically the close will also save the sketch so we want to also audit it.
  const closeHandler = () => {
    setCanvasOpen(false)
    auditCurrentSketch()
  }

  /** Smaller canvas */
  const renderPreviewContent = () => {
    if (lines.length === 0) return null

    return (
      <div
        className="canvas-preview"
        onClick={e => {
          e.stopPropagation()
          if (!canvasOpen) {
            setCanvasOpen(true)
          }
        }}
      >
        <DrawingCanvas className="preview-canvas" stageRef={stagePreviewRef} id="preview" disabled={true} />
      </div>
    )
  }

  return (
    <>
      <div className={`canvas-overlay ${canvasOpen ? 'open' : ''}`}>
        <div className="open-canvas-container">
          <Button className="close-button" onClick={closeHandler}>
            Close <CloseIcon />
          </Button>

          <div className="canvas-section">
            {/* Side Bar of the canvas */}
            <CanvasSideBar undoLastDrawing={undoLastDrawing} handleShapeSelect={handleShapeSelect} />
            {/* Drawing section of the canvas */}
            <div className="drawing-canvas-container">
              <DrawingCanvas selectedShape={selectedShape} stageRef={stageRef} />
            </div>
          </div>
          {/* Bottom part of the canvas */}
          <ModalFooter {...{ clearHandler, saveHandler }} />
        </div>
      </div>
      <div className="preview-container">{renderPreviewContent()}</div>
    </>
  )
}
