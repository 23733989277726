import { useState } from 'react'
import { submitInput } from '@/lib/app.ts'
import { ChatButton } from '@/components/chatSection/chatButton/ChatButton.tsx'
import { ContainerInput, InputField } from '@/components/inputWindow/InputWindowStyle.ts'

const InputWindow = () => {
  const [input, setInput] = useState('')

  const handleSubmit = () => {
    submitInput(input)
  }

  return (
    <ContainerInput>
      <InputField type="text" value={input} onChange={e => setInput(e.target.value)} />
      <ChatButton {...{ onClick: handleSubmit }} />
    </ContainerInput>
  )
}
export default InputWindow
