
import clsx from 'clsx'
import styles from './Feedback.module.scss'

export const Feedback = ({ isDark, setShowFeedbackModal }) => {
  const Click = e => {
    e.preventDefault()
    setShowFeedbackModal()
  }
  return (
    <div className={clsx(styles.Feedback, isDark && styles.dark)}>
      <p>
        Leo Alpha v3.0 | We ❤️ {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <a href="#" onClick={e => Click(e)} target="_blank" rel="noopener noreferrer">
          Feedback
        </a>
      </p>
    </div>
  )
}
