import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import {
  Box,
  Select,
  MenuItem,
  // TextField
} from '@mui/material'
import { useFetchData } from '../../api'
import { SPINNER_COLOR, Spinner } from '../../components/Spinner'
import { track } from '../../lib/analytics'
import { IMAGE_LIST_ENDPOINT } from '../../lib/config'
import { GalleryGridComponent } from '../../components/Gallery/GellaryGridComponent'
// import SearchIcon from '@mui/icons-material/Search';

const compareEntries = (a, b) => new Date(b.timestamp) - new Date(a.timestamp)

const menuItems = [
  { value: 'desc', label: 'Latest' },
  // { value: 'newest', label: 'Newest' },
  { value: 'asc', label: 'Oldest' },
]

export const Gallery = () => {
  const { fetchData } = useFetchData()

  // Entries are the objects the holds image information.
  // They are the standard backend object we work in alongside a timestamp.
  // entry = { timestamp, image, upscale }
  const [entries, setEntries] = useState([])
  const [loading, setLoading] = useState(true)
  const [currenItem, setCurrentItem] = React.useState(menuItems[0].value)
  // const imagesToShow = entries.map(({ upscale }) => upscale.map(({ uri }) => uri)).flat()

  useEffect(() => {
    fetchData({ url: IMAGE_LIST_ENDPOINT, method: 'GET', params: { limit: 10000, sorting: currenItem } })
      .then(result => {
        setEntries(result.images.sort(compareEntries))
        setLoading(false)
      })
      .catch(error => {
        console.error(error)
        toast.error(error?.message || 'Something went wrong...')
      })

    track('Component Mounted', { component: 'Gallery Page' })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currenItem])

  const handleChange = event => {
    setCurrentItem(event.target.value)
  }

  return (
    <div className="container" style={{ marginInline: '5vw' }}>
      <Box
        display={'flex'}
        justifyContent={'end'}
        alignItems={'center'}
        flexWrap={'wrap'}
        marginTop={'3.8vh'}
        marginBottom={'2.2vh'}
      >
        {/*<TextField*/}
        {/*    disabled*/}
        {/*    sx={{*/}
        {/*        '.MuiOutlinedInput-input': {*/}
        {/*            padding: '0.9vh 6.6vh 0.9vh 1.8vh!important',*/}
        {/*            fontSize: '1.6vh',*/}
        {/*        }*/}
        {/*    }}*/}
        {/*    placeholder={'Search'}*/}
        {/*    variant={"outlined"}*/}
        {/*    InputProps={{*/}
        {/*        endAdornment: (*/}
        {/*            <SearchIcon fill={'red'}/>*/}
        {/*        ),*/}
        {/*    }}*/}
        {/*/>*/}
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={currenItem}
          label={false}
          sx={{
            '& legend': {
              width: 0,
            },
            '.MuiOutlinedInput-input': {
              padding: '0.9vh 6.6vh 0.9vh 1.8vh!important',
              fontSize: '1.6vh',
            },
          }}
          onChange={handleChange}
        >
          {menuItems.map((item, index) => (
            <MenuItem key={index} value={item.value}>
              {item.label}
            </MenuItem>
          ))}
        </Select>
      </Box>
      <Box display="flex" justifyContent="center" alignItems="center" flexWrap="wrap" height="79vh">
        {loading ? (
          <div style={{ opacity: 0.5 }}>
            <Spinner {...{ color: SPINNER_COLOR.BRIGHT }} />
          </div>
        ) : (
          <GalleryGridComponent data={entries} />
          // <GalleryComponent {...{ images: imagesToShow }} />
        )}
      </Box>
    </div>
  )
}
