import React, { useEffect } from 'react'
import styles from './TechnicalSpecs.module.css'
import useDocsStore from '../../../store/useDocsStore'
import { Spinner, SPINNER_COLOR } from '../../Spinner'
import clsx from 'clsx'
import { useTechSpecStore } from '../../../store/useTechSpecStore'
import LoadingBar from '../../LoadingBar/LoadingBar'

export type ContentChangeHandler = (content: string) => void

export interface Props {
  name: string
  isDialog?: boolean
  onContentChange: ContentChangeHandler
}

export default function TechnicalSpecs(props: Props) {
  const docName = props.name
  const state = useDocsStore()
  const { currentVersionIndex } = useTechSpecStore(store => store)
  const data: any = state.getDoc(docName, currentVersionIndex)

  const dataArtifactsAmount = Object.keys(data?.artifacts || {}).length

  const artifactNamesAmount = (
    (state.docs &&
      state.docs['technical-spec'] &&
      state.docs['technical-spec']?.[currentVersionIndex]?.artifactNames) ||
    []
  ).length

  const mappedData = (data: any) => {
    const noResponseObject: any = {}
    Object.keys(data).forEach((key: any) => {
      const firstKey: any = Object.keys(data[key])[0]
      noResponseObject[firstKey] = data[key][firstKey]
    })
    return noResponseObject
  }

  const mappedObj = mappedData(data ? data.artifacts : {})

  const contentRef = React.useRef<any>(null)

  useEffect(() => {
    props.onContentChange(contentRef.current?.innerHTML)
  }, [contentRef, props])

  if (!state.hasDoc(docName, currentVersionIndex)) {
    return <></>
  }

  const renderContent = (
    content: any,
    elementIndex?: any,
    parentIndex?: any
  ): JSX.Element | JSX.Element[] | string | any => {
    if (Array.isArray(content)) {
      return content.map((item, arrayIndex: number) => renderContent(item, arrayIndex + 1 || 1, parentIndex || 1))
    } else if (typeof content === 'object') {
      return Object.keys(content || {}).map((key, index) => {
        const sectionIndex: any = parentIndex ? `${parentIndex}.${index + 1}` : `${index + 1}`

        const titleName = key.replace('.response', '').split('_').join(' ')

        return (
          <>
            <h3
              style={{
                textAlign: 'left',
                padding: '0.5vh 0 0 1vh',
                paddingLeft: parentIndex ? parentIndex.length + 'vh' : '0vh',
              }}
              key={`${key}_sub-title_${index}${parentIndex}`}
              className={styles['sub-title']}
            >
              {`${sectionIndex}. ${titleName}:`}
            </h3>

            <div key={`${key}_${index}_${parentIndex}`}>{renderContent(content[key], index + 1, sectionIndex)}</div>
          </>
        )
      })
    } else {
      return (
        <div
          style={{
            textAlign: 'left',
            paddingLeft: parentIndex ? parentIndex.length + 'vh' : '0vh',
          }}
          key={content + parentIndex}
          className={styles['content']}
        >
          {parentIndex && `${parentIndex}.`}
          {elementIndex && `${elementIndex} `}
          {content}
        </div>
      )
    }
  }

  return (
    <div className={clsx(styles.container, props.isDialog && styles.isDialog)}>
      <div ref={contentRef}>{renderContent(mappedObj)}</div>
      <div className={styles.loadingContainer}>
        {state.isDocLoading(docName, currentVersionIndex) && (
          <div className={styles.loadingWrapper}>
            <Spinner {...{ color: SPINNER_COLOR.BRIGHT }} />
            <LoadingBar total={artifactNamesAmount} value={dataArtifactsAmount} text={'(~1 min per section)'} />
          </div>
        )}
      </div>
    </div>
  )
}
