import styled from 'styled-components'

interface optionProps {
  selected: boolean
}

interface buttonProps {
  disable: boolean
}

export const Container = styled.div`
  width: 100%;
  border-radius: 12px;
  padding: 1rem;
  background: var(--dark-100);
  margin-top: 1rem;
`

export const OptionsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  margin: 1rem 0;
`

export const Title = styled.div`
  color: #ffffff;
  margin-bottom: 1rem;
`

export const Option = styled.span<optionProps>`
  color: ${props => (props.selected ? 'var(--purple-50)' : 'rgb(255, 255, 255, 0.8)')};
  border: 1px solid;
  border-radius: 8px;
  padding: 0.5rem;
  cursor: pointer;
  border-color: ${props => (props.selected ? 'var(--purple-50)' : 'rgb(255, 255, 255, 0.8)')};
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
`
export const CloseButton = styled.div`
  display: flex;
  justify-content: flex-end;
  cursor: pointer;
  margin-bottom: 0.5rem;
`
export const SubmitText = styled.span`
  font-size: 1.1rem;
  flex: 0 0 70%;
`

export const OtherContentInput = styled.div<buttonProps>`
  width: 100%;
  display: flex;
  align-items: end;
  gap: 5px;

  button {
    cursor: ${props => (props.disable ? 'default' : 'pointer')};
    background-color: var(--purple-100);
    color: white;
    font-weight: 700;
    border: none;
    border-radius: 8px;
    padding: 0.5rem;
    opacity: ${props => (props.disable ? 0.3 : 1)};
  }

  input {
    background: transparent;
    border: 1px solid rgb(152, 149, 154, 0.3);
    color: white;
    padding: 0.5rem;
    flex: 0 0 70%;
    border-radius: var(--leo-radius);
  }
`
