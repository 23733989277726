import styled from 'styled-components'

export interface PageNumberProps {
  currentPage?: boolean | undefined
  hide?: boolean | undefined | any
}

export interface ButtonProps {
  disabled?: boolean | undefined
}

export interface WrapperProps {
  positionStatic?: boolean | undefined
}

export const Wrapper = styled.div<WrapperProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${props => props.theme.palette.primary.contrastText};
  gap: 1vh;
  position: ${props => (props.positionStatic ? 'static' : 'absolute')};
  bottom: 15vh;
  padding: 1rem 0;
`

export const PageNumber = styled.span<PageNumberProps>`
  border: 1px solid;
  width: 2rem;
  height: 2rem;
  border-radius: 5px;
  display: ${props => (props.hide ? 'none' : 'flex')};
  justify-content: center;
  align-items: center;
  font-size: 1.4vh;
  font-weight: 700;
  letter-spacing: 1px;
  cursor: pointer;
  opacity: ${props => (props.currentPage ? 1 : '20%')};
`

export const Arrow = styled.button<ButtonProps>`
  cursor: ${props => !props.disabled && 'pointer'};
  background: transparent;
  border: none;
  opacity: ${props => (props.disabled ? 0.3 : 1)};
`
