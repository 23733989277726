import { getSessionData, getSessionsList } from "@/api/sessions.ts";
import React, { useEffect, useState } from "react";
import Close from "@/assets/icons/close.svg?react";

import {
  CloseButton,
  NewSessionButton,
  ComponentWrapper,
  ListWrapper,
} from "@/components/sessionsHistoryList/SessionsHistoryListStyle.ts";
import CreateNewSession from "@/components/sessions/createNewSession/CreateNewSession.tsx";
import { useChat } from "@/store/useChat.ts";
import { useSessionStore } from "@/store/useSessionStore.ts";
import SessionHistoryListItem from "./SessionHistoryListItem";
import { useChatApi } from "@/api/chat.ts";
// import { initialMessage } from "@/constants/msgEnums.ts";

interface Props {
  onNewSessionClicked: () => void;
  displayHistory: boolean;
  displayChat: boolean;
}

const SessionsHistoryList = (props: Props) => {
  const [sessions, setSessions] = useState<any>([]);
  const { postProcessMessages } = useChatApi();

  const { setMessages } = useChat((store) => store);
  const { setSessionNum, setRevisionNum } = useSessionStore((store) => store);

  useEffect(() => {
    const fetchSessions = async () => {
      const ans = await getSessionsList();
      setSessions(ans);
    };

    fetchSessions();
  }, []);

  const onSessionClick = async (sessionNum: number) => {
    const sessionData = await getSessionData(sessionNum);

    let historyMessages: any[] = [];

    sessionData.session.revisions.map((revision: any) => {
      const revisionMessages = revision.chats.sort(
        (a: any, b: any) => a.chatNum - b.chatNum,
      );
      historyMessages = [...historyMessages, ...revisionMessages];
    });

    if (historyMessages.length !== 0) {
      const messagesWithCitations = await postProcessMessages(historyMessages);
      setMessages(messagesWithCitations);
    }

    setSessionNum(sessionNum);
    setRevisionNum(1);
    props.onNewSessionClicked();
  };

  return (
    <ComponentWrapper
      displayHistory={props.displayHistory}
      displayChat={props.displayChat}
    >
      <CloseButton onClick={props.onNewSessionClicked}>
        <Close />
      </CloseButton>
      <NewSessionButton onClick={props.onNewSessionClicked}>
        <CreateNewSession buttonWithText />
      </NewSessionButton>
      <ListWrapper>
        {(sessions || []).map((session: any, index: number) => (
          <SessionHistoryListItem
            key={index}
            session={session}
            onSessionClick={() => onSessionClick(session.sessionNum)}
          />
        ))}
      </ListWrapper>
    </ComponentWrapper>
  );
};

export default React.memo(SessionsHistoryList);
