
import { Tooltip } from '@mui/material'
import ArrowsIcon from '../../../../assets/icons/arrow_split.svg?react'
import { DESIGN_INPUTS_SOURCE } from '../../../../constants/index.ts'
import { useQueue } from '../../../../hooks/useQueue'
import { useGalleryStore } from '../../../../store/useGalleryStore'
import { findVariation } from '../../../../utils'
import { ANIMATION_TIMEOUT, animateImage } from '../animateImage'
import styles from './styles.module.scss'

export const VariationButton = ({ type, imageRef, classProp, onClick }) => {
  const { setVariantImage, setVariantPayload, responses } = useGalleryStore(store => store)
  const { addToQueue } = useQueue()

  const generateVariants = event => {
    event.stopPropagation() // Prevent to open modal window

    !type && animateImage({ image: imageRef.current })

    const selectedUpscaleImage = findVariation(imageRef.current.src, responses)
    // For UI
    setTimeout(
      () => {
        setVariantImage(selectedUpscaleImage)
        addToQueue({ type: DESIGN_INPUTS_SOURCE.VARIANT, image: selectedUpscaleImage })
      },
      type ? 0 : ANIMATION_TIMEOUT
    )
    // For API
    setVariantPayload({ ...selectedUpscaleImage })
  }

  const hendleClick = e => {
    onClick && onClick()
    return generateVariants(e)
  }

  return (
    <Tooltip title="Generate variants" placement="top">
      <button onClick={hendleClick} className={classProp ? classProp : styles.button}>
        {type ? 'Variants' : ''}
        <ArrowsIcon />
      </button>
    </Tooltip>
  )
}
