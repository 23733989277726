
import { Button } from '../../../components/common/Button'
import { Box } from '@mui/material'
import styles from './Settings.module.scss'

export const Settings = () => {
  const removeHandler = () => {
    // TODO remove API
  }
  return (
    <Box display="flex" justifyContent="flex-end" className={styles.Settings}>
      <Button variant="outlined" color="warning" onClick={removeHandler} rounded>
        Delete account
      </Button>
    </Box>
  )
}
