import { create } from 'zustand'

export const useAppState = create(set => ({
  openEraserCanvas: false,
  setOpenEraserCanvas: openEraserCanvas => set({ openEraserCanvas }),

  isFirstSeenApp: false,
  setIsFirstSeenApp: isFirstSeenApp => set({ isFirstSeenApp }),
  firstSeenElements: {},
  setFirstSeenElements: firstSeenElements => set(store => ({ ...store.firstSeenElements, firstSeenElements })),

  isOnboardingOpens: false,
  setIsOnboardingOpens: isOnboardingOpens => set({ isOnboardingOpens }),
}))
