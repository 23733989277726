import PrevIcon from '../../../assets/icons/prev-icon.svg?react'
import NextIcon from '../../../assets/icons/next-icon.svg?react'
import { Wrapper, Arrow, PageNumber } from './sessionsPaginationStyle'
export interface Props {
  numOfPages: number
  currentPage: number
  pagesNumbersAmount: number
  pad: number
  setPage: (currentPage: number, arrowNavigation?: boolean) => void
  positionStatic?: boolean
}

export default function SessionsPagination(props: Props) {
  const { currentPage, numOfPages, pagesNumbersAmount, pad, positionStatic } = props

  const arrayCalculation = () => {
    let start: number
    let end: number
    if (numOfPages < pagesNumbersAmount || currentPage <= pad) {
      start = 1
      end = pagesNumbersAmount
    } else if (currentPage - pad + pagesNumbersAmount > numOfPages) {
      start = numOfPages - pagesNumbersAmount + 1
      end = numOfPages
    } else {
      start = currentPage - pad
      end = currentPage - pad + pagesNumbersAmount - 1
    }

    return [...Array(end - start + 1).keys()].map(x => x + start) || []
  }

  const calculatedPagesArray = arrayCalculation()

  const handlePrev = () => {
    props.setPage(-1, true)
  }

  const handleNext = () => {
    props.setPage(1, true)
  }

  const handleNumberClick = (selectedPage: number) => {
    props.setPage(selectedPage)
  }

  return (
    <Wrapper positionStatic={positionStatic}>
      <Arrow disabled={currentPage === 1} onClick={handlePrev}>
        <PrevIcon />
      </Arrow>

      {calculatedPagesArray.map((index: number) => {
        return (
          <PageNumber
            key={index}
            hide={numOfPages < index}
            currentPage={currentPage === index}
            onClick={() => handleNumberClick(index)}
          >
            {index}
          </PageNumber>
        )
      })}

      <Arrow disabled={currentPage === numOfPages} onClick={handleNext}>
        <NextIcon />
      </Arrow>
    </Wrapper>
  )
}
