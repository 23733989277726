import { useEffect, useState } from 'react'
import { Gallery as ReactPhotoGallery } from 'react-photoswipe-gallery'
import { Img } from '../RightSection/Gallery/GellaryComponent/Img'
import type { UIElementData } from 'photoswipe'
import styles from './GalleryGridComponent.module.scss'
import { useTechSpecStore } from '../../store/useTechSpecStore'
// import ReactPaginate from 'react-paginate';
import SessionsPagination from '../Sessions/SessionsPagination/sessionsPagination'
interface GalleryGridProps {
  data: ImageResponse[]
}

interface ImageResponse {
  upscale: Image[]
}

interface Image {
  id: {
    sessionNum: number
    revisionNum: number
    imageNum: number
  }
  uri: string
}

const imageResponseToImage: (v: ImageResponse[]) => Image[] = data => {
  const newData: Image[] = []
  data.forEach(imageResponse => {
    imageResponse &&
      imageResponse.upscale.forEach(image => {
        newData.push(image)
      })
  })
  return newData
}

export const GalleryGridComponent = ({ data }: GalleryGridProps) => {
  const newData = imageResponseToImage(data)
  const [page, setPage] = useState<number>(1)
  const pagesNumbersAmount = 4
  const pad = 1
  /** this will stop the saving image by right click */
  const disableRightClick = (event: MouseEvent) => {
    const target = event.target as HTMLElement
    if (target.tagName === 'IMG' && target.closest('.pswp__img')) {
      event.preventDefault()
    }
  }

  useEffect(() => {
    document.addEventListener('contextmenu', disableRightClick)
    return () => {
      document.removeEventListener('contextmenu', disableRightClick)
    }
  }, [])

  const uiElements: UIElementData[] = [
    {
      name: 'bulletsIndicator',
      order: 9,
      isButton: false,
      appendTo: 'wrapper',
      onInit: (el, pswpInstance) => {
        let prevIndex = -1
        const thumbnails: HTMLDivElement[] = []

        el.style.position = 'absolute'
        el.style.bottom = '20px'
        el.style.left = '10px'
        el.style.right = '0'
        el.style.display = 'grid'
        // el.style.gridGap = '10px'
        el.style.gridTemplateColumns = 'repeat(auto-fit, 40px)'
        el.style.gridTemplateRows = 'repeat(auto-fit, 40px)'
        el.style.justifyContent = 'center'

        const dataSource = Array.isArray(pswpInstance.options.dataSource) ? pswpInstance.options.dataSource : []

        for (let i = 0; i < dataSource.length; i++) {
          const slideData = dataSource[i]

          const thumbnail = document.createElement('div')
          thumbnail.style.transition = 'transform 0.15s ease-in'
          thumbnail.style.opacity = '0.6'
          thumbnail.style.cursor = 'pointer'
          thumbnail.onclick = e => {
            const target = e.target as HTMLElement
            const thumbnailEl = target?.tagName === 'IMG' ? (target?.parentElement as HTMLElement) : target
            if (thumbnailEl instanceof HTMLImageElement) {
              pswpInstance.goTo(thumbnails.indexOf(thumbnailEl))
            }
          }

          const thumbnailImage = document.createElement('img')
          thumbnailImage.setAttribute('src', slideData.msrc || '')
          thumbnailImage.style.width = '100%'
          thumbnailImage.style.height = '100%'
          thumbnailImage.style.objectFit = 'cover'

          thumbnail.appendChild(thumbnailImage)

          el.appendChild(thumbnail)

          thumbnails.push(thumbnail)
        }

        pswpInstance.on('change', () => {
          if (prevIndex >= 0) {
            const prevThumbnail: HTMLDivElement = thumbnails[prevIndex]
            prevThumbnail.style.opacity = '0.6'
            prevThumbnail.style.cursor = 'pointer'
            prevThumbnail.style.transform = 'scale(1)'
          }

          const currentThumbnail: HTMLDivElement = thumbnails[pswpInstance.currIndex]
          currentThumbnail.style.opacity = '1'
          currentThumbnail.style.cursor = 'unset'
          currentThumbnail.style.transform = 'scale(1.2)'

          prevIndex = pswpInstance.currIndex
        })
      },
    },
  ]
  const { isDesignWindowExpanded } = useTechSpecStore(store => store)
  const skeletonClass = uiElements.length === 3 && isDesignWindowExpanded && styles.expanded

  const itemsPerPage = 8

  const indexOfLast = page * itemsPerPage
  const indexOfFirst = indexOfLast - itemsPerPage
  const currentItems = newData.slice(indexOfFirst, indexOfLast)
  const pageCount = Math.ceil(newData.length / itemsPerPage)

  const changePage = (num: number, arrowNavigation?: boolean) => {
    if (arrowNavigation) {
      setPage(currentPage => currentPage + num)
    } else {
      setPage(num)
    }
  }

  return (
    <div>
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: '1fr 1fr 1fr 1fr',
          gridGap: '20px',
        }}
      >
        <ReactPhotoGallery
          // options={}
          uiElements={uiElements}
        >
          {currentItems.map(({ uri }, index) => {
            return <Img key={index} url={uri} index={index} skeletonClass={skeletonClass} />
          })}
        </ReactPhotoGallery>
      </div>
      <div className={styles.pagination}>
        <SessionsPagination
          currentPage={page}
          numOfPages={pageCount}
          pagesNumbersAmount={pagesNumbersAmount}
          pad={pad}
          setPage={changePage}
        />
      </div>
    </div>
  )
}
