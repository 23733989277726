const ANIMATION_DURATION = 1000
export const ANIMATION_TIMEOUT = 1200

const createDuplicate = image => {
  const { x: imageX, y: imageY, width: imageW, height: imageH } = image.getBoundingClientRect()
  const duplicateImage = image.cloneNode(true)
  duplicateImage.width = imageW
  duplicateImage.height = imageH
  duplicateImage.style.position = 'absolute'
  duplicateImage.style.top = imageY + 'px'
  duplicateImage.style.left = imageX + 'px'
  duplicateImage.style.transition = 'transform 1s linear'
  duplicateImage.style.overflow = 'hidden'
  document.body.appendChild(duplicateImage)
  return { duplicateImage, imageY, imageX, imageW, imageH }
}

const getWayToTarget = ({ imageY, imageX, imageW, imageH }) => {
  const targetNode = document.querySelector('#designCanvas')
  const targetPosition = targetNode.getBoundingClientRect()
  const { y, x, width: targetW, height: targetH } = targetPosition

  const targetCoordinates = { x: x + targetW / 2, y: y + targetH / 2 }

  const diffX = (targetCoordinates.x - imageX - imageW / 2) / 5
  const diffY = (targetCoordinates.y - imageY - imageH / 2) / 5

  return { diffX, diffY }
}

const cleanUp = duplicateImage => {
  // Clean up after animation completes
  setTimeout(() => {
    document.body.removeChild(duplicateImage)
  }, ANIMATION_TIMEOUT)
}

export const animateImage = ({ image }) => {
  const { duplicateImage, imageY, imageX, imageW, imageH } = createDuplicate(image)
  const { diffX, diffY } = getWayToTarget({ imageY, imageX, imageW, imageH })

  const animationProperties = {
    duration: ANIMATION_DURATION,
    keyframes: [
      {
        transform: `translate(0, 0)`,
        offset: 0,
      },
      {
        transform: `translate(${diffX}px, ${diffY}px) scale(0.9)`,
        offset: 0.2,
      },
      {
        transform: `translate(${2 * diffX}px, ${2 * diffY}px) scale(0.7)`,
        offset: 0.4,
      },
      {
        transform: `translate(${3 * diffX}px, ${3 * diffY}px) scale(0.5)`,
        offset: 0.6,
      },
      {
        transform: `translate(${4 * diffX}px, ${4 * diffY}px) scale(0.3)`,
        offset: 0.8,
      },
      {
        transform: `translate(${5 * diffX}px, ${5 * diffY}px) scale(0.1)`,
        offset: 1,
      },
    ],
    fill: 'forwards',
  }

  duplicateImage.animate(animationProperties.keyframes, animationProperties)
  cleanUp(duplicateImage)
}
